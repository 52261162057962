import {
  generateItemsReducer,
  generateActions,
  itemFromStore,
  itemEditDataFromStore,
  itemLocalDataFromStore,
} from 'reducers/item'

const itemType = "documentUserState"
const endpoint = "document_user_states/"

export const documentUserStatesReducer = generateItemsReducer(itemType)

export const {
  seedDocumentUserState,
  createDocumentUserState,
  readDocumentUserState,
  updateDocumentUserState,
  destroyDocumentUserState,
  setEditFieldsDocumentUserState,
  resetEditFieldsDocumentUserState,
  setLocalFieldsDocumentUserState,
  resetLocalFieldsDocumentUserState,
} = generateActions(itemType, endpoint)

export const documentUserStateFromStore = (store, id) => itemFromStore(store, itemType, id)
export const documentUserStateEditDataFromStore = (store, id) => itemEditDataFromStore(store, itemType, id)
export const documentUserStateLocalDataFromStore = (store, id) => itemLocalDataFromStore(store, itemType, id)
