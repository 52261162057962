import React from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'

const YMWD = {
  YEARS: "YEARS",
  MONTHS: "MONTHS",
  WEEKS: "WEEKS",
  DAYS: "DAYS"
}

const YMWD_CONVERT_TO_DAYS = {
  YEARS: 365,
  MONTHS: 30,
  WEEKS: 7,
  DAYS: 1
}

const YMWD_MAXIMUM_TIME_UNIT_VALUE = {
  YEARS: 110,
  MONTHS: 24,
  WEEKS: 24,
  DAYS: 30
}

const DEFAULT_STATE = {
  timeUnit: YMWD.YEARS,
  internalTimeUnitValue: {
    min: 0,
    max: YMWD_MAXIMUM_TIME_UNIT_VALUE[YMWD.YEARS]
  }
}

export default class GenYMWDFilter extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.value?.max) {
      let timeUnits = Object.keys(YMWD).reverse()
      let timeUnit = timeUnits.find((timeUnit) => {
        let unitDays = YMWD_CONVERT_TO_DAYS[timeUnit]
        let unitMax = YMWD_MAXIMUM_TIME_UNIT_VALUE[timeUnit]
        let maxDays = unitDays * unitMax

        return (props.value.max <= maxDays) && (props.value.max % unitDays === 0)
      })

      this.state = {
        timeUnit: timeUnit,
        internalTimeUnitValue: this.divideRange(props.value, YMWD_CONVERT_TO_DAYS[timeUnit])
      }
    } else {
      this.state = DEFAULT_STATE
    }
  }

  toggleFilter() {
    if (this.props.value) {
      this.props.onChange(null)
    } else {
      this.resetFilter()
    }
  }

  resetFilter() {
    this.setState(DEFAULT_STATE, this.syncExternal)
  }

  handleTimeUnitChange(event) {
    let timeUnit = event.target.value
    let newTimeRange = {
      min: 0,
      max: YMWD_MAXIMUM_TIME_UNIT_VALUE[timeUnit]
    }

    this.setState({
      timeUnit: event.target.value,
      internalTimeUnitValue: newTimeRange
    }, this.syncExternal)
  }

  handleInternalChange(internalTimeUnitValue) {
    this.setState({internalTimeUnitValue: internalTimeUnitValue})
  }

  handleExternalChange(internalTimeUnitValue) {
    this.setState({internalTimeUnitValue: internalTimeUnitValue}, this.syncExternal)
  }

  syncExternal() {
    this.props.onChange(this.convertDisplayRangeToDayRange(this.state.internalTimeUnitValue))
  }

  divideRange(range, integer) {
    return {
      min: range.min/integer,
      max: range.max/integer
    }
  }

  multiplyRange(range, integer) {
    return {
      min: range.min * integer,
      max: range.max * integer
    }
  }

  currentConversion() {
    return YMWD_CONVERT_TO_DAYS[this.state.timeUnit]
  }

  convertDayRangeToDisplayRange(dayRange) {
    return this.divideRange(dayRange, this.currentConversion())
  }

  convertDisplayRangeToDayRange(displayRange) {
    const range = this.multiplyRange(displayRange, this.currentConversion());
    range.min = Math.max(1, range.min);
    return range;
  }

  render() {
    let classes = 'gen-list-filter-2 gen-list-ymwd-filter'
    let title = <h5>{this.props.title}</h5>

    if (this.props.displayLikeFilterGroup) {
      classes += ' like-checkbox-filters'
      title = <h4>{this.props.title}</h4>
    }

    return <div className={classes}>
      <div className="gen-list-filter-title">
        {title}
        <div className="cursor-pointer"><span onClick={this.toggleFilter.bind(this)}>{this.props.value ? "All" : "Filter"}</span></div>
      </div>
      {this.props.value && <div className="gen-list-filter-body">
        <div style={{marginTop: '5px'}}>
          <select value={this.state.timeUnit} onChange={this.handleTimeUnitChange.bind(this)} style={{width: '100%'}}>
            {Object.values(YMWD).map((unit) => <option key={unit} value={unit}>{unit.charAt(0).toUpperCase() + unit.slice(1).toLowerCase()}</option>)}
          </select>
        </div>
        <div style={{margin: '5px 10px'}}>
          <InputRange
            minValue={0}
            maxValue={YMWD_MAXIMUM_TIME_UNIT_VALUE[this.state.timeUnit]}
            value={this.state.internalTimeUnitValue}
            onChange={this.handleInternalChange.bind(this)}
            onChangeComplete={this.handleExternalChange.bind(this)}
          />
        </div>
      </div>}
    </div>
  }
}
