import React from 'react';

export default function GenModal(
  {
    open,
    children,
  },
) {
  if (open) {
    return (
      <div className="gen-modal">
        {children}
      </div>
    );
  } else {
    return(null);
  }
}
