function toggleMenu(e) {
  e.preventDefault();
  const navigationMenu = document.getElementById("js-navigation-menu");

  if (navigationMenu.classList.contains("hidden")) {
    navigationMenu.classList.remove("hidden");
  } else {
    navigationMenu.classList.add("hidden");
  }
}

function readyMenu() {
  const menuToggle = document.getElementById("js-mobile-menu");

  if (menuToggle) {
    menuToggle.removeEventListener("click", toggleMenu);
    menuToggle.addEventListener("click", toggleMenu);
  }
}

document.addEventListener("DOMContentLoaded", readyMenu);
document.addEventListener("turbolinks:load", readyMenu);
