import React from 'react';

import CardiovascularImage from "images/category_icons/Cardiovascular.svg"
import DermatologyImage from "images/category_icons/Dermatology.svg"
import EENTImage from "images/category_icons/EENT.svg"
import EmergencyMedicineImage from "images/category_icons/EmergencyMedicine.svg"
import EndocrinologyImage from "images/category_icons/Endocrinology.svg"
import FamilyMedicineImage from "images/category_icons/FamilyMedicine.svg"
import GastroenterologyImage from "images/category_icons/Gastroenterology.svg"
import GeriatricsImage from "images/category_icons/Geriatrics.svg"
import InterdisciplinaryImage from "images/category_icons/Interdisciplinary.svg"
import MusculoskeletalImage from "images/category_icons/Musculoskeletal.svg"
import NephrologyImage from "images/category_icons/Nephrology.svg"
import NeurologyImage from "images/category_icons/Neurology.svg"
import NursingImage from "images/category_icons/Nursing.svg"
import OBGYNImage from "images/category_icons/OBGYN.svg"
import PediatricsImage from "images/category_icons/Pediatrics.svg"
import PhysicianAssistantImage from "images/category_icons/PhysicianAssistant.svg"
import PsychologyImage from "images/category_icons/Psychology.svg"
import PulmonologyImage from "images/category_icons/Pulmonology.svg"

const IMAGE_LOOKUP = {
  "Cardiovascular": CardiovascularImage,
  "Dermatology": DermatologyImage,
  "EENT": EENTImage,
  "Emergency Medicine": EmergencyMedicineImage,
  "Endocrinology": EndocrinologyImage,
  "Family Medicine": FamilyMedicineImage,
  "Gastroenterology": GastroenterologyImage,
  "Geriatrics": GeriatricsImage,
  "Interdisciplinary": InterdisciplinaryImage,
  "Musculoskeletal": MusculoskeletalImage,
  "Nephrology": NephrologyImage,
  "Neurology": NeurologyImage,
  "Nursing": NursingImage,
  "OBGYN": OBGYNImage,
  "Pediatrics": PediatricsImage,
  "Physician Assistant": PhysicianAssistantImage,
  "Psychology": PsychologyImage,
  "Pulmonology": PulmonologyImage,
}

const DIFFICULTY_LOOKUP = {
  [1]: "Easy",
  [2]: "Intermediate",
  [3]: "Difficult",
}

function DifficultyGraph(props) {
  return (
    <div className="flex flex-row items-end">
      {
        [1,2,3].map(i => {
          const color = props.difficulty < i ? "#C5CFCD" : "#3B8D87"
          const height = 4 + (i * 8)

          return (
            <svg key={i} xmlns="http://www.w3.org/2000/svg" className="m-0.5" width="8" height={height} viewBox={`0 0 8 ${height}`} fill="none">
              <path d="M8 0H0V28H8V0Z" fill={color} />
            </svg>
          )
        })
      }
    </div>
  );
}

export default function CatalogSummary(props) {
  const {
    documentSet,
  } = props;

  return(
    <div className="border-solid border-2">
      <a className="" href={"/catalog/" + documentSet.id}>
        <div className="relative">
          <img src={documentSet.cover_image} alt={`Course ${documentSet.id} Cover Image`} width="2092" height="1170"/>

          <div className="absolute bottom-0 w-full">
            <h3 className="m-2 p-2 bg-slate-200/[.80] rounded-lg text-center">{documentSet.name}</h3>
          </div>
        </div>

        <div className="flex flex-wrap w-full justify-between gap-y-3 p-2">
          <div className="flex flex-wrap gap-y-3">
            {(documentSet.categories || []).map(category =>
              <div key={category} className="flex items-center bg-slate-200 rounded-lg py-2 px-3 mr-3">
                <div className="mr-4">
                  <img src={IMAGE_LOOKUP[category]} className="h-7" />
                </div>
                <h4>
                  {category}
                </h4>
              </div>
            )}
          </div>

          <div className="bg-slate-200 rounded-lg py-2 px-3">
            <DifficultyGraph difficulty={documentSet.difficulty}/>
          </div>
        </div>

        <p className="p-2">{documentSet.description}</p>

        <div className="flex justify-between p-2">
          <div>
            {
              documentSet.contributor?.profile_image &&
              <img src={documentSet.contributor.profile_image} />
            }

            <h4>{documentSet.contributor?.name}</h4>
          </div>

          <div>
            <h4>
              {(new Date(documentSet.created_at)).toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric'})}
            </h4>
          </div>
        </div>
      </a>
    </div>
  );
}