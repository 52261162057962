import React from "react";

import GenLoadingSpinner from "./LoadingSpinner";
import GenToggleBar from "./ToggleBar";

import { sortBasedOnType } from "../../src/sort_utilities";
import { areAllSubstringsInValues } from "../../src/string_utilities";

function findInSummary(summary, grouping, key) {
  return grouping ? summary[grouping]?.[key] : summary[key];
}

export default class GenGroupableTable extends React.Component {
  constructor(props) {
    super(props);

    let defaultReport = props.reports[0];
    if (props.defaultReportKey) {
      defaultReport = props.reports.find((report) => report.key === props.defaultReportKey);
    }

    let collapsedRows = [];
    if (props.collapsedRowIds) {
      collapsedRows = props.collapsedRowIds;
    }

    const defaults = {
      currentReport: defaultReport,
      currentGrouping: defaultReport.groupings && defaultReport.groupings[0].key,
      searchValue: "",
      sortKey: null,
      sortDirection: null,
      collapsed: collapsedRows,
      loading: true,
    };
    this.state = defaults;

    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const {
      currentReport,
    } = this.state;

    fetch(currentReport.dataUrl, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

        return response.json();
      })
      .then((data) => { this.setState(data, this.sortAndFilterData); })
      .catch(() => { alert("There was an issue loading the report. Please try again."); });
  };

  orderedSettings() {
    const {
      currentReport,
      currentGrouping,
    } = this.state;

    return currentReport.settings.reduce((acc, setting) => {
      if (setting.grouping === currentGrouping) {
        acc[0].push(setting);
      } else {
        acc[1].push(setting);
      }

      return acc;
    }, [[], []]).flat();
  }

  groupTypes() {
    const {
      currentGrouping,
    } = this.state;

    const {currentReport} = this.state;

    if (currentGrouping === currentReport.groupings[0].key) {
      return {
        groupItemType: currentReport.groupings[0].key,
        unitItemType: currentReport.groupings[1].key,
      };
    } else {
      return {
        groupItemType: currentReport.groupings[1].key,
        unitItemType: currentReport.groupings[0].key,
      };
    }
  }

  sortAndFilterFlatData() {
    const {
      currentReport,
      searchValue,
      sortKey,
      usage_lookup_by_user_id,
      users,
    } = this.state;

    let items = users.map((user) => (
      {
        ...user,
        ...usage_lookup_by_user_id[user.id],
      }
    ));

    // FILTER
    if (searchValue && searchValue.trim() !== "") {
      const searchWords = searchValue.toLowerCase().split(" ").filter((word) => word !== "");
      const searchableSettings = currentReport.settings.filter((setting) => setting.searchable);

      items = items.filter((item) => {
        const itemSearchableValues = searchableSettings.map((setting) => item[setting.key]);
        return areAllSubstringsInValues(searchWords, itemSearchableValues);
      });
    }

    // SORT
    if (sortKey) {
      items = items.sort((a, b) => sortBasedOnType(a[sortKey], b[sortKey]));
    }

    this.setState({
      processedItems: items,
      loading: false,
    });
  }

  sortAndFilterGroupedData() {
    const {
      currentGrouping,
      searchValue,
      sortDirection,
      sortGroup,
      sortKey,
      usage_lookup_by_user_id_and_document_id,
    } = this.state;

    const { groupItemType, unitItemType } = this.groupTypes();

    const {
      [`${groupItemType}s`]: groupItems,
      [`${unitItemType}s`]: unitItems,
    } = this.state;

    if (!groupItems || !unitItems) { return; }

    const orderedSettings = this.orderedSettings();

    // CREATE SUMMARIES
    let groupedSummaries = groupItems.map((groupItem) => {
      const headerSummary = {
        [groupItemType]: groupItem,
      };

      const unitSummaries = unitItems.map((unitItem) => {
        const usage = groupItemType === "document"
          ? usage_lookup_by_user_id_and_document_id[unitItem.id][groupItem.id]
          : usage_lookup_by_user_id_and_document_id[groupItem.id][unitItem.id];

        if (usage) {
          return {
            [groupItemType]: groupItem,
            [unitItemType]: unitItem,
            ...usage,
          };
        } else {
          return null;
        }
      }).filter((s) => s);

      return {
        headerSummary,
        unitSummaries,
      };
    });

    // FILTER
    if (searchValue && searchValue.trim() !== "") {
      const searchWords = searchValue.toLowerCase().split(" ").filter((word) => word !== "");
      const groupSettings = orderedSettings.filter((setting) => setting.grouping === groupItemType && setting.searchable);
      const notGroupSettings = orderedSettings.filter((setting) => setting.grouping !== groupItemType && setting.searchable);

      groupedSummaries = groupedSummaries.map((groupedSummary) => {
        let {unitSummaries} = groupedSummary;

        const groupSearchValues = groupSettings.map((setting) => groupedSummary.headerSummary[setting.grouping][setting.key]);
        if (!areAllSubstringsInValues(searchWords, groupSearchValues)) {
          unitSummaries = unitSummaries.filter((unitSummary) => {
            const notGroupSearchValues = notGroupSettings.map((setting) => (setting.grouping ? unitSummary[setting.grouping][setting.key] : unitSummary[setting.key]));
            return areAllSubstringsInValues(searchWords, notGroupSearchValues);
          });
        }

        return {
          ...groupedSummary,
          unitSummaries,
        };
      }).filter((groupedSummary) => groupedSummary.unitSummaries.length > 0);
    }

    // SORT
    if (sortKey) {
      if (sortGroup === currentGrouping) {
        groupedSummaries = groupedSummaries.sort((a, b) => sortBasedOnType(findInSummary(a.headerSummary, sortGroup, sortKey), findInSummary(b.headerSummary, sortGroup, sortKey)));
        if (sortDirection === "descending") { groupedSummaries.reverse(); }
      } else {
        groupedSummaries.forEach((groupSummary) => {
          groupSummary.unitSummaries = groupSummary.unitSummaries.sort((a, b) => sortBasedOnType(findInSummary(a, sortGroup, sortKey), findInSummary(b, sortGroup, sortKey)));
          if (sortDirection === "descending") { groupSummary.unitSummaries.reverse(); }
        });
      }
    }

    this.setState({
      processedItems: groupedSummaries,
      loading: false,
    });
  }

  sortAndFilterData = () => {
    const {
      currentReport,
    } = this.state;

    if (currentReport.groupings) {
      return this.sortAndFilterGroupedData();
    } else {
      return this.sortAndFilterFlatData();
    }
  };

  toggleCollapseOnClick(value) {
    const {
      collapsed,
    } = this.state;

    if (collapsed.includes(value)) {
      return (() => {
        this.setState({
          collapsed: collapsed.filter((oldValue) => oldValue !== value),
        });
      });
    } else {
      return (() => {
        this.setState({
          collapsed: collapsed.concat([value]),
        });
      });
    }
  }

  renderFlatRows() {
    const {
      currentReport,
      processedItems,
    } = this.state;

    const currentReportSettings = currentReport.settings;
    const rows = processedItems.map((item) => (
      <tr key={item.id}>
        {currentReportSettings.map((setting) => {
          let value = item[setting.key];

          if (setting.displayFunction) {
            value = setting.displayFunction(value, item, this.setState);
          }

          return <th key={setting.key} style={{textAlign: (setting.align || "left")}}>{value}</th>;
        })}
      </tr>
    ));

    return (rows);
  }

  renderGroupedRows() {
    const {
      processedItems,
      collapsed,
    } = this.state;

    const orderedSettings = this.orderedSettings();

    const { groupItemType, unitItemType } = this.groupTypes();

    const rows = processedItems.map((groupSummary) => {
      const groupItemId = groupSummary.headerSummary[groupItemType].id;

      const isClosed = collapsed.includes(groupItemId);

      const headerRow = (
        <tr key={groupItemId}>
          <th key="collapse" onClick={this.toggleCollapseOnClick(groupItemId)}>
            {isClosed
              ? <i className="fa fa-plus-square report-collapse-button" />
              : <i className="fa fa-minus-square report-collapse-button" />}
          </th>
          {orderedSettings.map((setting) => {
            const value = findInSummary(groupSummary.headerSummary, setting.grouping, setting.key);

            return <th key={setting.key} style={{textAlign: (setting.align || "left")}}>{value}</th>;
          })}
        </tr>
      );

      let subRows = [];
      if (!isClosed) {
        subRows = groupSummary.unitSummaries.map((unitSummary) => {
          const unitItemId = unitSummary[unitItemType].id;

          return (
            <tr key={`${groupItemId}---${unitItemId}`}
                id={`row-${groupItemId}-${unitItemId}`}
                data-reviewable-row>
              <td key="collapse" />
              {orderedSettings.map((setting) => {
                let value = findInSummary(unitSummary, setting.grouping, setting.key);

                if (setting.displayFunction) {
                  value = setting.displayFunction(value, unitSummary, this.setState);
                }

                return <td key={setting.key} style={{textAlign: (setting.align || "left")}}>{value}</td>;
              })}
            </tr>
          );
        });
      }

      return [
        headerRow,
        ...subRows,
      ];
    }).flat();

    return (rows);
  }

  renderRows() {
    const {
      currentReport,
    } = this.state;

    if (currentReport.groupings) {
      return this.renderGroupedRows();
    } else {
      return this.renderFlatRows();
    }
  }

  onHeaderClick(newSortGroup, newSortKey) {
    const {
      sortGroup,
      sortKey,
      sortDirection,
    } = this.state;

    const newSortDirection = sortGroup === newSortGroup
      && sortKey === newSortKey
      && sortDirection === "ascending"
      ? "descending" : "ascending";

    this.setState({
      sortGroup: newSortGroup,
      sortKey: newSortKey,
      sortDirection: newSortDirection,
      loading: true,
    }, this.sortAndFilterData);
  }

  onSearchChange = e => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(this.sortAndFilterData, 1000);

    this.setState({
      searchValue: e.target.value,
      loading: true,
    });
  };

  onReportTypeButtonsClick = value => {
    const {
      reports,
    } = this.props;

    const newReport = reports.find((report) => report.key === value);

    this.setState({
      currentReport: newReport,
      currentGrouping: newReport.groupings && newReport.groupings[0].key,
      collapsed: [],
      loading: true,
    }, () => { setTimeout(this.getData, 200); });
  };

  onGroupByButtonsClick = value => {
    this.setState({
      currentGrouping: value,
      collapsed: [],
      loading: true,
    }, this.sortAndFilterData);
  };

  render() {
    const {
      reports,
    } = this.props;

    const {
      currentReport,
      currentGrouping,
      loading,
      processedItems,
      reviewRow,
      searchValue,
      sortDirection,
      sortGroup,
      sortKey,
    } = this.state;

    // Order Columns
    const orderedSettings = this.orderedSettings();

    // HeaderCells
    const headerCells = orderedSettings.map((setting) => {
      const isSorted = sortGroup === setting.grouping && sortKey === setting.key;
      return (
        <th
          key={setting.key}
          onClick={() => this.onHeaderClick(setting.grouping, setting.key)}
          style={{width: `${setting.width || 20}px`, textAlign: (setting.align || "left")}}
        >
          {setting.title} {isSorted && <i className={`fa fa-angle-${sortDirection === "ascending" ? "down" : "up"}`} />}
        </th>
      );
    });

    let rows;
    let renderedLoading;
    if (loading) {
      renderedLoading = <GenLoadingSpinner key="loading-spinner" />;
    } else {
      rows = this.renderRows();
    }

    const reportKeyButtons = reports.map((report) => ({name: report.name, value: report.key}));

    let reviewModal;
    if (currentReport.reviewModal && reviewRow) {
      const ReviewModalComponent = currentReport.reviewModal;
      reviewModal = (
        <ReviewModalComponent
          reviewRow={reviewRow}
          processedItems={processedItems}
          closeReviewRow={() => {
            this.setState({
              reviewRow: null,
            });
          }}
          reloadData={() => {
            this.setState({
              loading: true,
            }, this.getData);
          }}
          selectNewReviewRow={(newReviewRow) => {
            this.setState({
              reviewRow: newReviewRow,
            });
          }}
          {...(currentReport.extraModalData || {})}
        />
      );
    }

    return (
      <div className="panel-group">
        <div className="gen-list-control-panel">
          <form className="gen-list-search-bar" role="search">
            <input
              type="search"
              placeholder="Search..."
              value={searchValue}
              onChange={this.onSearchChange}
              onKeyPress={(e) => { if (e.key === "Enter") { e.preventDefault(); } }}
            />
          </form>

          {!!currentReport.groupings
            && (
            <div className="document-set-report-group-controls">
              <span className="document-set-report-group-controls-label">Group By: </span>
              <GenToggleBar
                buttons={currentReport.groupings.map((grouping) => ({name: grouping.name, value: grouping.key}))}
                value={currentGrouping}
                onClick={this.onGroupByButtonsClick}
              />
              {currentReport.exportUrl && <a className="gen-button export-link" href={currentReport.exportUrl}><span className="button-name">Export</span></a>}
            </div>
            )}

          <div className="document-set-report-group-controls">
            <span className="document-set-report-group-controls-label">Report Type: </span>
            <GenToggleBar buttons={reportKeyButtons} value={currentReport.key} onClick={this.onReportTypeButtonsClick} />
          </div>
        </div>
        <div className="panel" style={{overflowX: "auto"}}>
          <table className="document-set-report-table">
            <thead key="table-head">
              <tr>
                {!!currentReport.groupings && <th key="collapse" style={{width: "20px"}} />}
                {headerCells}
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
          {renderedLoading}
        </div>
        {reviewModal}
      </div>
    );
  }
}
