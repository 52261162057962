import {
  generateItemsReducer,
  generateActions,
  itemFromStore,
  itemEditDataFromStore,
  itemLocalDataFromStore
} from "reducers/item"

const itemType = "activity"
const endpoint = "activities/"

export const activitiesReducer = generateItemsReducer(itemType)

export const {
  seedActivity,
  createActivity,
  readActivity,
  updateActivity,
  destroyActivity,
  setEditFieldsActivity,
  resetEditFieldsActivity,
  setLocalFieldsActivity,
  resetLocalFieldsActivity,
} = generateActions(itemType, endpoint)

export const activityFromStore = (store, id) => itemFromStore(store, itemType, id)
export const activityEditDataFromStore = (store, id) => itemEditDataFromStore(store, itemType, id)
export const activityLocalDataFromStore = (store, id) => itemLocalDataFromStore(store, itemType, id)
