import React from 'react';

import classNames from 'classnames'

export const switchModes = {
  ON: "ON",
  OFF: "OFF",
  MIXED: "MIXED"
}

export default function GenSwitch(
  {
    value,
    onClick,
    includeMixed,
  },
) {
  return (
    <span
                className="gen-switch"
                onClick={ () => {
                  switch (value) {
                    case switchModes.ON:
                      onClick(switchModes.OFF)
                      break;
                    case switchModes.OFF:
                      onClick(includeMixed ? switchModes.MIXED : switchModes.ON)
                      break;
                    case switchModes.MIXED:
                    default:
                      onClick(switchModes.ON)
                  }}
                }>
                <span className={
                  classNames("gen-switch-slider", `${value}`)
                }></span>
           </span>
  );
}
