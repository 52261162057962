import React from 'react';

import GenList from 'Gen/List'

import DocumentSummary from 'Document/Summary'

export default function CategoryDocumentList(
  {
    category_id,
    rootDocumentSetId,
    currentUser,
    currentRoleDefinition,
  },
) {
  return (
    <GenList
      itemsURL={"/categories/" + category_id + "/category_documents"}
      gridView
      itemView={category_document =>
        <DocumentSummary
          document={category_document}
          key={category_document.id}
          rootDocumentSetId={rootDocumentSetId}
          currentUser={currentUser}
          currentRoleDefinition={currentRoleDefinition}
          categoryId={category_id}
        />
      }
      search
    />
  );
}
