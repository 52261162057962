import React from 'react';

import GenList from 'Gen/List'

import CategorySummary from 'Category/Summary'

export default function CategoryList(
  {
    rootDocumentSetId,
    currentUser,
    currentRoleDefinition,
  },
) {
  return (
    <GenList
      shouldDisableMoreButton
      itemsURL={"/categories"}
      itemView={(category, genListCallbacks) => {
        return (
          <CategorySummary
            key={category.id}
            rootDocumentSetId={rootDocumentSetId}
            {...category}
            currentUser={currentUser}
            currentRoleDefinition={currentRoleDefinition}
            positionCallback={genListCallbacks.refreshList}
            itemRefreshCallback={genListCallbacks.refreshItem}
          />
        );
      }}
      search={true}
    />
  );
}
