import React from "react";

import Moment from "moment";

function hostnameWithoutSubdomain() {
  return window.location.host.split(".").slice(-2).join(".");
}

export default function GroupSummary(
  {
    group,
    shouldShowExpiration,
  },
) {
  let expirationView;
  if (group.expiration_date && shouldShowExpiration) {
    const momentTime = Moment(group.expiration_date);
    const inThePast = momentTime < (new Date());
    const formattedDate = momentTime.format("MMMM Do YYYY h:mm a");

    if (inThePast) {
      expirationView = <span style={{color: "red"}}>Expired At: {formattedDate}</span>;
    } else {
      expirationView = <span style={{color: "black"}}>Expires At: {formattedDate}</span>;
    }
  }

  return (
    <div className="gen-select-item">
      <h1 className="name"><a href={`//${group.subdomain}.${hostnameWithoutSubdomain()}`}>{group.name}</a></h1>
      {expirationView}
      <p className="description">{group.description}</p>
    </div>
  );
}
