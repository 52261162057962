import React from 'react';

export default function GenTableDisplay(
  {
    sort_by,
    sort_order,
    setListSort,
    resetListPages,
    readListPage,
    selected,
    setListSelected,
    columns,
    listData,
  },
) {
  const updateSort = (newSortValue) => {
    if (!newSortValue) { return null }

    let newSortDirection = sort_by === newSortValue && sort_order === 'ASC' ? 'DESC' : 'ASC'

    setListSort(newSortValue, newSortDirection)
    resetListPages()
    readListPage()
  };

  const onItemClick = (item) => {
    const isSelected = selected.some(sItem => sItem.id === item.id);
    let newSelectedItems = isSelected ? selected.filter(sItem => sItem.id !== item.id) : selected.concat([item])
    setListSelected(newSelectedItems)
  };

  let tableHeaderCells = columns.map((column) => {
    let orderClass
    let className = ""
    if (column.center_column_header) {
      className = className + " edit-documents-access-table-centered-header"
    }

    if (column.sort_column) {
      className = className + " sort_cell"
      if (column.sort_column !== sort_by) {
        orderClass = "fa fa-sort"
      } else if (sort_order === 'ASC') {
        orderClass = "fa fa-sort-asc"
      } else {
        orderClass = "fa fa-sort-desc"
      }
    }

    if (column.column_width) {
      className = className  + ` ${column.column_width}-column-size`
    }

    return <td key={column.key || column.title} onClick={() => {updateSort(column.sort_column)}} className={className}>
      {column.title}
      <span>  <i className={orderClass}></i></span>
    </td>
  })

  let tableHeader = <tr key='table-header'>
    <td key="select-box" className="select-box"></td>
    {tableHeaderCells}
  </tr>

  let itemRows = (listData || []).map(item => {
    let cells = columns.map(column => {
      let className = (column.calculated_class_name) ? column.calculated_class_name(item) : ""
      return <td key={column.key || column.title} className={className}>{column.calculated_value(item)}</td>
    })

    let isSelected = selected.some(sItem => sItem.id === item.id)

    return <tr key={item.id} className={isSelected ? "bg-sky-100" : ""}>
      <td key="select-box" className="select-box">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => {onItemClick(item)}} />
      </td>
      {cells}
    </tr>
  })

  return <div className="gen-table">
    <table>
      <thead>
        {tableHeader}
      </thead>
      <tbody>
        {itemRows}
      </tbody>
    </table>
  </div>
}
