import React from 'react';

// GenCheckboxes :: {
//   options: [String | DetailedOptions]
//   onChange: function
// }
//
// DetailedOptions :: {
//   title: String,
//   value: Any
// }

export default function GenCheckboxesFilter(props) {
  let classes = 'gen-list-filter-2'
  let title = <h5>{props.title}</h5>

  if (props.displayLikeFilterGroup) {
    classes += ' like-checkbox-filters'
    title = <h4>{props.title}</h4>
  }

  return (
    <div className={classes}>
      <div className="gen-list-filter-title" onClick={() => {props.onChange(props.value ? null : [])}}>
        {title}
        <div className="cursor-pointer"><span>{props.value ? "All" : "Filter"}</span></div>
      </div>
      {props.value && <div className="gen-list-filter-options">
        {props.options.map((option) => {
          let optionName, optionValue
          if (typeof option === 'object') {
            optionName = option.displayName;
            optionValue = option.value;
          } else {
            optionName = option
            optionValue = option
          }

          let key = props.title + "-" + optionName

          let isChecked = props.value.includes(optionValue)
          let onChange = () => {
            let newValue = props.value
            if (isChecked) {
              newValue = newValue.filter((o) => {return o !== optionValue})
            } else {
              newValue = newValue.concat([optionValue])
            }

            props.onChange(newValue)
          }

          return(
            <div key={key} className="gen-list-filter-option">
              <label>
                <input
                  id={key}
                  name={key}
                  type="checkbox"
                  checked={isChecked}
                  onChange={onChange}
                />
                {optionName}
              </label>
            </div>
          )
        })}
      </div>}
    </div>
  );
}
