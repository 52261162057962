import * as R from "ramda"

import {
  generateItemsReducer,
  generateActions,
  itemFromStore,
  itemEditDataFromStore,
  itemLocalDataFromStore
} from "reducers/item"

const itemType = "activityUserState"
const endpoint = "activity_user_states/"

export const activityUserStatesReducer = generateItemsReducer(itemType)

export const {
  seedActivityUserState,
  createActivityUserState,
  readActivityUserState,
  updateActivityUserState,
  destroyActivityUserState,
  setEditFieldsActivityUserState,
  resetEditFieldsActivityUserState,
  setLocalFieldsActivityUserState,
  resetLocalFieldsActivityUserState,
} = generateActions(itemType, endpoint)

export const activityUserStateFromStore = (store, id) => itemFromStore(store, itemType, id, activityUserStateMergeFunction)
export const activityUserStateEditDataFromStore = (store, id) => itemEditDataFromStore(store, itemType, id)
export const activityUserStateLocalDataFromStore = (store, id) => itemLocalDataFromStore(store, itemType, id)

export function addActivityUserState(id, state) {
  return (dispatch, getState) => {
    const newState = R.map(R.map(value => { return {value: value, updatedAt: Date.now()} }), state)

    let activityUserState = activityUserStateFromStore(id, getState())
    let editState = activityUserState?.editData?.state || {}

    dispatch(setEditFieldsActivityUserState(id, {state: R.mergeDeepRight(editState, newState)}))
    dispatch(updateActivityUserState(id))
  }
}

function activityUserStateMergeFunction(rawItem) {
  return R.mergeWithKey(
    (key, oldV, newV) => key === "state" ? R.mergeDeepRight(oldV, newV) : newV,
    rawItem.savedData || {},
    rawItem.editData || {}
  )
}

function defaultDataMergeFunction(rawItem) {
  return R.mergeRight(rawItem.savedData || {}, rawItem.editData || {})
}
