import React from "react";

import GenBooleanCircle from "../Gen/BooleanCircle";
import GenButton from "../Gen/Button";
import GenGroupableTable from "../Gen/GroupableTable";

import CatalogReviewModal from "./ReviewModal";

import { stringDateToDisplayDateString } from "../../src/time_display";
import { fullURL } from "../../src/url";

const documentSetReportSettings = [
  {
    title: "First Name",
    key: "first_name",
    searchable: true,
    width: 100,
  },
  {
    title: "Last Name",
    key: "last_name",
    searchable: true,
    width: 100,
  },
  {
    title: "Email",
    key: "email",
    searchable: true,
    width: 250,
  },
  {
    title: "Viewed Course",
    key: "viewed_course",
    displayFunction: stringDateToDisplayDateString,
    width: 200,
    align: "center",
  },
  {
    title: "Completed Course",
    key: "completed_course",
    displayFunction: stringDateToDisplayDateString,
    width: 200,
    align: "center",
  },
];

const documentReportSettings = [
  {
    title: "First Name",
    key: "first_name",
    grouping: "user",
    searchable: true,
    width: 100,
  },
  {
    title: "Last Name",
    key: "last_name",
    grouping: "user",
    searchable: true,
    width: 100,
  },
  {
    title: "Email",
    key: "email",
    grouping: "user",
    searchable: true,
    width: 250,
  },
  {
    title: "Case Id",
    key: "id",
    grouping: "document",
    searchable: true,
    width: 75,
    displayFunction: (value, rowData) => <a href={`/documents/${rowData.document.id}`} target="_blank" rel="noreferrer">{value}</a>,
  },
  {
    title: "Case Name",
    key: "title",
    grouping: "document",
    searchable: true,
    width: 250,
    displayFunction: (value, rowData) => <a href={`/documents/${rowData.document.id}`} target="_blank" rel="noreferrer">{value}</a>,
  },
  {
    title: "Submitted",
    key: "submitted",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Time of Submission",
    key: "submitted_at",
    displayFunction: stringDateToDisplayDateString,
    width: 200,
    align: "center",
  },
  {
    title: "Grade",
    key: "grade_percentage",
    displayFunction: (value, rowData) => `${Math.round(value * 100)}%`,
    width: 100,
    align: "center",
  },
  {
    title: "Retook",
    key: "attempt_index",
    displayFunction: (value) => value > 0 ? "Yes" : "No",
    width: 100,
    align: "center",
  },
  {
    title: "Review",
    key: "review_button",
    displayFunction: (value, rowData, setState) => (
      <GenButton
        name="Review"
        highlighted={rowData.submitted}
        disabled={!rowData.submitted}
        onClick={() => { setState({reviewRow: rowData}); }}
      />
    ),
    width: 100,
    align: "center",
  },
  {
    title: "Reviewed",
    key: "review_submitted",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Time of Review",
    key: "reviewed_at",
    displayFunction: stringDateToDisplayDateString,
    width: 200,
    align: "center",
  },
  {
    title: "Review Text",
    key: "review_comment",
    searchable: true,
    width: 200,
  },
];

function WrappedCatalogReviewModal(props) {
  const {
    documentSet,
    reviewRow,
    processedItems,
    closeReviewRow,
    reloadData,
    selectNewReviewRow,
  } = props;

  return (
    <CatalogReviewModal
      documentSetId={documentSet.id}
      {...reviewRow}
      allItems={processedItems.flatMap((item) => item.unitSummaries)}
      afterSave={() => {
        reloadData();
      }}
      afterCancel={() => {
        closeReviewRow();
      }}
      setReviewRow={(newReviewRow) => {
        selectNewReviewRow(newReviewRow);
      }}
    />
  );
}

export default function CatalogReport(props) {
  const {
    documentSet,
    chooseCaseReport,
    collapsedDocumentIds,
  } = props;

  const reports = [
    {
      name: "Course",
      key: "course",
      dataUrl: fullURL(`/document_sets/${documentSet.id}/usage_report`),
      settings: documentSetReportSettings,
    },
    {
      name: "Case",
      key: "case",
      dataUrl: fullURL(`/document_sets/${documentSet.id}/usage_report`),
      settings: documentReportSettings,
      groupings: [
        {
          name: "Case",
          key: "document",
        },
        {
          name: "User",
          key: "user",
        },
      ],
      reviewModal: WrappedCatalogReviewModal,
      extraModalData: {documentSet},
    },
  ];

  return (
    <GenGroupableTable
      reports={reports}
      defaultReportKey={chooseCaseReport ? 'case' : null}
      collapsedRowIds={collapsedDocumentIds}
    />
  );
}
