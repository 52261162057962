import React from 'react';

import DocumentSummary from 'Document/Summary'

export const documentListDefaults = {
  title:"Cases",
  itemsURL:"/documents",
  itemView:function(document){
    return(<DocumentSummary document={document} key={document.id}/>);
  },
  search:true,
  searchPlaceHolder: "Search by symptom, diagnosis, or case #...",
  sortOptions:[
    {
      "title": "Case #",
      "value": "documents.id"
    },
    {
      "title": "Published",
      "value": "documents.created_at"
    }
  ],
  filterOptions:[
    { "title": "Patient Age",
      "options": [
        "Newborn",
        "Infant",
        "Toddler",
        "Child",
        "Adolescent",
        "Adult",
        "Young Adult",
        "Elderly"
      ]
    },
    { "title": "Organ System",
      "options": [
        "Circulatory",
        "Digestive",
        "Endocrine",
        "Integumentary/Dermatology",
        "Lymphatic",
        "Muscular",
        "Nervous",
        "Reproductive",
        "Respiratory",
        "Skeletal",
        "Urinary"
      ]
    },
    { "title": "Specialty",
      "options": [
        "Dentistry",
        "Dermatology",
        "Emergency Medicine",
        "Family Medicine",
        "Internal Medicine",
        "Medical Genetics",
        "Neurosurgery",
        "Neurology",
        "Obstetrics & Gynecology",
        "Orthopedics",
        "Pathology",
        "Pediatrics",
        "Physical Medicine & Rehabilitation",
        "Psychiatry",
        "Surgery",
      ]
    },
    { "title": "Libraries",
      "options": [
        "Developing World Medicine",
        "Emergency Bedside Ultrasound",
        "Emergency Medical Services (EMS)",
        "Intro to Pathology",
        "Kartini Clinic For Children & Families: Eating Disorders Library",
        "Oregon Supported Living Program    With Developmental Disabilities",
        "ReelDx Grand Rounds: Recorded Webinar Series"
      ]
    }
  ],
  checkboxFilterOptions:[
    {
      "key": "extra_media",
      "filter": "extra_media",
      "label": "Only cases with other media"
    }
  ]
};
