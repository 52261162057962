import React from 'react';

// GenCheckbox :: {
//   title: String,
//   value: Any
//   onChange: function
// }

export default function GenCheckboxFilter(props) {
  const {
    title,
    value,
    onChange
  } = props

  return(
    <div className="gen-checkbox-filter mb-2">
      <label>
        <input
          id={title}
          name={title}
          type="checkbox"
          checked={value || false}
          onChange={() => onChange(!value)}
        />
        {title}
      </label>
    </div>
  )
}
