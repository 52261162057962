import React from 'react';

import GenToggleButton from 'Gen/ToggleButton'

export default function GenToggleBar(props) {
  const onClickFunction = (value) => {
    return () => { props.onClick(value) };
  };

  let toggleButtons = props.buttons.map((button) => {
    return (
      <GenToggleButton
        key={button.name}
        name={button.name}
        value={button.value === props.value}
        disabled={props.disabled || button.disabled}
        onClick={onClickFunction(button.value)} />
    );
  });

  return(
    <div className="gen-toggle-bar">
      {toggleButtons}
    </div>
  );
}
