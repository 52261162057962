import React from 'react';

import GenPanelHeader from 'Gen/PanelHeader'
import GenButton from 'Gen/Button'

import { fullURL } from 'src/url'

export default class AddToSummary extends React.Component {
  state = {
    canAdd: !this.props.includesDoc
  }

  onAddClicked = () => {
    if (!this.props.documentId) {
      return;
    }

    this.setState({ canAdd: false })

    let data = {
      documentIds: [this.props.documentId],
      event: "add_to_course_button",
      categoryId: this.props.categoryId,
    };

    let url = fullURL(`/document_sets/${this.props.documentSet.id}/document_set_document_relations/create_batch_list`)

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {})
      .catch(() => {});
  };

  onRemoveClicked = () => {
    if (!this.props.documentId) {
      return;
    }

    this.setState({ canAdd: true })

    let data = {
      documentIds: [this.props.documentId],
      event: "add_to_course_button",
      categoryId: this.props.categoryId
    };

    let url = fullURL(`/document_sets/${this.props.documentSet.id}/document_set_document_relations/destroy_batch_list`)

    fetch(url, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {})
      .catch(() => {});
  }

  render() {
    const {
      enabled,
      documentSet
    } = this.props

    let button
    let buttonIcon = this.state.canAdd ? "fa-plus" : "fa-minus"

    if (!enabled) {
      button = <GenButton name={<i title="You don't have permission to edit this course" className={`fa ${buttonIcon}`}></i>} disabled/>
    } else if (this.state.canAdd) {
      button = <GenButton name={<i title="Add to course" className={`fa ${buttonIcon}`}></i>} highlighted onClick={this.onAddClicked.bind(this)}/>
    } else {
      button = <GenButton name={<i title="Remove from course" className={`fa ${buttonIcon}`}></i>} destructive onClick={this.onRemoveClicked.bind(this)}/>
    }

    return(
      <div className="panel summary">
        <GenPanelHeader rightContent={button}>
          <a
            target="_blank"
            href={"/document_sets/" + documentSet.id}
            onClick={this.handleTitleClick}
            className="name">
              {documentSet.name}
          </a>
        </GenPanelHeader>

        <p className="description">{documentSet.description}</p>
        {documentSet.owner_full_name && <p> <strong> Course Owner: </strong> {documentSet.owner_full_name}</p>}
      </div>
    );
  }
}
