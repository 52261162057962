import React from 'react';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import 'react-widgets/dist/css/react-widgets.css';

export default function GroupExpirationDatePicker(props) {
  return <DateTimePicker
           defaultValue={props.currentValue && new Date(props.currentValue)}
           time={false}
           {...props}
         />
}
