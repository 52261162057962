import axios from 'axios'
import * as R from 'ramda'

import { fullURL } from 'src/url'

export const SET_ENDPOINT = 'list/SET_ENDPOINT'

export const READ_PAGE = 'list/READ_PAGE'
export const RESET_PAGES = 'list/RESET_PAGES'

const PENDING = '_PENDING'
const FULFILLED = '_FULFILLED'
const REJECTED = '_REJECTED'

export const ADD_FILTER = 'list/ADD_FILTER'
export const REMOVE_FILTER = 'list/REMOVE_FILTER'

export const SET_SEARCH = 'list/SET_SEARCH'
export const RESET_SEARCH = 'list/RESET_SEARCH'

export const SET_SORT = 'list/SET_SORT'
export const RESET_SORT = 'list/RESET_SORT'

export const SET_SELECTED = 'list/SET_SELECTED'
export const RESET_SELECTED = 'list/RESET_SELECTED'
export const ADD_SELECTED = 'list/ADD_SELECTED'
export const REMOVE_SELECTED = 'list/REMOVE_SELECTED'

export const SET_ITEMS_PER_PAGE = 'list/SET_ITEMS_PER_PAGE'

export function listsReducer(state={}, action={}) {
  if (action.meta?.listID) {
    return {...state,
      [action.meta.listID]: listReducer(state[action.meta.listID], action)
    }
  } else {
    return state
  }
}

export function getListFromStore(store, listID) {
  return store.lists[listID] || DEFAULT_STATE;
}

// {
//   listData: [],
//   localMetadata: {},
//   isSyncing: false,
//   lastSyncedAt: Date.now(),
//   errors: null,
//   nextSyncPageNumber:
//   itemsPerPage:
//   lastPageLength:
//   selected:
//   search:
//   sort_by:
//   sort_order:
//   activeFilters:
// }

const DEFAULT_STATE = {
  isSyncing: false,
  nextSyncPageNumber: 0,
  itemsPerPage: 24,
  selected: [],
}

function listReducer(state=DEFAULT_STATE, action={}) {
  switch (action.type) {
    case SET_ENDPOINT:
      return {...state,
        endpoint: action.payload.endpoint
      }
    case READ_PAGE + PENDING:
      return {...state,
        isSyncing: true
      }
    case READ_PAGE + FULFILLED:
      return {...state,
        isSyncing: false,
        lastSyncedAt: Date.now(),
        errors: null,
        listData: (state.listData || []).concat(action.payload.data),
        nextSyncPageNumber: state.nextSyncPageNumber + 1,
        lastPageLength: action.payload.data.length
      }
    case READ_PAGE + REJECTED:
      return {...state,
        isSyncing: false,
        syncError: action.payload.message
      }
    case RESET_PAGES:
      return {...state,
        isSyncing: false,
        lastSyncedAt: null,
        errors: null,
        listData: null,
        nextSyncPageNumber: 0,
        lastPageLength: null
      }
    case ADD_FILTER:
      return {...state,
        activeFilters: {...state.activeFilters,
          [action.payload.filterID]: action.payload.filter
        }
      }
    case REMOVE_FILTER:
      return {...state,
        activeFilters: R.omit([action.payload.filterID], state.activeFilters)
      }
    case SET_SEARCH:
      return {...state,
        search: action.payload.search
      }
    case RESET_SEARCH:
      return R.omit(['search'], state)
    case SET_SORT:
      return {...state,
        sort_by: action.payload.sort_by,
        sort_order: action.payload.sort_order,
      }
    case RESET_SORT:
      return R.omit(['sort_by', 'sort_order'], state)
    case SET_SELECTED:
      return {...state,
        selected: action.payload.selected
      }
    case RESET_SELECTED:
      return {...state,
        selected: []
      }
    case ADD_SELECTED:
      return {...state,
        selected: R.unionWith((a, b) => a.id === b.id, state.selected, action.payload.addSelected)
      }
    case REMOVE_SELECTED:
      return {...state,
        selected: R.difference((a, b) => a.id === b.id, state.selected, action.payload.removeSelected)
      }
    case SET_ITEMS_PER_PAGE:
      return {...state,
        itemsPerPage: action.payload.itemsPerPage
      }
    default:
      return state
  }
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function setEndpoint(listID, endpoint) {
  return {
    type: SET_ENDPOINT,
    meta: {listID},
    payload: {endpoint}
  }
}


function listDataFromStore(listStore=DEFAULT_STATE) {
  let data = R.pick([
      'ids',
      'not_ids',
      'search',
      'sort_order',
      'sort_by',
    ],
    listStore
  )

  if (listStore.itemsPerPage) {
    data.page = listStore.nextSyncPageNumber
    data.per_page = listStore.itemsPerPage
  }

  //
  // if (filterWhereQuery && filterWhereQuery.length > 0) {
  //   data.where = JSON.stringify(filterWhereQuery)
  // }

  return data;
}

export function readListPageWithData(listID, endpoint, data) {
  return {
    type: READ_PAGE,
    meta: {listID},
    payload: axios({
      url: fullURL('/' + endpoint),
      method: 'get',
      params: data,
      withCredentials: true,
      headers: {'Accept': 'application/json'}
    }),
  }
}

export function readListPage(listID) {
  return (dispatch, getState) => {
    const state = getState()
    const list = state.lists[listID]
    const data = listDataFromStore(list)

    const endpoint = list.endpoint

    dispatch(readListPageWithData(listID, endpoint, data))
  }
}

export function resetListPages(listID) {
  return {
    type: RESET_PAGES,
    meta: {listID}
  }
}

export function addListFilter(listID, filterID, filter) {
  return {
    type: ADD_FILTER,
    meta: {listID},
    payload: {filterID, filter}
  }
}

export function removeListFilter(listID, filterID) {
  return {
    type: REMOVE_FILTER,
    meta: {listID},
    payload: {filterID}
  }
}

export function setListSearch(listID, search) {
  return {
    type: SET_SEARCH,
    meta: {listID},
    payload: {search}
  }
}

export function resetListSearch(listID) {
  return {
    type: RESET_SEARCH,
    meta: {listID}
  }
}

export function setListSort(listID, sort_by, sort_order) {
  return {
    type: SET_SORT,
    meta: {listID},
    payload: {sort_by, sort_order}
  }
}

export function resetListSort(listID) {
  return {
    type: RESET_SORT,
    meta: {listID}
  }
}

export function setListSelected(listID, selected) {
  return {
    type: SET_SELECTED,
    meta: {listID},
    payload: {selected}
  }
}

export function resetListSelected(listID) {
  return {
    type: RESET_SELECTED,
    meta: {listID}
  }
}

export function addListSelected(listID, addSelected) {
  return {
    type: ADD_SELECTED,
    meta: {listID},
    payload: {addSelected}
  }
}

export function removeListSelected(listID, removeSelected) {
  return {
    type: REMOVE_SELECTED,
    meta: {listID},
    payload: {removeSelected}
  }
}

export function setListItemsPerPage(listID, itemsPerPage) {
  return {
    type: SET_ITEMS_PER_PAGE,
    meta: {listID},
    payload: {itemsPerPage}
  }
}
