import React from 'react';

export default class DocVideoJWPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.jwPlayerId = `jw-player-${Math.floor(Math.random() * 1000000000)}`
  }

  componentDidMount() {
    const {
      coverImageURL,
      hasCaptions,
      onVideoPlayEvent,
      shouldLoop=false,
      videoURL,
    } = this.props

    let jwplayerSettings = {
      "androidhls": true,
      "playlist": [{
        "image": coverImageURL || videoURL + "/thumbnail_00002.png",
        "sources" : [{
          "file": videoURL + "/index.m3u8"
        },{
          "file": videoURL + "/index.mpd"
        },{
          "file": videoURL + "/main.mp4"
        }]
      }],
      "repeat": shouldLoop
    }

    if (hasCaptions) {
      jwplayerSettings.playlist[0].tracks = [{
        "kind": "captions",
        "file": videoURL + "/captions.vtt",
        "label": "English"
      }]
    }

    this.jwPlayer = jwplayer(this.jwPlayerId).setup(jwplayerSettings);
    this.startTime = this.jwPlayer.getPosition()

    if (onVideoPlayEvent) {
      this.jwPlayer.on('play', this.handleJWPlayerPlay.bind(this));
      this.jwPlayer.on('pause', this.handleJWPlayerPause.bind(this));
      this.jwPlayer.on('complete', this.handleJWPlayerComplete.bind(this));
    }
  }

  componentWillUnmount() {
    const {
      onVideoPlayEvent,
    } = this.props

    if (onVideoPlayEvent && this.jwPlayer) {
      this.jwPlayer.off('play', this.handleJWPlayerPlay.bind(this));
      this.jwPlayer.off('pause', this.handleJWPlayerPause.bind(this));
      this.jwPlayer.off('complete', this.handleJWPlayerComplete.bind(this));
    }
  }

  handleJWPlayerPlay = () => {
    this.startTime = this.jwPlayer.getPosition()
  };

  handleJWPlayerPause = () => {
    this.props.onVideoPlayEvent(this.jwPlayerTimeDiff());
    this.startTime = this.jwPlayer.getPosition()
  };

  handleJWPlayerComplete = () => {
    this.props.onVideoPlayEvent(this.jwPlayerTimeDiff());
    this.startTime = this.jwPlayer.getPosition()

    if (this.props.onVideoCompleteEvent) {
      this.props.onVideoCompleteEvent();
    }
  };

  jwPlayerTimeDiff = () => {
    let timeDiff = this.jwPlayer.getPosition() - this.startTime;
    return (timeDiff > 0 ? timeDiff : 0);
  };

  render() {
    return <div>
      <div id={this.jwPlayerId} className="video-wrapper jw-player"/>
    </div>
  }
}
