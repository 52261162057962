import React from 'react';

import GenCycleButton from 'Gen/CycleButton'

export default function GenToggleButton(
  {
    name,
    onName,
    disabled,
    value,
    onClick,
  },
) {
  return (
    <GenCycleButton
      states={[
        { "button" : {
            "name" : name,
            "highlighted" : false,
            "disabled" : false
          },
          "value" : false,
          "nextValue" : true
        },
        { "button" : {
            "name" : (onName || name),
            "highlighted" : true,
            "disabled" : false
          },
          "value" : true,
          "nextValue" : false
        }
      ]}
      disabled={disabled}
      currentValue={value}
      onClick={onClick} />
  );
}
