import React from "react";

import {capitalize} from "lodash";
import pluralize from "pluralize";
import connectToList from "../../store/list";

import GenControlsBar from "../Gen/ControlsBar";
import GenListDisplay from "../Gen/ListDisplay";
import GenMore from "../Gen/More";
import GenSearchBox from "../Gen/SearchBox";
import GenSelectBar from "../Gen/SelectBar";

const ACTIVITIES_ENDPOINT = "activities";
const CATEGORIES_ENDPOINT = "categories";

function DocumentSetNewBatchPreset(
  {
    activities,
    categories,
  },
) {
  React.useEffect(() => {
    activities.mountList(ACTIVITIES_ENDPOINT);
    categories.mountList(CATEGORIES_ENDPOINT);
  }, []);

  const activitiesRender = (
    <div>
      <GenControlsBar>
        <GenSearchBox
          {...activities}
        />
      </GenControlsBar>
      <GenSelectBar
        {...activities}
        itemType="skill(s)"
      />
      <GenListDisplay
        {...activities}
        renderItem={(item) => <p>{item.name}</p>}
      />
      <GenMore
        {...activities}
        shouldInfiniteScroll
      />
    </div>
  );

  const categoriesRender = (
    <div>
      <GenControlsBar>
        <GenSearchBox
          {...categories}
        />
      </GenControlsBar>
      <GenSelectBar
        {...categories}
        itemType="future course(s)"
      />
      <GenListDisplay
        {...categories}
        renderItem={(item) => <p>{item.name}</p>}
      />
      <GenMore
        {...categories}
        shouldInfiniteScroll
      />
    </div>
  );

  return (
    <div className="two-columns">
      <div className="column">
        <div className="panel">
          <h3>Skills To Add</h3>
          <input type="hidden" name="activity_ids" value={JSON.stringify(activities.selected.map((item) => item.id))} />
        </div>
        {activitiesRender}
      </div>
      <div className="column">
        <div className="panel">
          <h3>{capitalize(pluralize(i18n.t("category")))} To Use</h3>
          <input type="hidden" name="category_ids" value={JSON.stringify(categories.selected.map((item) => item.id))} />
        </div>
        {categoriesRender}
      </div>
    </div>
  );
}

export default connectToList(DocumentSetNewBatchPreset, ["activities", "categories"]);
