import React from 'react';

import CaptureSummary from 'Document/CaptureSummary'

export const captureListDefaults = {
  title:"My Cases",
  itemsURL:"/captures",
  itemView:function(document){
    return(<CaptureSummary document={document} key={document.id}/>);
  },
  search:true,
  searchPlaceHolder: "Search by title, or case #...",
  sortOptions:[
    {
      "title": "Case #",
      "value": "documents.id"
    },
    {
      "title": "Published",
      "value": "documents.created_at"
    }
  ]
};
