import consumer from "./consumer"

export default function connectAiChatSubscription({chatId, onMessageReceived}) {
  return consumer.subscriptions.create({ channel: "AiChatChannel", chat_id: chatId }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      const {
        message,
      } = data;

      onMessageReceived(message);
    },

    chat(messages) {
      this.perform("chat", { messages: messages })
    }
  });
}