import React from 'react';

import GenButton from 'Gen/Button'
import GenModal from 'Gen/Modal'
import GenTable from 'Gen/Table'

export default class GroupRoleDefinitionUserTable extends React.Component {
  state = {
    selectedGroupRoleDefinitionUserIds : [],
    destroyProcessing: false,
    setExpirationProcessing: false,
    key: 1,
    setExpirationModalOpen: false,
    setExpirationOption: 'past',
    setExpirationDay: '',
    setExpirationMonth: '',
    setExpirationYear: '',
  };

  handleGroupRoleDefinitionUserSelectionChange = (newSelection) => {
    this.setState({selectedGroupRoleDefinitionUserIds : newSelection});
  };

  handleDestroySelectedGroupRoleDefinitions = () => {
    if (this.state.selectedGroupRoleDefinitionUserIds.length === 0) {
      alert("Nothing Selected! You must select at least one item to lend.");
      return(null);
    }

    let data = {groupRoleDefinitionUserIds: this.state.selectedGroupRoleDefinitionUserIds};

    let destroy_url = window.location.href + "/destroy_batch";

    this.setState({destroyProcessing: true});

    fetch(destroy_url, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

        return response.json();
      })
      .then(this.onDestroySelectedGroupRoleDefinitionsSuccess)
      .catch(() => {
        this.setState({destroyProcessing: false});
        alert("Error: Removing Members!");
      });
  };

  handleToggleSetExpirationModalOpen = (e) => {
    this.setState({
      setExpirationModalOpen: !this.state.setExpirationModalOpen
    });
  };

  handleSetExpirationOptionChange = (e) => {
    this.setState({
      setExpirationOption: e.target.value
    });
  };

  handleSetExpirationDay = (e) => {
    this.setState({
      setExpirationDay: e.target.value
    });
  };

  handleSetExpirationMonth = (e) => {
    this.setState({
      setExpirationMonth: e.target.value
    });
  };

  handleSetExpirationYear = (e) => {
    this.setState({
      setExpirationYear: e.target.value
    });
  };

  intDate = () => {
    if (this.state.setExpirationMonth > 12 || this.state.setExpirationDay > 31 || this.state.setExpirationYear > 99) {
      return(NaN);
    } else {
      const date = Date.parse(this.state.setExpirationMonth + "/" + this.state.setExpirationDay + "/" + this.state.setExpirationYear);
      if (date > Date.now()) {
        return(date);
      } else {
        return(NaN);
      }
    }
  };

  validDate = () => {
    return(!isNaN(this.intDate()));
  };

  onDestroySelectedGroupRoleDefinitionsSuccess = () => {
    this.setState({
      selectedGroupRoleDefinitionUserIds : [],
      key: this.state.key + 1,
      destroyProcessing: false
    });
    alert("Members Removed!");
  };

  handleSetExpirationSubmit = (e) => {
    if (this.state.selectedGroupRoleDefinitionUserIds.length === 0) {
      alert("Nothing Selected! You must select at least one item to lend.");
      return(null);
    }

    let data = {
      group_role_definition_user_ids: this.state.selectedGroupRoleDefinitionUserIds,
      expiration_mode: this.state.setExpirationOption,
    };

    if (this.state.setExpirationOption === "date") {
      data.expiration_date = this.intDate();
    }

    let expiration_url = window.location.href + "/update_expiration_batch";

    this.setState({
      setExpirationProcessing: true,
      setExpirationModalOpen: false
    });

    fetch(expiration_url, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

        return response.json();
      })
      .then(this.onSetExpirationSubmitSuccess)
      .catch(() => {
        this.setState({destroyProcessing: false});
        alert("Error: Updating Members!");
      });
  };

  onSetExpirationSubmitSuccess = () => {
    this.setState({
      selectedGroupRoleDefinitionUserIds : [],
      key: this.state.key + 1,
      setExpirationProcessing: false
    });
    alert("Members Updated!");
  };

  render() {
    let invalidDateMessage = null;
    if (!this.validDate() && this.state.setExpirationMonth.length > 0 && this.state.setExpirationDay.length > 0 && this.state.setExpirationYear.length > 0) {
      invalidDateMessage = <p>Invalid Date: Date must be valid and in the future</p>;
    }

    let selectExtraButtons = [
      <GenButton key="RemoveMembers" name="Remove Selected Members" highlighted={true} loading={this.state.destroyProcessing} small={true} disabled={this.state.selectedGroupRoleDefinitionUserIds.length === 0} onClick={this.handleDestroySelectedGroupRoleDefinitions}/>
    ];

    if (this.props.is_admin) {
      selectExtraButtons.unshift(<GenButton key="SetExpiration" name="Set Expiration For Selected" highlighted={true} loading={this.state.setExpirationProcessing} small={true} disabled={this.state.selectedGroupRoleDefinitionUserIds.length === 0} onClick={this.handleToggleSetExpirationModalOpen}/>);
    }

    let role_filter_options = {}
    this.props.role_definition_names.forEach(function(role_definition_name) {
      role_filter_options[role_definition_name] = ["=", "role_definitions.name", role_definition_name]
    });

    return(
      <div>
      <GenModal open={this.state.setExpirationModalOpen} closeModelFunction={this.handleToggleSetExpirationModalOpen}>
        <div className="gen-modal-panel">
          <h1>Set Expiration</h1>
          <div>
            <form action="">
              <div>
                <label><input type="radio" value="past" checked={this.state.setExpirationOption === "past"} onChange={this.handleSetExpirationOptionChange}/>Expire Now</label>
              </div>
              <div>
                <label><input type="radio" value="never" checked={this.state.setExpirationOption === "never"} onChange={this.handleSetExpirationOptionChange}/>Never Expire</label>
              </div>
              <div>
                <label><input type="radio" value="date" checked={this.state.setExpirationOption === "date"} onChange={this.handleSetExpirationOptionChange}/>Expire On Date...</label>
              </div>
              <div>
                <input type="text" name="month" placeholder="mm"
                       disabled={"date" != this.state.setExpirationOption}
                       style={{width: 45 + 'px', display: 'inline-block'}}
                       value={this.state.setExpirationMonth}
                       onChange={this.handleSetExpirationMonth}/>/
                <input type="text" name="day" placeholder="dd"
                       disabled={"date" != this.state.setExpirationOption}
                       style={{width: 45 + 'px', display: 'inline-block'}}
                       value={this.state.setExpirationDay}
                       onChange={this.handleSetExpirationDay}/>/
                <input type="text" name="year" placeholder="yy"
                       disabled={"date" != this.state.setExpirationOption}
                       style={{width: 45 + 'px', display: 'inline-block'}}
                       value={this.state.setExpirationYear}
                       onChange={this.handleSetExpirationYear}/>
                {invalidDateMessage}
              </div>
            </form>
            <div className="gen-button-left-group">
              <GenButton name="Set Expiration" highlighted={true} loading={this.state.destroyProcessing} small={true} disabled={(this.state.setExpirationOption === "date") && !this.validDate()} onClick={this.handleSetExpirationSubmit}/>
              <GenButton name="Cancel" highlighted={true} small={true} onClick={this.handleToggleSetExpirationModalOpen}/>
            </div>
          </div>
        </div>
      </GenModal>
      <GenTable
        key={this.state.key}
        selected={this.state.selectedGroupRoleDefinitionUserIds}
        onSelectionChange={this.handleGroupRoleDefinitionUserSelectionChange}
        selectExtraButtons={selectExtraButtons}
        itemsURL={"/group_role_definition_users"}
        defaultItemsPerPage={24}
        search={true}
        whereFilterOptions={[
          {
            "title": "Role",
            "options": role_filter_options
          },
          {
            "title": "Subscription Status",
            "options": {
              "Invited": ["is_null", "group_role_definition_users.subscription_end"],
              "Active": [">", "group_role_definition_users.subscription_end", (new Date().toISOString())],
              "Expired": ["<", "group_role_definition_users.subscription_end", (new Date().toISOString())]
            }
          }
        ]}
        columns= {[
          {
            "title": "First Name",
            "sort_column": "users.first_name",
            "calculated_value": (item) => {
              return <a href={"group_role_definition_users/" + item.id}>{item.user.first_name}</a>
            }
          },{
            "title": "Last Name",
            "sort_column": "users.last_name",
            "calculated_value": (item) => {
              return <a href={"group_role_definition_users/" + item.id}>{item.user.last_name}</a>
            }
          },{
            "title": "Email",
            "column_width": "large",
            "sort_column": "users.email",
            "calculated_value": (item) => {
              return <a href={"group_role_definition_users/" + item.id}>{item.user.email}</a>
            }
          },{
            "title": "Role",
            "sort_column": "role_definitions.name",
            "calculated_value": (item) => {return item.role_definition.name}
          },{
            "title": "Subscription Start",
            "sort_column": "group_role_definition_users.subscription_start",
            "calculated_value": (item) => {
              if (item.subscription_end === null){
                return("Invited");
              } else {
                if (item.subscription_start === null){
                  return("");
                } else {
                  const subscription_start_date = new Date(item.subscription_start);
                  return(subscription_start_date.toISOString().slice(0, 10));
                }
              }
            }
          },{
            "title": "Subscription End",
            "sort_column": "group_role_definition_users.subscription_end",
            "calculated_value": (item) => {
              if (item.subscription_end === null){
                return("Invited");
              } else {
                const subscription_end_date = new Date(item.subscription_end);
                if (subscription_end_date.getTime() > new Date('2100')) {
                  return("Never Expires");
                } else if (subscription_end_date.getTime() > Date.now()) {
                  return(subscription_end_date.toISOString().slice(0, 10));
                } else {
                  return("Expired: " + subscription_end_date.toISOString().slice(0, 10));
                }
              }
            }
          }
        ]}
      />
      </div>
    );
  }
}
