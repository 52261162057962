import React from 'react';

import * as R from 'ramda'

import {
  setNodeValueByUIDAndKey
} from 'src/doc_helpers'

import DocBookmark from 'Doc/Bookmark'
import DocExtra from 'Doc/Extra'

import DocInlineText from 'Doc/InlineText'

import DocVideoJWPlayer from 'Doc/VideoJWPlayer'

import { performActions } from 'src/doc_actions'

export default function DocVideo(props) {
  const onVideoEditClick = () => {
    if (props.editState.isEditing) {
      props.editState.setCurrentlyEditing({uid: props.uid, menu: 'mediaMenu', mediaType: 'video'})
    }
  }

  const handleImagingTypeChange = (e) => {
    props.editState.modifyEditJSON(json =>
      setNodeValueByUIDAndKey(json, props.uid, 'imagingType', e.target.value)
    )
  }

  let video;
  switch (props.source) {
    case "local":
      const videoURL = props.currentVersion.aws_folder_url +
        "/original/videos/" +
        props.uri

      video = <video preload="none" controls>
        <source src={videoURL} />
      </video>

      break;
    case "youtube":
      video = <iframe width="560" height="315" src={"https://www.youtube.com/embed/" + props.uri} frameBorder="0" allowFullScreen></iframe>

      break;
    case "vimeo":
      video = <iframe src={"https://player.vimeo.com/video/" + props.uri} width="500" height="281" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>

      break;
    case "reeldx":
      let mediaAsset = props.currentDocument.media_assets.find(media_asset => media_asset.media_key === props.uri)

      let coverImageURL
      if (props.cover) {
        coverImageURL = `${props.currentVersion.aws_folder_url}/compressed/images/${props.cover}`
      }

      video = <DocVideoJWPlayer
        key={props.uri}
        videoURL={`https://d3o9veyy7wy4ai.cloudfront.net/${props.uri}`}
        coverImageURL={coverImageURL}
        hasCaptions={!!mediaAsset?.three_play_media_captions_processed_at || !mediaAsset} // TEMP: !mediaAsset enables captions even if file is not attached
        shouldLoop={!!mediaAsset?.is_silent}
        onVideoPlayEvent={time => props.currentUser.logEvent(props.uid, "video_played", time)}
        onVideoCompleteEvent={() => props.onVideoComplete && performActions(props.onVideoComplete, props.currentUser)}
      />

      break;
    default:
      return (
        <DocExtra
          subtype="error"
          content={"Unknown video source type: " + props.source}
        />
      );
  }

  return (
    <div className={`doc-video doc-video-${props.source}`}>
      <div className="doc-video-wrapper">
        {video}
        {props.editState.isEditing &&
         <>
           <i className="fa fa-cog bg-sky-600 p-2 doc-media-edit-button" onClick={onVideoEditClick}> Edit Video</i>
           {'imagingTypes' in props && R.length(props.imagingTypes) > 0 &&
            <select value={props.imagingType} onChange={handleImagingTypeChange} className="tag-select">
              <option value="">Select Imaging Type</option>
              {props.imagingTypes.map((t) => <option value={t} key={t}>{t}</option>)}
            </select>
           }
         </>
        }
      </div>
      <figcaption>
        <DocInlineText content={props.title} uid={props.uid} contentKey="title" editState={props.editState}/>
      </figcaption>
      <figcaption>
        {props.extraTitle}
      </figcaption>
      <DocBookmark {...props} />
    </div>
  );

}
