import React from 'react';

import GenLoadingSpinner from 'Gen/LoadingSpinner'

export default function GenPostButton(
  {
    disabled,
    onClick,
    highlighted,
    small,
    loading,
    url,
    data,
    name,
  },
) {
  const internalOnClick = (value) => {
    if (!disabled) {
      onClick(value);
      value.stopPropagation();
    }
  };

  let classes = "gen-button";

  if (disabled) {
    classes += " disabled";
  }

  if (highlighted) {
    classes += " highlighted";
  }

  if (small) {
    classes += " small";
  }

  if (loading) {
    return(
      <div className={classes} onClick={internalOnClick}>
        <span className="button-name"><GenLoadingSpinner/></span>
      </div>
    );
  } else {
    return (
      <div className={classes}>
        <form action={url} method='POST'>
          <input type='hidden' name='authenticity_token' value={document.head.querySelector("meta[name='csrf-token']").content} />
          {Object.keys(data).map((key, index) => {
            return <input type='hidden' key={key} name={key} value={JSON.stringify(data[key])} />;
          })}
          <input key='submit' type='submit' value={name} />
        </form>
      </div>
    );
  }
}
