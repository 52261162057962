import React from 'react';

import GenButton from 'Gen/Button'
import GenSelectList from 'Gen/SelectList'

import {documentListDefaults} from 'Document/ListDefaults'
import {groupListDefaults} from 'Group/ListDefaults'

import { documentDisplayTitle } from 'src/document_display_title'

export default class GroupDocumentCreateBatchSelectList extends React.Component {
  state = {
    selectedGroupDocumentIds : [],
    selectedGroupIds : [],
    loading: false
  };

  handleGroupDocumentSelectionChange = (newSelection) => {
    this.setState({selectedGroupDocumentIds : newSelection});
  };

  handleGroupSelectionChange = (newSelection) => {
    this.setState({selectedGroupIds : newSelection});
  };

  onSubmitSuccess = () => {
    const edit_url = window.location.href;
    const update_url = edit_url.replace("/group_documents/new_batch", "/documents");

    Turbolinks.visit(update_url);
  };

  handleSubmitButton = () => {
    if (this.state.selectedGroupDocumentIds.length === 0) {
      alert("Nothing Selected! You must select at least one item to lend.");
      return(null);
    }

    if (this.state.selectedGroupIds.length === 0) {
      alert("Nothing Selected! You must select at least one group to lend to.");
      return(null);
    }

    let data = {groupDocumentIds: this.state.selectedGroupDocumentIds, groupIds: this.state.selectedGroupIds};

    let edit_url = window.location.href;
    let update_url = edit_url.replace("new_", "create_");

    this.setState({loading: true});

    fetch(update_url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

        return response.json();
      })
      .then(this.onSubmitSuccess)
      .catch(() => {
        this.setState({loading: false});
      });
  };

  render() {
    return(
      <div>
        <div className="submit-panel">
          <h1>Lend Cases To Group</h1>
          <GenButton
            name="Lend"
            highlighted={true}
            loading={this.state.loading}
            onClick={this.handleSubmitButton}
          />
        </div>
        <div className="two-columns">
          <div className="column">
            <div className="panel">
              <h1>Groups</h1>
            </div>
            <GenSelectList
              {...groupListDefaults}
              selected={this.state.selectedGroupIds}
              onSelectionChange={this.handleGroupSelectionChange}
              defaultItemsPerPage={10000}
              itemView={(group) => (
                <div className="document-select-summary" key={group.id}>
                  <span>{group.id} | {group.name}</span>
                </div>
              )}
            />
          </div>
          <div className="column">
            <div className="panel">
              <h1>Cases</h1>
            </div>
            <GenSelectList
              {...documentListDefaults}
              selected={this.state.selectedGroupDocumentIds}
              onSelectionChange={this.handleGroupDocumentSelectionChange}
              defaultItemsPerPage={10000}
              itemView={(group_document) => (
                <div className="document-select-summary" key={group_document.id}>
                  <span>{documentDisplayTitle(group_document.document)}</span>
                </div>
              )}
              itemsURL={"/group_documents/new_batch"}
            />
          </div>
        </div>
      </div>
    );
  }
}
