import * as R from 'ramda'

const STATE_ACTION_NAMES = ['setNodeInactive', 'setNodeInvisible', 'setNodeKVPair']

export function performActions(actions, currentUser) {
  const [stateActions, otherActions] = R.partition((action) => STATE_ACTION_NAMES.indexOf(action.action) >= 0, actions);

  const stateActionsGroupByTarget = R.groupBy((stateAction) => stateAction.arguments.targetType ? [stateAction.arguments.targetType, stateAction.arguments.targetId] : [currentUser.addStateTargetType, currentUser.addStateTargetId], stateActions);

  R.values(stateActionsGroupByTarget).forEach((actionsForTarget) => {
    const targetType = actionsForTarget[0].arguments.targetType
    const targetId = actionsForTarget[0].arguments.targetId

    let unmergedStates = actionsForTarget.map((action) => {
      switch(action.action) {
        case 'setNodeInactive':
          return {[action.arguments.uid]: {"inactive": action.arguments.value}}
        case 'setNodeInvisible':
          return {[action.arguments.uid]: {"invisible": action.arguments.value}}
        case 'setNodeKVPair':
          return {[action.arguments.uid]: {[action.arguments.key]: action.arguments.value}}
        }
    });

    const state = R.reduce(R.mergeDeepLeft, R.head(unmergedStates), R.tail(unmergedStates));

    switch (targetType) {
      case "activity":
        currentUser.addActivityUserState(targetId, state);
        break;
      case "document":
        currentUser.addDocumentUserState(state);
        break;
      default:
        currentUser.addState(state);
        break;
    }
  })

  otherActions.forEach((action, index, array) => {
    switch(action.action) {
      case 'openNode':
        currentUser.openSection(action.arguments.uid);
        break;
      case 'openLink':
        Turbolinks.visit(action.arguments.href);
        break;
    }
  });
}