import React from 'react';

export default class GenDirectionalButtons extends React.Component {
  // Props
  // title : HTML
  // value : String
  // direction : ""
  // disabled : Bool

  state = {
    selectedValue: null,
    selectedDirection: 'ascending'
  };

  onButtonClick = (value) => {
    let direction = 'ascending';
    if (this.props.selectedValue === value) {
      direction = this.props.selectedDirection === 'ascending' ? 'descending' : 'ascending';
    }

    this.props.onUpdate(value, direction);
  };

  buttons = () => {
    let selectedValue = this.props.selectedValue;
    let selectedDirection = this.props.selectedDirection;
    let outsideDisabled = this.props.disabled;
    let onButtonClick = this.onButtonClick;

    return(
      this.props.buttons.map(function(button){
        let classes = "gen-directional-button";
        let spanClasses = "bg-sky-600 hover:bg-sky-500"

        if (outsideDisabled || button.disabled) {
          classes += " disabled";
        }

        if (button.value === selectedValue) {
          classes += " selected " + selectedDirection;
        } else {
          spanClasses = "bg-white hover:bg-slate-100"
        }

        return(
          <div className={classes} key={button.value} value={button.value} onClick={onButtonClick.bind(null, button.value)}>
            <span className={spanClasses}>{button.title}</span>
          </div>
        );
      })
    );
  };

  render() {
    return(
      <div className="gen-directional-buttons">
        {this.buttons()}
      </div>
    );
  }
}
