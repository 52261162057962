import {
  generateItemsReducer,
  generateActions,
  itemFromStore,
  itemLocalDataFromStore
} from 'reducers/item'

const itemType = "document"
const endpoint = "documents/"

export const documentsReducer = generateItemsReducer(itemType)

export const {
  seedDocument,
  createDocument,
  readDocument,
  updateDocument,
  destroyDocument,
  setEditFieldsDocument,
  resetEditFieldsDocument,
  setLocalFieldsDocument,
  resetLocalFieldsDocument,
} = generateActions(itemType, endpoint)

export const documentFromStore = (store, id) => itemFromStore(store, itemType, id)
export const documentLocalDataFromStore = (store, id) => itemLocalDataFromStore(store, itemType, id)
