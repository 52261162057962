export function documentDisplayTitle(document) {
  return `${documentDisplayNumber(document)} | ${document.title}`;
}

export function documentDisplayNumber(document) {
  let displayId = document.id;
  if (document.non_ai_document_id) {
    displayId = `${document.non_ai_document_id} AI`;
  }

  return displayId;
}
