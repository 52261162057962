import React from "react";

import * as R from "ramda";

import GenButton from "../Gen/Button";
import GenLoadingSpinner from "../Gen/LoadingSpinner";
import GenModal from "../Gen/Modal";

import ActivityReviewSummary from "../Activity/ReviewSummary";

import { stringDateToDisplayDateString } from "../../src/time_display";

import { documentDisplayTitle } from 'src/document_display_title'

const drawerUidsInOrder = [
  "vitals",
  "differential",
  "workup",
  "diagnosis",
  "outcomes",
  "care",
  "keypoints",
];

function uidIndex(placementUid) {
  const foundIndex = drawerUidsInOrder.indexOf(placementUid);
  return foundIndex >= 0 ? foundIndex : drawerUidsInOrder.length;
}

function beforeInsideIndex(placementBefore, placementInside) {
  if (placementBefore && !placementInside) {
    return 0;
  } else if (placementBefore && placementInside) {
    return 1;
  } else if (!placementBefore && placementInside) {
    return 2;
  } else {
    return 3;
  }
}

function activityOrderIndices({
  placement_uid, placement_before, placement_inside, order,
}) {
  return [uidIndex(placement_uid), beforeInsideIndex(placement_before, placement_inside), order];
}

export default class CatalogReviewModal extends React.Component {
  constructor(props) {
    super(props);

    const {
      review_comment,
    } = props;

    this.state = {
      reviewComment: review_comment,
      loading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const {
      documentSetId,
      document,
      user,
    } = this.props;

    fetch(`/activity_report/${documentSetId}/${document.id}/${user.id}`, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

        return response.json();
      })
      .then((data) => {
        this.setState({
          ...data,
          loading: false,
        });
      })
      .catch(() => { alert("There was an issue loading the data. Please try again."); });
  }

  postDocumentSetDocumentStateData(id, data) {
    const {
      afterSave,
    } = this.props;

    fetch(`/document_set_document_states/${id}`, {
      method: "PUT",
      body: JSON.stringify({document_set_document_state: data}),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

        return response.json();
      })
      .then(() => { afterSave(); })
      .catch(() => { alert("There was an error saving your review."); });
  }

  render() {
    const {
      activities,
      activity_user_states_by_activity_id,
      loading,
      reviewComment,
    } = this.state;

    const {
      afterCancel,
      allItems,
      document,
      documentSetId,
      review_submitted,
      setReviewRow,
      submitted_at,
      user,
    } = this.props;

    let renderReview;
    if (loading) {
      renderReview = <GenLoadingSpinner />;
    } else {
      const renderedActivities = R.sortBy((activity) => activityOrderIndices(activity), activities).map((activity) => (
        <div key={activity.uid}>
          <h3>{activity.name}</h3>
          <ActivityReviewSummary
            activity={activity}
            activityUserState={activity_user_states_by_activity_id[activity.id]}
          />
          <hr />
        </div>
      ));

      renderReview = (
        <div>
          <div className="review-modal-feedback" style={{marginBottom: "10px"}}>
            <div>
              <p>Feedback:</p>
              <textarea
                style={{resize: "none"}}
                rows="4"
                value={reviewComment}
                disabled={review_submitted}
                onChange={(e) => { this.setState({reviewComment: e.target.value}); }}
              />
            </div>
            <div>
              <div className="review-buttons">
                <GenButton
                  name="Save Draft"
                  highlighted
                  disabled={review_submitted}
                  onClick={() => {
                    this.postDocumentSetDocumentStateData(`${documentSetId}/${document.id}/${user.id}`, {
                      review_comment: reviewComment,
                    });
                  }}
                />
                <GenButton
                  name="Submit Review"
                  highlighted
                  disabled={review_submitted}
                  onClick={() => {
                    this.postDocumentSetDocumentStateData(`${documentSetId}/${document.id}/${user.id}`, {
                      review_comment: reviewComment,
                      reviewed_at: (new Date()).toISOString(),
                    });
                  }}
                />
                <GenButton
                  name="Close"
                  highlighted
                  onClick={afterCancel}
                />
              </div>
            </div>
          </div>

          <hr />

          <div className="activity">
            {renderedActivities}
          </div>
        </div>
      );
    }

    const sumbittedItems = allItems.filter((item) => item.submitted_at);
    const totalReviewableCount = sumbittedItems.length;
    const reviewIndex = sumbittedItems.findIndex((item) => item.user.id === user.id && item.document.id === document.id);

    const reviewNavigation = (
      <div className="absolute top-0 right-10 flex items-center">
        <GenButton
          name={<span><i className="fa fa-chevron-left" /> Prev</span>}
          highlighted
          disabled={reviewIndex === 0}
          onClick={() => {
            const newRow = sumbittedItems[reviewIndex - 1];
            setReviewRow(newRow);
            this.setState({
              reviewComment: newRow.review_comment,
              loading: true,
            }, this.getData.bind(this));
          }}
        />
        <div className="p-3 border-solid">{reviewIndex + 1}/{totalReviewableCount}</div>
        <GenButton
          name={<span>Next <i className="fa fa-chevron-right" /></span>}
          highlighted
          disabled={reviewIndex === totalReviewableCount - 1}
          onClick={() => {
            const newRow = sumbittedItems[reviewIndex + 1];
            setReviewRow(newRow);
            this.setState({
              reviewComment: newRow.review_comment,
              loading: true,
            }, this.getData.bind(this));
          }}
        />
      </div>
    );

    return (
      <GenModal open className="activity-review-modal">
        <div className="gen-modal-panel">
          <div className="review-modal-header relative">
            <h1>Case {document.id} Review</h1>
            <p style={{marginBottom: "5px"}}><strong>Case:</strong> <a href={`/documents/${document.id}`} target="_blank" rel="noreferrer">{documentDisplayTitle(document)}</a></p>
            <p style={{marginBottom: "5px"}}><strong>Student&apos;s Name:</strong> {user.first_name} {user.last_name}</p>
            <p style={{marginBottom: "5px"}}><strong>Student&apos;s Email:</strong> {user.email}</p>
            <p style={{marginBottom: "5px"}}><strong>Submission Time:</strong> {stringDateToDisplayDateString(submitted_at)}</p>

            {reviewNavigation}
          </div>

          <hr />

          {renderReview}
        </div>
      </GenModal>
    );
  }
}
