import * as R from 'ramda'

import { splitInlineJSONAtIndex } from 'src/inline_json'

export function deepFind(data, tester) {
  let result = null;
  if (data instanceof Array) {
    for(let i = 0; i < data.length; i++) {
      result = deepFind(data[i], tester);
      if (result) {
        return result;
      }
    }
  } else if (data instanceof Object) {
    if(tester(data)) {
      return data;
    }

    for(let prop in data) {
      if(data[prop] instanceof Object || data[prop] instanceof Array) {
        result = deepFind(data[prop], tester);
        if (result) {
          return result;
        }
      }
    }
  }
  return result;
}

export function deepFindLineage(data, tester) {
  let result = null;
  if (data instanceof Array) {
    for(let i = 0; i < data.length; i++) {
      result = deepFindLineage(data[i], tester);
      if (result) {
        return result;
      }
    }
  } else {
    if(tester(data)) {
      return [data];
    }

    for(let prop in data) {
      if(data[prop] instanceof Object || data[prop] instanceof Array) {
        result = deepFindLineage(data[prop], tester);
        if (result) {
          return ([data].concat(result));
        }
      }
    }
  }
  return result;
}

export function deepFindPath(data, tester) {
  let result = null;
  if (data instanceof Array) {
    for(let i = 0; i < data.length; i++) {
      result = deepFindPath(data[i], tester);
      if (result) {
        return [i].concat(result);
      }
    }
  } else {
    if(tester(data)) {
      return [];
    }

    for(let prop in data) {
      if(data[prop] instanceof Object || data[prop] instanceof Array) {
        result = deepFindPath(data[prop], tester);
        if (result) {
          return ([prop].concat(result));
        }
      }
    }
  }
  return result;
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function deepFindByUID(data, uid) {
  return(deepFind(data, datum => (datum.type !== undefined) && (datum.uid === uid)));
}

export function deepFindLineageByUID(data, uid) {
  return(deepFindLineage(data, datum => (datum.type !== undefined) && (datum.uid === uid)));
}

export function deepFindAncestorsByUID(data, uid) {
  let lineage = deepFindLineageByUID(data, uid)

  return lineage && R.init(lineage)
}

export function deepFindParentByUID(data, uid) {
  let lineage = deepFindLineageByUID(data, uid)

  return lineage && R.last(R.init(lineage))
}

export function deepFindSiblingBeforeByUID(data, uid) {
  let path = deepFindPathByUID(data, uid)

  if (R.length(path) > 0 && R.last(path) > 0) {
    let siblingLineage = R.update(-1, R.last(path) - 1, path)

    return R.view(R.lensPath(siblingLineage), data)
  } else {
    return undefined
  }
}

export function deepFindOldestNotSectionAncestorByUID(data, uid) {
  let lineage = deepFindLineageByUID(data, uid)

  return R.find(node => node.type !== 'section', lineage)
}

export function deepFindYoungestSectionAncestorByUID(data, uid) {
  let lineage = deepFindLineageByUID(data, uid)

  return R.findLast(node => node.type === 'section', lineage)
}

export function deepFindPathByUID(data, uid) {
  return(deepFindPath(data, datum => (datum.type !== undefined) && (datum.uid === uid)));
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function insertBeforeNodeByUID(data, uid, toAdd) {
  let path = deepFindPathByUID(data, uid)
  let newIndex = R.last(path)

  return R.over(R.lensPath(R.init(path)), R.insert(newIndex, toAdd), data)
}

export function insertAfterNodeByUID(data, uid, toAdd) {
  let path = deepFindPathByUID(data, uid)
  let newIndex = R.last(path) + 1

  return R.over(R.lensPath(R.init(path)), R.insert(newIndex, toAdd), data)
}

export function insertAfterNodeByUIDAtSectionLevel(data, uid, toAdd) {
  let lineage = deepFindLineageByUID(data, uid)
  let parentSection = R.findLast(R.propEq('type', 'section'), lineage)

  let pathFromParent = deepFindPathByUID(parentSection, uid)
  let newParentContentKey = pathFromParent[0]
  let newParentIndex = pathFromParent[1] + 1

  let pathToParent = deepFindPathByUID(data, parentSection.uid)

  return R.over(R.lensPath(pathToParent.concat([newParentContentKey])), R.insert(newParentIndex, toAdd), data)
}

export function deleteNodeByUID(data, uid) {
  let path = deepFindPathByUID(data, uid)

  return path ? R.over(R.lensPath(R.init(path)), R.remove(R.last(path), 1), data) : data
}

export function replaceNodeByUID(data, uid, toReplaceWith) {
  let path = deepFindPathByUID(data, uid)

  return R.over(R.lensPath(R.init(path)), R.update(R.last(path), toReplaceWith), data)
}

export function replaceNodeWithArrayByUID(data, uid, toReplaceWith) {
  let path = deepFindPathByUID(data, uid)

  let indexToReplace = R.last(path)
  let functionToReplaceWith = R.compose(R.insertAll(indexToReplace, toReplaceWith), R.remove(indexToReplace, 1))

  return R.over(R.lensPath(R.init(path)), functionToReplaceWith, data)
}

export function setNodeValueByUIDAndKey(data, uid, key, newValue) {
  let mediaTypePath = deepFindPathByUID(data, uid)
  let mediaTypeLens = R.lensPath(mediaTypePath.concat([key]))

  return R.set(mediaTypeLens, newValue, data)
}

export function modifyNodeValueByUIDAndKey(data, uid, key, func) {
  let mediaTypePath = deepFindPathByUID(data, uid)
  let mediaTypeLens = R.lensPath(mediaTypePath.concat([key]))

  return R.over(mediaTypeLens, func, data)
}

export function siblingIndexByUID(data, uid) {
  return R.last(deepFindPathByUID(data, uid))
}

export function siblingCountByUID(data, uid) {
  return R.length(R.view(R.lensPath(R.init(deepFindPathByUID(data, uid))), data))
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function splitNodeAtIndex(node, contentKey, index) {
  const [content1, content2] = splitInlineJSONAtIndex(node[contentKey], index)

  return [
    {
      ...node,
      [contentKey]: content1
    },
    {
      ...node,
      [contentKey]: content2
    },
  ]
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
import { v4 as uuidv4 } from 'uuid'

export function generateNode(type, args={}) {
  let defaults
  switch (type) {
    case 'paragraph':
    case 'listItem':
      defaults = {
        content: ""
      }
      break;
    case 'regularList':
    case 'numberedList':
      defaults = {
        content: [generateNode('listItem')]
      }
      break;
    case 'audio':
    case 'image':
      defaults = {
        title : '',
        source: 'local',
        uri: '',
      }
      break;
    case 'video':
      defaults = {
        title : '',
        source: 'reeldx',
        uri: '',
      }
      break;
    default:
      defaults = {}
  }

  return {
    type: type,
    uid: uuidv4(),
    ...defaults,
    ...args
  }
}

export function newAction(action, args={}) {
  return {
    type: "action",
    action,
    arguments: args,
  }
}

export function newSetNodeKVPairAction(uid, key, value, targetType, targetId) {
  return newAction("setNodeKVPair", { uid, key, value, targetType, targetId });
}

export function newOpenLinkAction(href) {
  return newAction("openLink", { href });
}