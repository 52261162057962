import React from 'react';

import classNames from 'classnames'

import DocBookmark from 'Doc/Bookmark'

export default class DocSliderBar extends React.Component {
  state = {
    value: this.props.value ? this.props.value : this.props.minValue,
    timer: null
  };

  componentWillReceiveProps(newProps) {
    if ((newProps.value != this.props.value) && (newProps.value != this.state.value)) {
      this.setState({value: newProps.value});
    }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
      self.updateUSCWithTimer();
    }
  }

  handleChange = (e) => {
    this.setState({value: parseInt(e.target.value)});

    if (this.state.timer) { clearTimeout(this.state.timer); }
    this.setState({timer: setTimeout(this.updateUSCWithTimer, 2000)});
  };

  updateUSC = () => {
    this.props.currentUser.addState({[this.props.uid]: {"value": this.state.value}});
  };

  updateUSCWithTimer = () => {
    this.updateUSC();
    this.setState({timer: null});
  };

  render() {
    return (
      <div className={classNames("doc-slider-bar", {"inactive": this.props.inactiveBoolean})}>
        <input type="range" min={this.props.minValue} max={this.props.maxValue} step={this.props.step} disabled={this.props.inactiveBoolean} value={this.state.value} onChange={this.handleChange}/>
        {this.props.showValue ? <h6>{this.state.value}</h6> : ""}
        <DocBookmark {...this.props} />
      </div>
    )
  }
}
