import React from "react";

import * as R from "ramda";

import connectToList from "store/list";

import { batch } from "react-redux";

import {
  stringToDate,
  translateToEffectiveDateReverse,
  translateToEffectiveDate,
  areEqualEffectiveDates,
  now,
  yesterday,
  atEarliestNow,
  selectionDescription,
} from "src/effective_dates"

import GenButton from "Gen/Button";
import GenModal from "Gen/Modal";
import GenMore from "Gen/More";
import DocumentSetDocumentRelationScheduleStatus from "DocumentSetDocumentRelation/ScheduleStatus";
import GenControlsBar from "Gen/ControlsBar";
import GenSearchBox from "Gen/SearchBox";
import GenSelectBar from "Gen/SelectBar";
import GenSwitch, {switchModes} from "Gen/Switch";
import GenTableDisplay from "Gen/TableDisplay";

import DateTimePicker from "react-widgets/lib/DateTimePicker";

import "react-widgets/dist/css/react-widgets.css";

const ITEMS_PER_PAGE = 1000; // TODO: Look into why Gen/More doesn't work in Chrome
const TRUNCATE_COUNT = 8;


class DocumentSetEditDocumentsAccessTable extends React.Component {
  state = {
    // Updating DocumentSetDocumentRelation Records
    dsdrDocumentSchedule: {},
    dsdrItemSchedules: {},

    // Controls
    setVisibilityProcessing: false,
    setVisibilityModalOpen: false,
    showingCaseVisibilityControls: false,
  };

  componentDidMount() {
    batch(() => {
      this.props.documentSetDocumentRelations.setListItemsPerPage(ITEMS_PER_PAGE);
      this.props.documentSetDocumentRelations.mountList(this.endpoint());
    });
  }

  endpoint() {
    return  "/document_sets/" + this.props.document_set_id + "/document_set_document_relations";
  }

  selectedDocumentSetDocumentRelationIds() {
    return this.props.documentSetDocumentRelations.selected.map((item) => item.id);
  }

  handleToggleSetVisibilityModalOpen(e) {
    this.setState({
      setVisibilityModalOpen: !this.state.setVisibilityModalOpen,
      dsdrDocumentSchedule: {},
      dsdrItemSchedules: {},
      showingCaseVisibilityControls: false
    });
  };

  handleSetVisibilitySubmit(e) {
    const data = {
      document_set_document_relation_ids: this.selectedDocumentSetDocumentRelationIds(),
      ...this.state.dsdrDocumentSchedule,
      drawer_schedules: this.state.dsdrItemSchedules
    };

    const visibilityUrl = window.location.href.replace("/edit_documents_access", "/update_documents_access");

    this.setState({ setVisibilityProcessing: true });

    fetch(visibilityUrl, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

        return response.json();
      })
      .then(this.onSetVisibilitySubmitSuccess.bind(this))
      .catch(() => {
        alert("Error: Updating Visibility!");
      });
  };

  onSetVisibilitySubmitSuccess() {
    batch(() => {
      this.props.documentSetDocumentRelations.setListSelected([]);
      this.props.documentSetDocumentRelations.resetListSearch();
      this.props.documentSetDocumentRelations.resetListPages();
      this.props.documentSetDocumentRelations.readListPage();
    });

    this.setState({
      dsdrDocumentSchedule: {},
      dsdrItemSchedules: {},
      setVisibilityProcessing: false,
      setVisibilityModalOpen: false,
      showingCaseVisibilityControls: false
    });
  }

  itemVisibilityFor(show_after) {
    const show_after_date = stringToDate(show_after);

    let status = "";
    let className = "";
    if (show_after && !(show_after_date instanceof Date)) {
      className = "hidden-cell";
    } else if (show_after_date && show_after_date > now()) {
      status = `${show_after_date.getMonth() + 1}/${show_after_date.getDate()}/${show_after_date.getFullYear()}`;
      className = "hidden-until-cell";
    } else {
      className = "visible-cell";
    }

    return { status: <span className="schedule-status">{status}</span>, className: className };
  }

  defaultSwitchStates() {
    const selectedDSDRs = this.props.documentSetDocumentRelations.selected;

    const caseSwitchMode = selectedDSDRs.reduce((acc, dsdr) => {
      const show_after = translateToEffectiveDate(dsdr.show_after);
      const show_until = translateToEffectiveDateReverse(dsdr.show_until);

      if (acc.hasOwnProperty("switchMode")) {
        if (!areEqualEffectiveDates(acc.show_after, show_after) || !areEqualEffectiveDates(acc.show_until, show_until)) {
          return {
            switchMode: switchModes.MIXED
          };
        }
      } else {
        return {
          switchMode: show_after || show_until ? switchModes.OFF : switchModes.ON,
          show_after: show_after,
          show_until: show_until
        };
      }

      return acc;
    }, {});


    const itemKeys = R.uniq(selectedDSDRs.flatMap((dsdr) => Object.keys(dsdr.drawer_schedules)));
    const itemSwitchModes = selectedDSDRs.reduce((acc, dsdr) => {
      itemKeys.forEach((key) => {
        const showAfter = R.view(R.lensPath(["drawer_schedules", key, "hide_until"]), dsdr);
        const effectiveShowAfter = translateToEffectiveDate(showAfter);

        if (acc.hasOwnProperty(key)) {
          if (!areEqualEffectiveDates(acc[key].hide_until, effectiveShowAfter)) {
            acc[key] = {
              switchMode: switchModes.MIXED
            };
          }
        } else {
          acc[key] = {
            switchMode: !effectiveShowAfter ? switchModes.ON : switchModes.OFF,
            hide_until: effectiveShowAfter
          };
        }
      });

      return acc;
    }, {});

    return {
      caseSwitchMode,
      itemSwitchModes
    };
  }

  dsdrDocumentScheduleCorrection(dsdrDocumentSchedule) {
    const {
      show_after,
      show_until
    } = dsdrDocumentSchedule;

    const newDsdrDocumentSchedule = {
      ...dsdrDocumentSchedule
    };

    if (show_after) {
      if (show_until && show_until < show_after) {
        newDsdrDocumentSchedule.show_until = show_until < yesterday() ? null : show_after;
      }
    } else {
      if (!show_until) {
        newDsdrDocumentSchedule.show_until = new Date(0);
      }
    }

    return newDsdrDocumentSchedule;
  }

  render() {
    const {
      dsdrDocumentSchedule,
      dsdrItemSchedules,
      showingCaseVisibilityControls
    } = this.state;

    const selectedCount = this.props.documentSetDocumentRelations.selected.length;

    const switchStateModes = this.defaultSwitchStates();

    const selectExtraButtons = [
        <GenButton
          key="SetSchedule"
          name="Set Visibility For Selected"
          highlighted
          small
          disabled={selectedCount === 0}
          onClick={this.handleToggleSetVisibilityModalOpen.bind(this)}
        />
    ];

    const defaultCaseSwitchMode = switchStateModes.caseSwitchMode.switchMode || switchModes.ON;
    const caseSwitchMode = showingCaseVisibilityControls ? showingCaseVisibilityControls : defaultCaseSwitchMode;

    let caseStateText;
    switch (caseSwitchMode) {
      case switchModes.ON:
        caseStateText = "Showing";
        break;
      case switchModes.OFF:
        caseStateText = "Schedule Case Visibility";
        break;
      case switchModes.MIXED:
        caseStateText = "Selected cases will retain current settings";
        break;
    }

    const show_after = dsdrDocumentSchedule.hasOwnProperty("show_after") ? dsdrDocumentSchedule.show_after : switchStateModes.caseSwitchMode.show_after;
    const show_until = dsdrDocumentSchedule.hasOwnProperty("show_until") ? dsdrDocumentSchedule.show_until : switchStateModes.caseSwitchMode.show_until;

    const dsdrDocumentScheduleComputed = {
      show_after: show_after,
      show_until: show_until
    };

    const caseVisibilityControls = <div className="case-visibility-controls">
      <div className="set-visibility-row">
        <p className="visibility-control-detail-paragraph"> Show after: </p>
        <DateTimePicker
          min={now()}
          placeholder={"optional"}
          value={show_after instanceof Date ? show_after : null}
          onChange={(date) => this.setState({dsdrDocumentSchedule: this.dsdrDocumentScheduleCorrection({...dsdrDocumentScheduleComputed, show_after: atEarliestNow(date)})})}
        />
        <GenButton
          name="X"
          highlighted
          disabled={!(show_after instanceof Date)}
          small
          className="clear-button"
          onClick={() => this.setState({dsdrDocumentSchedule: this.dsdrDocumentScheduleCorrection({...dsdrDocumentScheduleComputed, show_after: null})})}
        />
      </div>

      <div className="set-visibility-row">
        <p className="visibility-control-detail-paragraph"> Show until: </p>
        <DateTimePicker
          min={show_after || now()}
          placeholder={"optional"}
          value={show_until instanceof Date && show_until > yesterday() ? show_until : null}
          onChange={(date) => {
            const dateToUse = date < now() ? now() : date;
            this.setState({dsdrDocumentSchedule: this.dsdrDocumentScheduleCorrection({...dsdrDocumentScheduleComputed, show_until: dateToUse})});
          }}
        />
        <GenButton
          name="X"
          highlighted
          disabled={!(show_until instanceof Date) || show_until < yesterday()}
          small
          className="clear-button"
          onClick={() => this.setState({dsdrDocumentSchedule: this.dsdrDocumentScheduleCorrection({...dsdrDocumentScheduleComputed, show_until: null})})}
        />
      </div>
    </div>;

    const scheduleItemInfos = [
      {key: "vitals", title: "Vitals and Demographics", shortTitle: "Vitals"},
      {key: "differential", title: "Differential Diagnosis", shortTitle: "Diff"},
      {key: "workup", title: "Patient Workup", shortTitle: "Workup"},
      {key: "diagnosis", title: "Diagnosis", shortTitle: "Diag"},
      {key: "outcomes", title: "Actual Outcomes", shortTitle: "Outcomes"},
      {key: "care", title: "Standard of Care", shortTitle: "Stdrds"},
      {key: "keypoints", title: "Key Points", shortTitle: "Key Points"}
    ];

    const scheduleItems = scheduleItemInfos.map((itemInfo) => {
      const itemDefaults = switchStateModes.itemSwitchModes[itemInfo.key];
      const defaultSwitchMode = (itemDefaults && itemDefaults.switchMode) || switchModes.ON;

      let showAfter, switchMode;
      if (dsdrItemSchedules.hasOwnProperty(itemInfo.key)) {
        showAfter = dsdrItemSchedules[itemInfo.key] && dsdrItemSchedules[itemInfo.key].hide_until;
        switchMode = !showAfter ? switchModes.ON : switchModes.OFF;
      } else {
        showAfter = itemDefaults && itemDefaults.hide_until;
        switchMode = defaultSwitchMode;
      }

      let stateText;
      switch (switchMode) {
        case switchModes.ON:
          stateText = "Showing";
          break;
        case switchModes.OFF:
          stateText = "Hide until:";
          break;
        case switchModes.MIXED:
          stateText = "Selected cases will retain current settings";
          break;
      }

      return <div key={itemInfo.key} className="drawer">
        <strong className="drawer-strong"> {itemInfo.title} </strong>
        <div>
          <div className="set-visibility-row">
            <GenSwitch
              value={switchMode}
              includeMixed={defaultSwitchMode === switchModes.MIXED}
              onClick={(newSwitchMode) => {
                switch (newSwitchMode) {
                  case switchModes.MIXED:
                    this.setState({ dsdrItemSchedules: R.omit([itemInfo.key], dsdrItemSchedules) });
                    break;
                  case switchModes.ON:
                    this.setState({ dsdrItemSchedules: {...dsdrItemSchedules, [itemInfo.key]: {hide_until: false}} });
                    break;
                  case switchModes.OFF:
                    this.setState({ dsdrItemSchedules: {...dsdrItemSchedules, [itemInfo.key]: {hide_until: true}} });
                    break;
                }
              }}
            />
            <p className="visibility-control-detail-paragraph"> {stateText} </p>
            {switchMode === switchModes.OFF && <>
              <DateTimePicker
                min={now()}
                placeholder={"Forever"}
                value={showAfter instanceof Date ? showAfter : null}
                onChange={(date) => {
                  const dateToUse = date < now() ? now() : date;
                  this.setState({dsdrItemSchedules: {...dsdrItemSchedules, [itemInfo.key]: {hide_until: dateToUse}}});
                }}
              />
              <GenButton
                name="X"
                highlighted
                disabled={!(showAfter instanceof Date)}
                small
                className="clear-button"
                onClick={() => this.setState({ dsdrItemSchedules: {...dsdrItemSchedules, [itemInfo.key]: {hide_until: true}}})}
              />
            </>}
          </div>
        </div>
      </div>;
    });

    let caseIDs = this.props.documentSetDocumentRelations.selected.map((item) => item.document_id).sort((a, b) => a-b);
    let showMore;
    if (selectedCount > TRUNCATE_COUNT + 1) {
      if (!this.state.showAllCaseIDSummary) {
        caseIDs = caseIDs.slice(0, TRUNCATE_COUNT);
        showMore = <>and {selectedCount - TRUNCATE_COUNT} more. <a style={{"text-decoration": "underline"}}>Show All</a></>;
      } else {
        showMore = <> <a style={{"text-decoration": "underline"}}>Hide All</a></>;
      }
    }

    const modalCaseIDSummary = <p className='case-ids'>Changes made will affect case(s) with id(s)... {caseIDs.join(", ")} {showMore}</p>;
    return <div>
      <GenModal open={this.state.setVisibilityModalOpen} closeModelFunction={this.handleToggleSetVisibilityModalOpen.bind(this)}>
        <div className="gen-modal-panel">
          <div className="edit-documents-access-modal-header">
            <h1>Set Visibility</h1>
            <div className="gen-button-right-group">
              <GenButton
                name="Cancel"
                highlighted
                small
                onClick={this.handleToggleSetVisibilityModalOpen.bind(this)}
              />
              <GenButton
                name="Save"
                highlighted
                small
                loading={this.state.setVisibilityProcessing}
                onClick={this.handleSetVisibilitySubmit.bind(this)}
              />
            </div>
          </div>

          <div className="case-control-header">
            <h3 className="visibility-h3"> Case{(selectedCount > 1) ? "s" : ""} {`(${selectedCount} Selected)`}</h3>
            <h6 className="case-state-label">
              <DocumentSetDocumentRelationScheduleStatus
                showAfter={show_after}
                showUntil={show_until}
                mixedOverride={caseSwitchMode === switchModes.MIXED}
                hideOverride={caseSwitchMode === switchModes.OFF}
              />
            </h6>
          </div>

          <div onClick={() => this.setState({showAllCaseIDSummary: !this.state.showAllCaseIDSummary})}>
            {modalCaseIDSummary}
          </div>

          <div className="edit-documents-access-modal-bordered-section">
            <div className="case-visibility-switch-row">
              <GenSwitch
                value={caseSwitchMode}
                includeMixed={switchStateModes.caseSwitchMode.switchMode === switchModes.MIXED}
                onClick={(newSwitchMode) => {
                  let dsdrDocumentSchedule;
                  switch (newSwitchMode) {
                    case switchModes.ON:
                      dsdrDocumentSchedule = {
                        show_after: null,
                        show_until: null
                      };
                      break;
                    case switchModes.OFF:
                      if (switchStateModes.caseSwitchMode.switchMode === switchModes.OFF) {
                        dsdrDocumentSchedule = {};
                      } else {
                        dsdrDocumentSchedule = this.dsdrDocumentScheduleCorrection({
                          show_after: null,
                          show_until: null
                        });
                      }

                      break;
                    case switchModes.MIXED:
                    default:
                      dsdrDocumentSchedule = {};
                  }

                  this.setState({
                    showingCaseVisibilityControls: newSwitchMode,
                    dsdrDocumentSchedule: dsdrDocumentSchedule
                  });
                }}
              />
              <h6 className="case-state-text"> {caseStateText} </h6>
            </div>
            {caseSwitchMode === switchModes.OFF && caseVisibilityControls}
          </div>

          <h5 className="drawer-control-header"> Drawers </h5>
          <div className="edit-documents-access-modal-bordered-section">
            {scheduleItems}
          </div>
        </div>
      </GenModal>
      <GenSelectBar
        {...this.props.documentSetDocumentRelations}
        itemType={"case(s)"}
        selectExtraButtons={selectExtraButtons}
        selectionDescription={selectionDescription}
      />
      <GenControlsBar>
        <GenSearchBox
          {...this.props.documentSetDocumentRelations}
          showSearch
        />
      </GenControlsBar>
      <GenTableDisplay
        {...this.props.documentSetDocumentRelations}
        columns= {[
          {
            "title": "",
            "sort_column": "",
            "calculated_value": (item) => (
              <DocumentSetDocumentRelationScheduleStatus
                showAfter={item.show_after}
                showUntil={item.show_until}
                hideStatusText
              />
            )
          },
          {
            "key": "Case Id",
            "title": <><span className="adaptive-table-header">Case </span>ID</>,
            "sort_column": "documents.id",
            "calculated_value": (item) => <p className="edit-documents-access-table-centered-paragraph">{item.document_id}</p>,
            "center_column_header": true
          },
          {
            "key": "Case Title",
            "title": <><span className="adaptive-table-header">Case </span>Title</>,
            "column_width": "extra-large",
            "sort_column": "documents.title",
            "calculated_value": (item) => <a href={"/document_set_document_relations/" + item.id}>{item.document.title}</a>,
            "center_column_header": true
          },
          ...scheduleItemInfos.map((itemInfo) => {
            return {
              "title": itemInfo.shortTitle || itemInfo.title,
              "calculated_value": (item) => {
                const itemSchedule = item.drawer_schedules[itemInfo.key] || {};
                return this.itemVisibilityFor(itemSchedule.hide_until).status;
              },
              "calculated_class_name": (item) => {
                const itemSchedule = item.drawer_schedules[itemInfo.key] || {};
                return this.itemVisibilityFor(itemSchedule.hide_until).className;
              },
              "center_column_header": true
            };
          })
        ]}
      />
      <GenMore
        {...this.props.documentSetDocumentRelations}
        shouldInfiniteScroll
      />
    </div>;
  }
}

export default connectToList(DocumentSetEditDocumentsAccessTable, "documentSetDocumentRelations");
