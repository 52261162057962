import * as R from 'ramda'

// InlineJSON
// {
//   text: String,
//   styles: [Style],
// }

// Style
// {
//   type: String,
//   range: Range,
//   args: {}
// }

import {
  adjustRangeValuesAtMostToCuttoff
} from 'src/range'

export function replaceText(inlineJSON, replaceRange, newText) {
  return adjustStyleRangesWithFunction({
    ...inlineJSON,
    text: inlineJSON.text.slice(0, replaceRange.start) + newText + inlineJSON.text.slice(replaceRange.end),
  }, range => adjustRangeValuesAtMostToCuttoff(range, newText.length - (replaceRange.end - replaceRange.start), replaceRange.start))
}

export function addStyle(inlineJSON, type, range, args={}) {
  return ({
    ...inlineJSON,
    styles: combineStyleRanges(inlineJSON.styles.concat({
      type,
      range,
      args
    }))
  })
}

export function removeStyle(inlineJSON, type, range) {
  let groupedStyles = R.groupBy(style => style.type, inlineJSON.styles)

  let groupedStyle = groupedStyles[type]

  groupedStyles[type] = R.flatten(groupedStyle.map(style => {
    if (range.start <= style.range.start) {
      if (range.end >= style.range.end) {
        return []
      } else if (range.end > style.range.start) {
        return {
          ...style,
          range: {
            start: range.end,
            end: style.range.end
          }
        }
      } else {
        return style
      }
    } else if (range.start < style.range.end) {
      if (range.end >= style.range.end) {
        return {
          ...style,
          range: {
            start: style.range.start,
            end: range.start
          }
        }
      } else {
        return [{
          ...style,
          range: {
            start: style.range.start,
            end: range.start
          }
        },{
          ...style,
          range: {
            start: range.end,
            end: style.range.end
          }
        }]
      }
    } else {
      return style
    }
  }))

  return ({
    ...inlineJSON,
    styles: R.flatten(R.values(groupedStyles))
  })
}

export function setStyleArg(inlineJSON, type, index, argKey, argValue) {
  let groupedStyles = R.groupBy(style => style.type, inlineJSON.styles)

  let groupedStyle = groupedStyles[type]

  groupedStyles[type] = groupedStyle.map(style => {
    if (style.range.start <= index && style.range.end >= index) {
      return {
        ...style,
        args: {
          ...style.args,
          [argKey]: argValue
        }
      }
    } else {
      return style
    }
  })

  return ({
    ...inlineJSON,
    styles: R.flatten(R.values(groupedStyles))
  })
}

function adjustStyleRangesWithFunction(inlineJSON, func) {
  return R.over(R.lensProp('styles'), R.map(R.over(R.lensProp('range'), func)), inlineJSON)
}


export function inlineJSONBeforeIndex(inlineJSON, index) {
  return {
    text: inlineJSON.text.slice(0,index),
    styles: inlineJSON.styles.filter(style => style.range.start < index).map(style => {return {...style, range: {...style.range, end: Math.min(style.range.end, index)}}})
  }
}

export function inlineJSONAfterIndex(inlineJSON, index) {
  return {
    text: inlineJSON.text.slice(index),
    styles: inlineJSON.styles.filter(style => style.range.end > index).map(style => {return {...style, range: {...style.range, start: Math.max(style.range.start, index)}}})
  }
}

function combineStyleRanges(styles) {
  let arrayOfGroupedStyles = R.values(R.groupBy(style => style.type, styles))
  let arrayOfGroupedStylesCombined = arrayOfGroupedStyles.map(groupedStyle => {
    let sortedByStart = R.sortBy(style => style.range.start, groupedStyle)

    return sortedByStart.reduce((acc, style) => {
      if (acc.length === 0) {
        return [style]
      } else {
        let lastStyle = R.last(acc)
        if (lastStyle.range.end < style.range.start) {
          return acc.concat([style])
        } else {
          return R.init(acc).concat([{
            ...lastStyle,
            range: {
              start: lastStyle.range.start,
              end: R.max(lastStyle.range.end, style.range.end)
            }
          }])
        }
      }
    }, [])
  })

  return R.flatten(arrayOfGroupedStylesCombined)
}

export function splitInlineJSONAtIndex(inlineJSON, index) {
  const [text1, text2] = R.splitAt(index, inlineJSON.text)
  const [styles1, styles2] = splitStylesAtIndex(inlineJSON.styles, index)

  return [
    {
      ...inlineJSON,
      text1: text1,
      styles: styles1
    },
    {
      ...inlineJSON,
      text2: text2,
      styles: styles2
    }
  ]
}

export function splitStylesAtIndex(styles, index) {
  const unadjustedStyles1 = styles.filter(style => style.range.start < index)
  const unadjustedStyles2 = styles.filter(style => style.range.end > index)

  const styles1 = unadjustedStyles1.map(style => {
    return {
      ...style,
      range: {
        start: style.start,
        end: R.min(style.end, index)
      }
    }
  })

  const styles2 = unadjustedStyles2.map(style => {
    return {
      ...style,
      range: {
        start: style.start - index,
        end: style.end - index
      }
    }
  })

  return [styles1, styles2]
}
