import React from 'react'

import ReactS3Uploader from 'react-s3-uploader'

import classNames from 'classnames'

import axios from 'axios'

export default class MediaAssetNewWebkit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  onUploadProgress(progressEvent) {
    this.setState({progressPercent: progressEvent})
  }

  onUploadError(error) {
    console.log("ERROR:", error)
    alert("Error Uploading")
    // DISPLAY THERE WAS AN ERROR UPLOADING
  }

  onUploadFinish(response) {
    console.log("FINISH:", response)

    let fileUrl = new URL(response.signedUrl)
    let originalPath = fileUrl.pathname

    console.log("File Path:", originalPath)

    const csrfToken = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

    axios.post(`/documents/${this.props.document_id}/media_assets`, {
      media_asset: {
        original_upload_path: originalPath,
        media_type: 'video'
      }
    })
    .then((response) => {
      Turbolinks.visit(`/documents/${this.props.document_id}/hipaa_releases/new`)
    })
    .catch((error) => {
      console.log(error)
      alert("Error Uploading:")
    })
  }

  render() {
    let hasProgress = typeof(this.state.progressPercent) === 'number'

    return <div>
      <label className={classNames('button', 'bg-sky-600 hover:bg-sky-500', {disabled: hasProgress})} style={{width: '100%'}}>
        {hasProgress ? 'Uploading Video' : 'Capture Video'}
        <ReactS3Uploader
          signingUrl="/media_assets/signed_url"
          signingUrlMethod="GET"
          accept="video/mp4,video/*"
          s3path="/uploads/"
          onProgress={this.onUploadProgress.bind(this)}
          onError={this.onUploadError.bind(this)}
          onFinish={this.onUploadFinish.bind(this)}
          id="video_upload_button"
          style={{display: 'none'}}
          uploadRequestHeaders={{}}
        />
      </label>
      {hasProgress && <div className="progress progress-striped active">
        <div className="bar" style={{width: `${this.state.progressPercent * 0.95}%`}}></div>
      </div>}
    </div>
  }
}
