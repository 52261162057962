import React from 'react';

import GenButton from 'Gen/Button'
import GenCycleButton from 'Gen/CycleButton'
import GenToggleBar from 'Gen/ToggleBar'
import GenToggleButton from 'Gen/ToggleButton'

//<%= react_component('Test/ComponentPreview') %>

export default class TestComponentPreview extends React.Component {
  state = {
    cycleButtonValue : "one",
    toggleButtonValue : false,
    toggleBarValue : "one"
  };

  onButtonClick = () => {
    alert("Clicked");
  };

  onCycleButtonClick = (newValue) => {
    this.setState({cycleButtonValue: newValue});
  };

  onToggleButtonClick = (newValue) => {
    this.setState({toggleButtonValue: newValue});
  };

  onToggleBarClick = (newValue) => {
    this.setState({toggleBarValue: newValue});
  };

  render() {
    return(
      <div>
        <div>
          <h1>GenButton</h1>
          <GenButton
            name="Button"
            highlighted={false}
            disabled={false}
            onClick={this.onButtonClick} />
          <GenButton
            name="Highlighted Button"
            highlighted={true}
            disabled={false}
            onClick={this.onButtonClick} />
          <GenButton
            name="Disabled Button"
            highlighted={false}
            disabled={true}
            onClick={this.onButtonClick} />
          <GenButton
            name="Disabled Highlighted Button"
            highlighted={true}
            disabled={true}
            onClick={this.onButtonClick} />
        </div>

        <div>
          <h1>GenCycleButton</h1>
          <GenCycleButton
            states={[
              { "button" : {
                  "name" : "One",
                  "highlighted" : false,
                  "disabled" : false
                },
                "value" : "one",
                "nextValue" : "two"
              },
              { "button" : {
                  "name" : "Two",
                  "highlighted" : true,
                  "disabled" : false
                },
                "value" : "two",
                "nextValue" : "three"
              },
              { "button" : {
                  "name" : "Three",
                  "highlighted" : true,
                  "disabled" : false
                },
                "value" : "three",
                "nextValue" : "two"
              }
            ]}
            disabled={false}
            currentValue={this.state.cycleButtonValue}
            onClick={this.onCycleButtonClick} />
        </div>

        <div>
          <h1>GenToggleButton</h1>
          <GenToggleButton
            name={"Test"}
            value={this.state.toggleButtonValue}
            onClick={this.onToggleButtonClick}/>
        </div>

        <div>
          <h1>GenToggleBar</h1>
          <GenToggleBar
            buttons={[
              { name : "One",
                value: "one",
                disabled: false},
              { name : "Two",
                value: "two",
                disabled: false},
              { name : "Three",
                value: "three",
                disabled: true},
            ]}
            value={this.state.toggleBarValue}
            onClick={this.onToggleBarClick}/>
        </div>
      </div>
    );
  }
}
