import React from 'react';

export const groupListDefaults = {
  itemsURL:"/groups",
  search:true,
  sortOptions:[
    {
      "title": "Name",
      "value": "groups.name"
    },
    {
      "title": "ID",
      "value": "groups.id"
    }
  ]
};
