import { applyMiddleware, createStore } from 'redux'

import reducer from 'reducers/index'

import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
// import { createLogger } from 'redux-logger'

const middleware = applyMiddleware(
  thunk,
  promise,
  // createLogger({})
)

export default function store() {return createStore(reducer, middleware)}
