import React from "react";

import GenButton from "./Button";
import GenLoadingSpinner from "./LoadingSpinner";

export default function GenMore({
  isSyncing,
  itemsPerPage,
  lastPageLength,
  readListPage,
  shouldInfiniteScroll,
}) {
  // TODO: When at react 18 change React.useRef to React.userId
  const infiniteScrollRandomKey = React.useRef(Math.floor(Math.random() * 1000000));

  const checkVisibilityForInfiniteScroll = React.useCallback(() => {
    const element = document.getElementById(infiniteScrollRandomKey);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      const isVisible = !(elementRect.bottom < 0 || elementRect.top - viewHeight >= 0);

      if (isVisible) {
        readListPage();
      }
    }
  }, []);

  React.useEffect(() => {
    let checkVisibilityTimer;
    if (shouldInfiniteScroll) {
      window.addEventListener("scroll", checkVisibilityForInfiniteScroll);
      checkVisibilityTimer = setInterval(checkVisibilityForInfiniteScroll, 500);
    }

    return () => {
      if (shouldInfiniteScroll) {
        window.removeEventListener("scroll", checkVisibilityForInfiniteScroll);
        clearTimeout(checkVisibilityTimer);
      }
    };
  }, [checkVisibilityForInfiniteScroll, shouldInfiniteScroll]);

  if (isSyncing) {
    return <GenLoadingSpinner />;
  } else if (lastPageLength < itemsPerPage) {
    return (
      <div className="gen-list-pagination flex justify-center">
        <GenButton name="End" highlighted disabled />
      </div>
    );
  } else if (shouldInfiniteScroll) {
    return (
      <div className="gen-list-pagination">
        <div id={infiniteScrollRandomKey} className="infinite-scroll-area" />
      </div>
    );
  } else {
    return (
      <div className="gen-list-pagination flex justify-center">
        <GenButton name="More" onClick={() => readListPage()} highlighted />
      </div>
    );
  }
}
