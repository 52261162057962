import React from 'react';

import GenButton from 'Gen/Button';

export default function GenSelectBar(
  {
    selected,
    setListSelected,
    listData,
    itemType,
    selectionDescription,
    selectExtraButtons,
  },
) {
  const handleSelectAll = (e) => {
    e.preventDefault();

    selected.length > 0
      ? setListSelected([])
      : setListSelected((listData || []));
  }

  const selectText = `Select ${listData ? listData.length + " " : ""} Shown`

  return (
    <div className="gen-select-list-buttons">
      <div className="gen-select-list-buttons-left flex gap-2 items-baseline">
        <GenButton name={selected.length > 0 ? "Deselect All" : selectText} onClick={handleSelectAll} highlighted small/>
        <span>{selected.length} {itemType} selected</span>
        <span>{selectionDescription && selectionDescription(selected)}</span>
      </div>
      <div className="gen-select-list-buttons-right">
        {selectExtraButtons}
      </div>
    </div>
  );
}
