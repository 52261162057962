import React from 'react';

import GenModal from 'Gen/Modal'
import GenButton from 'Gen/Button'

import AddToList from 'DocumentSetDocumentRelation/AddTo/List'

import { documentDisplayTitle } from 'src/document_display_title'

const ALLOWED_ROLE_NAMES = ["Faculty", "Manager", "Partner", "ReelDx_Faculty"]
const ADMIN_NAMES = ["Manager"]

export default class AddToButton extends React.Component {
  state = {
    modalOpen: false,
  }

  handleToggleModalOpen = (e) =>  {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  render() {
    const {
      document,
      rootDocumentSetId,
      currentUser,
      currentRoleDefinition,
      categoryId
    } = this.props

    const isUserAllowed = currentUser?.is_admin || ALLOWED_ROLE_NAMES.includes(currentRoleDefinition?.name)
    if (!isUserAllowed) { return null }

    const canAddToUnownedDocumentSets = currentUser?.is_admin || ADMIN_NAMES.includes(currentRoleDefinition?.name)

    return(<>
      <button title="Add to course" className="bg-sky-600 hover:bg-sky-500 p-2 button add-to-button" onClick={this.handleToggleModalOpen}>
        <i className="fa fa-plus"></i>
      </button>

      <GenModal open={this.state.modalOpen} closeModelFunction={this.handleToggleModalOpen}>
        <div className="gen-modal-panel add-to-modal">
          <div className="add-to-button-modal-header">
            <h1>Adding Case to Course</h1>

            <div className="gen-button-right-group">
              <GenButton
                name="Close"
                highlighted
                small
                onClick={this.handleToggleModalOpen}
              />
            </div>
          </div>

          <div className='case-info'>
            <img
              src={document.cover_image}
              alt={`Case ${document.id} Cover Image`}
            />

            <h5>{documentDisplayTitle(document)}</h5>
          </div>

          <AddToList
            rootDocumentSetId={rootDocumentSetId}
            documentId={document.id}
            canAddToUnownedDocumentSets={canAddToUnownedDocumentSets}
            userId={currentUser?.id}
            showsOwnershipCheckbox
            categoryId={categoryId}
          />
        </div>
      </GenModal>
    </>);
  }
}
