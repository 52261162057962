import React from 'react';

import DocBookmark from 'Doc/Bookmark'
import DocImage from 'Doc/Image'

export default function DocImageGallery(props) {
  let images = props.images.map((image) => {
    const passToEveryNode = {
      currentDocument: props.currentDocument,
      currentVersion: props.currentVersion,
      currentUser: props.currentUser,
      displayState: props.displayState,
      editState: props.editState,

      currentDocumentSetDocumentRelation: props.currentDocumentSetDocumentRelation,
      currentRoleDefinition: props.currentRoleDefinition,
    }

    return (
      <div className="doc-image-gallery-slide" key={image.uid}>
        <DocImage
          {...image}
          {...passToEveryNode}
          inGallery
        />
      </div>
    )
  });

  return (
    <div className="doc-image-gallery">
      <div className="doc-image-gallery-scroller">
        {images}
      </div>
      <DocBookmark {...props} />
    </div>
  );
}
