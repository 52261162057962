import React from 'react';

import {capitalize} from 'lodash';
import pluralize from 'pluralize';

import GenSidebarList from 'Gen/SidebarList'

import CatalogSummary from 'Catalog/Summary'

export default function CatalogList(
  {
    document_set_id,
  },
) {
  return (
    <GenSidebarList
      title={capitalize(pluralize(i18n.t("catalog")))}
      search
      searchPlaceHolder={"Search..."}
      itemsURL={"/document_sets/" + document_set_id + "/document_sets"}
      itemView={documentSet => <CatalogSummary documentSet={documentSet} key={documentSet.id} />}
      historyPersistance
      gridView
      filters={[
        {
          key: "categories",
          title: "Tags",
          displayLikeFilterGroup: true,
          type: "checkboxes",
          dataType: "MDATA",
          dataCount: "MDATA_MANY",
          options: [
            "Cardiovascular",
            "Dermatology",
            "EENT",
            "Emergency Medicine",
            "Endocrinology",
            "Family Medicine",
            "Gastroenterology",
            "Geriatrics",
            "Interdisciplinary",
            "Musculoskeletal",
            "Nephrology",
            "Neurology",
            "Nursing",
            "OB/GYN",
            "Pediatrics",
            "Physician Assistant",
            "Psychology",
            "Pulmonology",
          ],
        },
        {
          key: "difficulty",
          title: "Difficulty",
          displayLikeFilterGroup: true,
          type: "checkboxes",
          dataType: "REGULAR",
          options: [
            {
              displayName: "Easy",
              value: 1
            },
            {
              displayName: "Intermediate",
              value: 2
            },
            {
              displayName: "Hard",
              value: 3
            },
          ]
        },
      ]}
    />
  );
}
