import React from "react";

import * as R from "ramda";

import DocSection from "Doc/Section";
import GenButton from "Gen/Button";

import DocQuestionReportButton from "Doc/QuestionReportButton";

import {
  deepFind,
  deepFindByUID,
  generateNode,
  newSetNodeKVPairAction,
} from "src/doc_helpers";

export default function DocActivity(props) {
  const {
    documentSetDocumentActivity,
    activityUserState,

    onComplete,
    onCompleteTitle,

    inactive,
    inactiveBoolean,

    currentDocument,
    currentDocumentSetDocumentRelation,
    currentRoleDefinition,
    currentVersion,
    currentUser,
    displayState,
    editState,
  } = props;

  const {activity} = documentSetDocumentActivity;
  const activityUserStateLongId = currentDocumentSetDocumentRelation ? `${activity.id}/${currentDocumentSetDocumentRelation.document_set_id}/${currentDocument.id}` : `${activity.id}/${currentDocument.id}`;

  const showAfter = typeof documentSetDocumentActivity.show_after === "string" ? new Date(documentSetDocumentActivity.show_after) : null;
  const dueAt = typeof documentSetDocumentActivity.due_at === "string" ? new Date(documentSetDocumentActivity.due_at) : null;

  const currentUserForActivity = {
    ...currentUser,
    addState: (state) => currentUser.addActivityUserState(activity.id, state),
    addStateTargetType: "activity",
    addStateTargetId: activity.id,
    logEvent: () => {},
  };

  const hasStarted = !showAfter || showAfter.getTime() < new Date().getTime();

  const hasBeenSubmitted = activityUserState.state?.submit_button?.inactive?.value === 2;
  const submittedAt = hasBeenSubmitted ? activityUserState.state?.submit_button?.inactive?.updatedAt : undefined;

  let badgeText = "";
  if (currentRoleDefinition.isLearner) {
    if (!hasStarted) {
      return null;
    } if (hasBeenSubmitted) {
      const formattedSubmittedAt = (new Date(submittedAt)).toLocaleDateString("en-US");

      badgeText = `Submitted: ${formattedSubmittedAt}`;

      if (activityUserState.reviewed_at) {
        badgeText += " (Reviewed)";
      }
    } else {
      badgeText = dueAt ? `Due: ${dueAt.toLocaleDateString("en-US")}` : "Due";
    }
  } else if (!hasStarted) {
    badgeText = `Activates: ${showAfter.toLocaleDateString("en-US")}`;
  } else {
    badgeText = dueAt ? `Due: ${dueAt.toLocaleDateString("en-US")}` : "Active";
  }

  const activityContent = activity.content;
  const hasTextInputs = deepFind(activityContent, (node) => node.type === "textInput");
  const hasOpenAiChats = deepFind(activityContent, (node) => node.type === "openAiChat");

  let activitySections;
  if (Array.isArray(activityContent[0])) {
    activitySections = [{
      type: "section",
      uid: "root",
      title: activity.name,
      content: activityContent,
      level: activity.placement_inside ? 2 : 1,
    }];
  } else {
    activitySections = activityContent;
  }

  activitySections = activitySections.map((activitySection, activitySectionIndex) => {
    const isFirstSection = activitySectionIndex === 0;
    const isLastSection = activitySectionIndex === activitySections.length - 1;

    let activityBlocks = Array.isArray(activitySection.content[0]) ? activitySection.content : [activitySection.content];
    activityBlocks = activityBlocks.map((activityBlock, activityBlockIndex) => {
      const isFirstBlock = activityBlockIndex === 0;
      const isLastBlock = activityBlockIndex === activityBlocks.length - 1;

      const activityBlockInputNodes = activityBlock.filter((node) => ["textInput", "multipleChoice", "openAiChat"].includes(node.type));

      const buttonUid = isLastBlock && isLastSection ? "submit_button" : (isFirstSection ? `submit_button_${activityBlockIndex}` : `submit_button_${activitySectionIndex}_${activityBlockIndex}`);
      let buttonActions = activityBlockInputNodes.map((inputNode) => newSetNodeKVPairAction(inputNode.uid, "inactive", 2));

      if (activity.is_graded) {
        buttonActions = buttonActions.concat(activityBlockInputNodes.map((inputNode) => newSetNodeKVPairAction(inputNode.uid, "graded", true)));
      }

      if (isLastBlock) {
        if (activity.is_graded) {
          const scoreableNodes = activityBlocks.flatMap((ab) => ab.filter((node) => ["multipleChoice"].includes(node.type)));
          const nodeScores = scoreableNodes.map((scorableNode) => {
            const isCorrect = scorableNode.content.every((answerOption) => !!answerOption.answer === !!activityUserState.state?.[answerOption.uid]?.value?.value);

            return isCorrect ? 1.0 : 0.0
          })

          if (nodeScores.length > 0) {
            const grade_percentage = R.sum(nodeScores) / nodeScores.length;
            buttonActions = buttonActions.concat(newSetNodeKVPairAction("root", "grade_percentage", grade_percentage));
          }
        }

        const otherActivityBlocks = R.init(activityBlocks);
        const otherActivityNodes = otherActivityBlocks.flat().filter((node) => node.type !== "button");
        const showOtherActivityNodesActions = otherActivityNodes.map((node) => newSetNodeKVPairAction(node.uid, "invisible", false));

        buttonActions = buttonActions.concat(
          newSetNodeKVPairAction(buttonUid, "inactive", 2),
          ...showOtherActivityNodesActions,
        );

        if (isLastSection) {
          if (onComplete) {
            buttonActions = buttonActions.concat(onComplete)
          }
        } else {
          const nextActivitySection = activitySections[activitySectionIndex + 1];
          const showNextSection = [
            newSetNodeKVPairAction(nextActivitySection.uid, "invisible", false),
            newSetNodeKVPairAction(nextActivitySection.uid, "collapsed", false),
          ];

          buttonActions = buttonActions.concat(showNextSection)
        }
      } else {
        const hideCurrentNodes = activityBlock.map((node) => newSetNodeKVPairAction(node.uid, "invisible", true));

        const nextActivityBlock = activityBlocks[activityBlockIndex + 1];
        const showNextNodes = nextActivityBlock.map((node) => newSetNodeKVPairAction(node.uid, "invisible", false));

        const isNextActivityBlockLast = activityBlockIndex + 1 === activityBlocks.length - 1;
        const nextNextActivityButtonUid = isNextActivityBlockLast && isLastSection ? "submit_button" : (isFirstSection ? `submit_button_${activityBlockIndex + 1}` : `submit_button_${activitySectionIndex}_${activityBlockIndex + 1}`);

        buttonActions = buttonActions.concat(
          ...hideCurrentNodes,
          newSetNodeKVPairAction(buttonUid, "invisible", true),
          ...showNextNodes,
          newSetNodeKVPairAction(nextNextActivityButtonUid, "invisible", false),
        );
      }

      const submitButtonTitle = activitySection.submitButtonTitle;

      activityBlock = activityBlock.concat([
        generateNode("button", {
          uid: buttonUid,
          title: isLastBlock && (submitButtonTitle || onCompleteTitle) ? (submitButtonTitle || onCompleteTitle) : "Submit",
          invisible: !isFirstBlock,
          onClick: buttonActions,
        })
      ]);


      return activityBlock;
    });

    return {
      ...activitySection,
      content: activityBlocks.flat(),
      nested: true,
      collapsible: !activity.placement_inside,
      collapsed: !activity.placement_inside,
      inactive: inactive,
      inactiveBoolean: inactiveBoolean,
      invisible: !isFirstSection,
    }
  })

  const state = activityUserState.state || {};
  const strippedState = R.map(R.map((valueData) => valueData.value), state);

  R.toPairs(strippedState).forEach(([uid, kvs]) => {
    const node = deepFindByUID(activitySections, uid);
    if (node) {
      R.toPairs(kvs).forEach(([key, value]) => {
        node[key] = value;
      });
    }
  });

  if (hasBeenSubmitted) {
    activitySections.forEach((activitySection) => {
      activitySection.content
        .filter((node) => node.type === "textInput")
        .forEach((node) => {
          node.inactive = 2;
        });
    });
  }

  let activityReview;
  if (activityUserState.reviewed_at) {
    activityReview = (
      <div className="activity-review">
        <h4>Review:</h4>
        <div className="activity-review-inside">
          <p>{activityUserState.review_comment}</p>
        </div>
      </div>
    );
  }

  let questionReportButton;
  if (!hasTextInputs) {
    questionReportButton = <DocQuestionReportButton/>
  }

  let exportPdfButton;
  if (hasTextInputs || hasOpenAiChats) {
    exportPdfButton = (
      <GenButton
        name="Export PDF"
        href={`/activity_user_states/${activityUserStateLongId}.pdf`}
        target="_blank"
        highlighted
      />
    )
  }

  let editActivityButton;
  if (currentUser.is_admin || currentRoleDefinition?.name === "ReelDx_Faculty") {
    editActivityButton = (
      <GenButton
        name="Edit Activity"
        href={`/activities/${activity.id}/edit`}
        target="_blank"
        highlighted
      />
    )
  }

  return (
    activitySections.filter((section) => !section.invisible).map((activitySection, activitySectionIndex) => (
      <DocSection
        key={activitySection.uid}
        {...activitySection}
        headerColor="#F1F3F3"
        titleBadge={<div className="skill-badge bg-sky-600">Activity</div>}
        rightContent={(
          <>
            {editActivityButton}
            {exportPdfButton}
            <div className="doc-due-date-badge due-at">{badgeText}</div>
          </>
        )}
        aboveContent={activitySectionIndex === 0 && activityReview}
        currentDocument={currentDocument}
        currentVersion={currentVersion}
        editState={editState}
        currentUser={currentUserForActivity}
        currentRoleDefinition={currentRoleDefinition}
        currentDocumentSetDocumentRelation={currentDocumentSetDocumentRelation}
        currentActivityUserState={activityUserState}
        scheduableSectionUIDs={[]}
        reportButton={questionReportButton}
        displayState={displayState}
      />
    ))
  );
}
