import React from 'react';

import DocBookmark from 'Doc/Bookmark'
import DocExtra from 'Doc/Extra'

import DocInlineText from 'Doc/InlineText'

export default function DocAudio(props) {
  const audioURL = () => {
    return props.currentVersion.aws_folder_url +
    "/original/audios/" +
    props.uri;
  };

  let title = <figcaption>
    <DocInlineText content={props.title} uid={props.uid} contentKey="title" editState={props.editState}/>
  </figcaption>;

  switch (props.source) {
    case "local":
      return (
        <div className="doc-audio doc-audio-local">
          <audio preload="none" controls>
            <source src={audioURL} />
          </audio>
          {title}
          <DocBookmark {...props} />
        </div>
      );
    case "soundcloud":
      return (
        <div className="doc-audio doc-audio-soundcloud">
          <iframe width="100%" height="450" scrolling="no" frameBorder="no" src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + props.uri + "&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"}></iframe>
          {title}
          <DocBookmark {...props} />
        </div>
      );
    default:
      return (
        <DocExtra
          subtype="error"
          content={"Unknown audio source type: " + props.source}
        />
      );
  }
}
