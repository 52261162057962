import React from 'react'

export default function DocumentUserSummary(
  {
    documentUser,
  },
) {
  return (
    <div className="panel">
      <h1>{documentUser.user.first_name} {documentUser.user.last_name}</h1>
      <p>{documentUser.user.email}</p>
    </div>
  );
}
