import * as R from 'ramda'

// HTML_STRING :: STRING
//
// INLINE_JSON :: {
//   text: STRING,
//   styles: [INLINE_STYLE_RANGE]
// }
//
// INLINE_JSON_WITH_STARTS :: {
//   text: STRING,
//   styles: [STYLE_RANGE],
//   styleStarts: [STYLE_START]
// }
//
// STYLE_RANGE :: {
//   {
//     type: STYLE_TYPES,
//     range: RANGE,
//     args : {}
//   }
// }
//
// STYLE_START :: {
//   {
//     type: STYLE_TYPES,
//     start: INTEGER,
//     args : {}
//   }
// }
//
// STYLE_TYPES :: 'b' | 'i' | 'u' | 's' | 'sub' | 'sup' | 'a'

const inlineJSONToHTMLStyleNames = {
  'bold': 'b',
  'italic': 'i',
  'underlined': 'u',
  'strikethrough': 's',
  'subscript': 'sub',
  'superscript': 'sup',
  'link': 'a'
}

// TODO OTHER STYLES HERE
const tagTanslation = {
  'strong': 'b',
  'em': 'i'
}

const inlineHTMLToJSONStyleNames = R.invertObj(inlineJSONToHTMLStyleNames)

// TODO COMBINE SIMILAR STYLES <b>this </b><b>that</b>
export function inlineHTMLToJSON(inlineHTML, prefixInlineJSON={text: "", styles: [], styleStarts: {}}, offset=0) {
  let {
    text,
    styles,
    styleStarts
  } = prefixInlineJSON

  let styleRegEx = /\<(\/)?([^<]+)>/g
  let match = styleRegEx.exec(inlineHTML)

  if (match) {
    let beforeTagText = unescapeHtml(inlineHTML.slice(0, match.index))
    text = text + beforeTagText

    let tagContents = R.trim(match[2])

    let tagName = tagContents.split(/\s/)[0]

    tagName = tagTanslation[tagName] || tagName
    let isEndTag = !!match[1]

    if (isEndTag) {
      // TODO HANDLE IF NO styleStart
      let styleStart = styleStarts[tagName]
      if (styleStart) {
        styles = styles.concat([R.dissoc('start', {
          ...styleStart,
          range: {
            start: styleStart.start,
            end: beforeTagText.length + offset
          }
        })])

        styleStarts = R.omit([tagName], styleStarts)
      }
    } else {
      let args = R.fromPairs([...tagContents.matchAll(/(\w+)="([^"]*)"/g)].map(m => m.slice(1,3)))

      if (!styleStarts[tagName]) {
        styleStarts = {
          ...styleStarts,
          [tagName]: {
            type: tagName,
            args,
            start: beforeTagText.length + offset
          }
        }
      }
    }

    let afterTagText = inlineHTML.slice(styleRegEx.lastIndex)

    return inlineHTMLToJSON(afterTagText, {
      text,
      styles,
      styleStarts
    }, beforeTagText.length + offset)
  } else {
    return({
      text: text + unescapeHtml(inlineHTML),
      styles
    })
  }
}

export function inlineJSONToHTML(inlineJSON, extraEndTags=[]) {
  return internalInlineJSONToHTML(inlineJSON.text, R.sortBy(s => s.range.start, inlineJSON.styles), R.sortBy(s => s.range.end, inlineJSON.styles))
}

function internalInlineJSONToHTML(text, sortedStarts, sortedEnds, offset=0, startedStack=[]) {
  let nextStartStyle = sortedStarts[0]
  let nextEndStyle = sortedEnds[0]

  if (nextEndStyle) {
    let offestTagSpot, tags
    if (nextStartStyle && nextStartStyle.range.start < nextEndStyle.range.end) {
      tags = openHTMLTag(nextStartStyle)

      offestTagSpot = nextStartStyle.range.start - offset

      sortedStarts = sortedStarts.slice(1)
      offset = nextStartStyle.range.start
      startedStack = startedStack.concat([nextStartStyle])
    } else {
      let [startedStackBase, startedStackTop] = R.splitWhen(s => s.type === nextEndStyle.type, startedStack)
      let closeTags = R.reverse(startedStackTop).map(style => closeHTMLTag(style)).join('')
      let openTags = R.tail(startedStackTop).map(style => openHTMLTag(style)).join('')
      tags = closeTags + openTags

      offestTagSpot = nextEndStyle.range.end - offset

      sortedEnds = sortedEnds.slice(1)
      offset = nextEndStyle.range.end
      startedStack = R.concat(startedStackBase, R.tail(startedStackTop))
    }

    let beforeTagText = escapeHtml(text.slice(0, offestTagSpot))
    let afterTagText = text.slice(offestTagSpot)

    return `${beforeTagText}${tags}` + internalInlineJSONToHTML(afterTagText, sortedStarts, sortedEnds, offset, startedStack)
  } else {
    return escapeHtml(text)
  }
}

function openHTMLTag(style) {
  let attributes = R.toPairs(style.args || []).map(([argName, argValue]) => `${argName}="${argValue}"`).join(' ')

  return `<${style.type} ${attributes}>`
}

function closeHTMLTag(style) {
  return `</${style.type}>`
}

function escapeHtml(text) {
  return text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/\s\s/g, " &nbsp;")
    .replace(/\s$/g, "&nbsp;")
    .replace(/^\s/g, "&nbsp;")
}

function unescapeHtml(html) {
  return html
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, "\"")
    .replace(/&#039;/g, "'")
    .replace(/&nbsp;/g, " ")
}
