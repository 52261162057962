import * as R from 'ramda'

import {
  deepFindPathByUID,
} from 'src/doc_helpers'

export function addActivitiesToDocument(json, currentDocumentSetDocumentActivities, activityUserStatesByActivityId) {
  const groupedDocumentSetDocumentActivities = R.groupBy((documentSetDocumentActivity) => {
    const activity = documentSetDocumentActivity.activity
    return [
      activity.placement_uid,
      activity.placement_before,
      activity.placement_inside,
    ]
  }, currentDocumentSetDocumentActivities);

  R.values(groupedDocumentSetDocumentActivities).map((groupOfDocumentSetDocumentActivities) => {
    const sortedGroupOfDocumentSetDocumentActivities = R.sortBy((dsda) => dsda.activity.order, groupOfDocumentSetDocumentActivities);
    const activitiesToInsert = sortedGroupOfDocumentSetDocumentActivities.map((dsda) => (
      {
        type: "activity",
        uid: dsda.id,
        documentSetDocumentActivity: dsda,
        activityUserState: activityUserStatesByActivityId[dsda.activity.id],
      }
    ))

    const firstActivity = groupOfDocumentSetDocumentActivities[0].activity;
    const placementUid = firstActivity.placement_uid;
    const placementBefore = firstActivity.placement_before;
    const placementInside = firstActivity.placement_inside;

    let activitiesPath
    if (placementUid === "video") {
      const firstLevelContent = json.content;
      const videoIndex = R.findIndex((node) => "video" === node.type, firstLevelContent);

      const mainVideoPath = ["content", videoIndex];
      activitiesPath = placementBefore ? mainVideoPath : R.adjust(-1, R.add(2), mainVideoPath); // ADJUST TO BE BUTTON
    } else if(!placementUid) {
      // FIND LAST SECTION
      const firstLevelContent = json.content;
      const lastSectionIndex = R.findLastIndex(n => ["section", "activity"].includes(n.type), firstLevelContent);
      activitiesPath = ["content", lastSectionIndex + 1];
    } else {
      const placementNodePath = deepFindPathByUID(json, placementUid);

      activitiesPath = placementNodePath;
      if (placementInside) {
        activitiesPath = [...activitiesPath, "content"];

        if (placementBefore) {
          activitiesPath = [...activitiesPath, 0];
        } else {
          const lengthOfContent = R.path(activitiesPath, json).length;
          activitiesPath = [...activitiesPath, lengthOfContent];
        }
      } else {
        activitiesPath = placementBefore ? placementNodePath : R.adjust(-1, R.inc, placementNodePath);
      }
    }

    json = R.over(R.lensPath(R.init(activitiesPath)), R.insertAll(R.last(activitiesPath), activitiesToInsert), json);
  })

  return json;
}
