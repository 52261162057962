export function stringToDate(stringToConvert) {
  return (typeof stringToConvert === "string") ? new Date(stringToConvert) : stringToConvert;
}

export function now() {
  return new Date();
}

export function yesterday() {
  return now().setDate(now().getDate() - 1);
}

export function atEarliestNow(date) {
  return date < now() ? now() : date;
}

export function translateToEffectiveDateReverse(date) {
  date = stringToDate(date);

  if (date instanceof Date) {
    return date < now() ? new Date(0) : date;
  } else {
    return !!date;
  }
}

export function translateToEffectiveDate(date) {
  date = stringToDate(date);

  if (date instanceof Date) {
    return date < now() ? false : date;
  } else {
    return !!date;
  }
}

export function areEqualEffectiveDates(dateA, dateB) {
  return dateA === dateB || (dateA instanceof Date && dateB instanceof Date && dateA.getTime() === dateB.getTime());
}

const TRUNCATE_COUNT = 8;
export function selectionDescription(selected) {
  if (selected.length === 0) {
    return "";
  } else {
    const selectedIDs = selected.map((item) => item.id);
    if (selected.length > (TRUNCATE_COUNT + 1)) {
      return ` (${selectedIDs.slice(0, TRUNCATE_COUNT).join(", ")}, and ${selected.length - TRUNCATE_COUNT} more)`;
    } else {
      return ` (${selectedIDs.join(", ")})`;
    }
  }
}
