import React from "react";

import connectToList from "../../store/list";

import GenMore from "../Gen/More";
import GenControlsBar from "../Gen/ControlsBar";
import GenSearchBox from "../Gen/SearchBox";
import GenSortButtons from "../Gen/SortButtons";

const ENDPOINT = "contributors";

function ContributorList(
  {
    contributors,
  },
) {
  React.useEffect(() => {
    contributors.mountList(ENDPOINT);
  }, []);

  const items = (contributors.listData || []).map((item) => (
    <div className="panel" key={item.id}>
      <div className="gen-panel-header">
        <div className="gen-panel-header-title">
          <h1>
            <a href={`/contributors/${item.id}`}>{item.name}{item.designation && `, ${item.designation}`}</a>
          </h1>
        </div>

        <div className="gen-panel-header-right-content">
          <div className="list-stat-panels">
            <div className="list-stat-panel">
              <h1>{item.cached_assignment_count.toLocaleString()}</h1>
              <h5>Assignments</h5>
            </div>
            <div className="list-stat-panel">
              <h1>{item.cached_view_count.toLocaleString()}</h1>
              <h5>Views</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <GenControlsBar>
        <GenSearchBox
          {...contributors}
        />
        <GenSortButtons
          {...contributors}
          sortOptions={[
            {
              title: "Name",
              value: "contributors.name",
            },
            {
              title: "Assignments",
              value: "contributors.cached_assignment_count",
            },
            {
              title: "Views",
              value: "contributors.cached_view_count",
            },
          ]}
        />
      </GenControlsBar>
      {items}
      <GenMore
        {...contributors}
        shouldInfiniteScroll
      />
    </div>
  );
}

export default connectToList(ContributorList, "contributors");
