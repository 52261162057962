import React from "react";

import connectToList from "../../store/list";

import GenMore from "../Gen/More";
import GenControlsBar from "../Gen/ControlsBar";
import GenSearchBox from "../Gen/SearchBox";
import GenSortButtons from "../Gen/SortButtons";

function InstitutionContributorList(
  {
    contributor_id,
    institution_contributors,
    institution_id,
  },
) {
  React.useEffect(() => {
    const endpoint = institution_id ? `institutions/${institution_id}/institution_contributors` : `contributors/${contributor_id}/institution_contributors`;

    institution_contributors.mountList(endpoint);
  }, []);

  const items = (institution_contributors.listData || []).map((institution_contributors) => {
    if (institution_id) {
      const {contributor} = institution_contributors;
      return (
        <div className="panel" key={institution_contributors.id}>
          <div className="gen-panel-header">
            <div className="gen-panel-header-title">
              <h1>
                <a href={`/contributors/${contributor.id}`}>{contributor.name}{contributor.designation && `, ${contributor.designation}`}</a>
              </h1>
            </div>

            <div className="gen-panel-header-right-content">
              <div className="list-stat-panels">
                <div className="list-stat-panel">
                  <h1>{contributor.cached_assignment_count.toLocaleString()}</h1>
                  <h5>Assignments</h5>
                </div>
                <div className="list-stat-panel">
                  <h1>{contributor.cached_view_count.toLocaleString()}</h1>
                  <h5>Views</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="panel" key={institution_contributors.id}>
          <h1>
            <a href={`/institutions/${institution_contributors.institution.id}`}>{institution_contributors.institution.name}</a>
          </h1>
        </div>
      );
    }
  });

  let extraSortOptions = [];
  if (institution_id) {
    extraSortOptions = [
      {
        title: "Assignments",
        value: "contributors.cached_assignment_count",
      },
      {
        title: "Views",
        value: "contributors.cached_view_count",
      },
    ];
  }

  return (
    <div>
      <GenControlsBar>
        <GenSearchBox
          {...institution_contributors}
        />
        <GenSortButtons
          {...institution_contributors}
          sortOptions={[
            {
              title: "Name",
              value: institution_id ? "contributors.name" : "institutions.name",
            },
            ...extraSortOptions,
          ]}
        />
      </GenControlsBar>
      {items}
      <GenMore
        {...institution_contributors}
        shouldInfiniteScroll
      />
    </div>
  );
}

export default connectToList(InstitutionContributorList, "institution_contributors");
