export const DISPLAY_MODES = {
  EDIT: 'DISPLAY_MODE/EDIT',
  EDIT_FULL: 'DISPLAY_MODE/EDIT_FULL',
  FACULTY: 'DISPLAY_MODE/FACULTY',
  STUDENT: 'DISPLAY_MODE/STUDENT',
}

export function displayTraitsBasedOnMode(displayMode) {
  let isUnlocked, isForcedUncollapsed, isEditDisplayMode
  switch (displayMode) {
    case DISPLAY_MODES.EDIT:
      return {
        isUnlocked: true,
        isForcedUncollapsed: false,
        isEditDisplayMode: true,
      }
    case DISPLAY_MODES.EDIT_FULL:
      return {
        isUnlocked: true,
        isForcedUncollapsed: true,
        isEditDisplayMode: true,
      }
    case DISPLAY_MODES.FACULTY:
      return {
        isUnlocked: true,
        isForcedUncollapsed: false,
        isEditDisplayMode: false,
      }
    case DISPLAY_MODES.STUDENT:
    default:
      return {
        isUnlocked: false,
        isForcedUncollapsed: false,
        isEditDisplayMode: false,
      }
  }
}

export function displayModeDisplayName(displayMode) {
  return {
    [DISPLAY_MODES.EDIT]: "Edit",
    [DISPLAY_MODES.EDIT_FULL]: "Edit (Full)",
    [DISPLAY_MODES.STUDENT]: "Student Preview",
    [DISPLAY_MODES.FACULTY]: "Faculty Preview",
  }[displayMode]
}
