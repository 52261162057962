import { combineReducers } from 'redux'

import { activityUserStatesReducer as activityUserStates } from 'reducers/activity_user_state'
import { activitiesReducer as activities } from 'reducers/activity'
import { documentSetDocumentStatesReducer as documentSetDocumentStates } from 'reducers/document_set_document_state'
import { documentUserStatesReducer as documentUserStates } from 'reducers/document_user_state'
import { documentsReducer as documents } from 'reducers/document'
import { listsReducer as lists } from 'reducers/list'
import { versionsReducer as versions } from 'reducers/version'

export default combineReducers({
  activityUserStates,
  activities,
  documentSetDocumentStates,
  documentUserStates,
  documents,
  lists,
  versions,
})
