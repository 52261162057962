import React from 'react';

import AddToButton from 'DocumentSetDocumentRelation/AddTo/Button'

import { documentDisplayTitle } from 'src/document_display_title'

export default function DocumentSummary(
  {
    document,
    rootDocumentSetId,
    currentUser,
    currentRoleDefinition,
    categoryId,
  },
) {
  let mediaIcons = []

  if(document.description.indexOf('extra_video') !== -1) {
    mediaIcons.push(<i key="extra_video" className="fa fa-video-camera" style={{'paddingRight': '5px'}}></i>)
  }

  if(document.description.indexOf('extra_image') !== -1) {
    mediaIcons.push(<i key="extra_image" className="fa fa-image" style={{'paddingRight': '5px'}}></i>)
  }

  if(document.description.indexOf('extra_audio') !== -1) {
    mediaIcons.push(<i key="extra_audio" className="fa fa-volume-up" style={{'paddingRight': '5px'}}></i>)
  }

  let canAddButton = rootDocumentSetId &&
    (currentUser?.is_admin ||
      ["Faculty", "Manager", "ReelDx_Faculty"].includes(currentRoleDefinition?.name))

  let addToButton = canAddButton && <AddToButton
    rootDocumentSetId={rootDocumentSetId}
    document={document}
    currentUser={currentUser}
    currentRoleDefinition={currentRoleDefinition}
    categoryId={categoryId}
  />

  return (
    <div className="cover-wrapper">
      {addToButton}

      <a className="cover summary" href={"/documents/" + document.id}>
        <img src={document.cover_image} alt={`Case ${document.id} Cover Image`}  width="2092" height="1170"/>
        <h4 className="name">{documentDisplayTitle(document)}</h4>
        {mediaIcons.length > 0 && <h4>{mediaIcons}</h4>}
      </a>
    </div>
  );
}
