import * as R from "ramda"

import {
  generateItemsReducer,
  generateActions,
  itemFromStore,
  itemEditDataFromStore,
  itemLocalDataFromStore,
} from 'reducers/item'

const itemType = "documentSetDocumentState"
const endpoint = "document_set_document_states/"

export const documentSetDocumentStatesReducer = generateItemsReducer(itemType)

export const {
  seedDocumentSetDocumentState,
  createDocumentSetDocumentState,
  readDocumentSetDocumentState,
  updateDocumentSetDocumentState,
  destroyDocumentSetDocumentState,
  setEditFieldsDocumentSetDocumentState,
  resetEditFieldsDocumentSetDocumentState,
  setLocalFieldsDocumentSetDocumentState,
  resetLocalFieldsDocumentSetDocumentState,
} = generateActions(itemType, endpoint)

export const documentSetDocumentStateFromStore = (store, id) => itemFromStore(store, itemType, id, documentSetDocumentStateMergeFunction)
export const documentSetDocumentStateEditDataFromStore = (store, id) => itemEditDataFromStore(store, itemType, id)
export const documentSetDocumentStateLocalDataFromStore = (store, id) => itemLocalDataFromStore(store, itemType, id)


export function addDocumentSetDocumentState(id, state) {
  return (dispatch, getState) => {
    const newState = R.map(R.map(value => { return {value: value, updatedAt: Date.now()} }), state)

    let documentSetDocumentState = documentSetDocumentStateFromStore(id, getState())
    let editState = documentSetDocumentState?.editData?.state || {}

    dispatch(setEditFieldsDocumentSetDocumentState(id, {state: R.mergeDeepRight(editState, newState)}))
    dispatch(updateDocumentSetDocumentState(id))
  }
}

export function resetDocumentSetDocumentState(id) {
  return (dispatch, getState) => {
    dispatch(setEditFieldsDocumentSetDocumentState(id, {startRetry: true}))
    dispatch(updateDocumentSetDocumentState(id))
  }
}

function documentSetDocumentStateMergeFunction(rawItem) {
  return R.mergeWithKey(
    (key, oldV, newV) => key === "state" ? R.mergeDeepRight(oldV, newV) : newV,
    rawItem.savedData || {},
    rawItem.editData || {}
  )
}
