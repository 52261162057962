import React from 'react';

export default function GenPanelHeader(
  {
    children,
    rightContent,
  },
) {
  return (
    <div className="gen-panel-header">
      <div className="gen-panel-header-title">
        <h1>{children}</h1>
      </div>
      <div className="gen-panel-header-right-content">
        {rightContent}
      </div>
    </div>
  );
}
