import React from 'react';

import classNames from 'classnames'

import DocBookmark from 'Doc/Bookmark'

export default class DocTextInput extends React.Component {
  state = {
    value: this.props.value,
    timer: null
  };

  componentWillUnmount() {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
      this.updateUSCWithTimer();
    }
  }

  handleChange(e) {
    this.setState({value: e.target.value});

    if (this.state.timer) { clearTimeout(this.state.timer); }
    this.setState({timer: setTimeout(this.updateUSCWithTimer.bind(this), 2000)});
  };

  updateUSC() {
    this.props.currentUser.addState({[this.props.uid]: {"value": this.state.value}});
  };

  updateUSCWithTimer() {
    this.updateUSC();
    this.setState({value: null, timer: null});
  };

  render() {
    let feedback;
    if (this.props.graded && this.props.feedback) {
      feedback = (
        <div className="border-solid border-2 border-black p-3">
          <h4>Sample Response:</h4>
          {this.props.feedback}
        </div>
      )
    }

    return (
      <div className={classNames("doc-text-input", {"inactive": this.props.inactiveBoolean})}>
        <textarea
          cols="40"
          rows={this.props.boxLines}
          maxLength={this.props.characterLimit}
          disabled={this.props.inactiveBoolean}
          value={this.state.value || this.props.value}
          onChange={this.handleChange.bind(this)}
        />
        <i>Instructions: Your answer is saved automatically. Once you submit, you will not be able to edit the text.</i>
        <DocBookmark {...this.props} />
        {feedback}
      </div>
    )
  }
}
