import React from "react";

import * as R from "ramda";

import connectToList from "store/list";

import { batch } from "react-redux";

import {
  stringToDate,
  areEqualEffectiveDates,
  now,
  yesterday,
  selectionDescription,
} from "src/effective_dates"

import GenButton from "Gen/Button";
import GenModal from "Gen/Modal";
import GenMore from "Gen/More";
import DocumentSetDocumentRelationScheduleStatus from "DocumentSetDocumentRelation/ScheduleStatus";
import GenControlsBar from "Gen/ControlsBar";
import GenSearchBox from "Gen/SearchBox";
import GenSelectBar from "Gen/SelectBar";
import GenSwitch, {switchModes} from "Gen/Switch";
import GenTableDisplay from "Gen/TableDisplay";

import DateTimePicker from "react-widgets/lib/DateTimePicker";

import "react-widgets/dist/css/react-widgets.css";

const ITEMS_PER_PAGE = 1000; // TODO: Look into why Gen/More doesn't work in Chrome
const TRUNCATE_COUNT = 8;


class DocumentSetEditDocumentsActivitiesTable extends React.Component {
  state = {
    // Updating DocumentSetDocumentRelation Records
    // dsdrDocumentSchedule: {},
    dsdrItemSchedules: {},

    customSkill: { title: '', prompt: '' },

    // Controls
    setVisibilityProcessing: false,
    setDeleteProcessing: false,
    setVisibilityModalOpen: false,
    customSkillModalVisible: false,
  };

  componentDidMount() {
    batch(() => {
      const customSkill = this.props.activities.find(activity => activity.document_set_id != null)
      if (customSkill) {
        const title = customSkill.name
        const content = customSkill.content[0].find(item => item.uid == 'Custom_Header').content
        const prompt = />(.*)</s.exec(content)[1]
        this.setState({customSkill: { title: title, prompt: prompt }})
      }
      this.props.documentSetDocumentRelations.setListItemsPerPage(ITEMS_PER_PAGE);
      this.props.documentSetDocumentRelations.mountList(this.endpoint());
    });
  }

  endpoint() {
    return  "/document_sets/" + this.props.document_set_id + "/document_set_document_relations";
  }

  selectedDocumentSetDocumentRelationIds() {
    return this.props.documentSetDocumentRelations.selected.map((item) => item.id);
  }

  handleToggleSetVisibilityModalOpen(e) {
    this.setState({
      setVisibilityModalOpen: !this.state.setVisibilityModalOpen,
      dsdrItemSchedules: {}
    });
  };

  handleSetVisibilitySubmit(e) {
    const data = {
      document_set_document_relation_ids: this.selectedDocumentSetDocumentRelationIds(),
      activity_schedules: this.state.dsdrItemSchedules
    };

    const visibilityUrl = window.location.href.replace("/edit_documents_activities", "/update_documents_activities");

    this.setState({ setVisibilityProcessing: true });

    fetch(visibilityUrl, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

        return response.json();
      })
      .then(this.onSetVisibilitySubmitSuccess.bind(this))
      .catch(() => {
        alert("Error: Could not save Skill assignments. Please retry.");
      });
  };

  onSetVisibilitySubmitSuccess() {
    batch(() => {
      this.props.documentSetDocumentRelations.setListSelected([]);
      this.props.documentSetDocumentRelations.resetListSearch();
      this.props.documentSetDocumentRelations.resetListPages();
      this.props.documentSetDocumentRelations.readListPage();
    });

    this.setState({
      dsdrItemSchedules: {},
      setVisibilityProcessing: false,
      setDeleteProcessing: false,
      setVisibilityModalOpen: false,
      customSkillModalVisible: false,
    });
  }

  itemVisibilityFor(schedule) {
    let status = "";
    let className = "";

    if (schedule) {
      const show_after = stringToDate(schedule.show_after);

      if (show_after && show_after > now()) {
        status = `Activates: ${show_after.getMonth() + 1}/${show_after.getDate()}/${show_after.getFullYear()}`;
        className = "hidden-until-cell";
      } else {
        const due_at = stringToDate(schedule.due_at);

        if (!due_at) {
          status = "Assigned"
        } else {
          status = `Due: ${due_at.getMonth() + 1}/${due_at.getDate()}/${due_at.getFullYear()}`
        }

        className = "visible-cell";
      }
    } else {
      className = "hidden-cell";
      status = "Unassigned";
    }

    return { status: <span className="schedule-status">{status}</span>, className: className };
  }

  defaultSwitchStates() {
    const selectedDSDRs = this.props.documentSetDocumentRelations.selected;

    const itemKeys = R.uniq(selectedDSDRs.flatMap((dsdr) => dsdr.document_set_document_activities.map((dsda) => dsda.activity_id)));
    const itemSwitchModes = selectedDSDRs.reduce((acc, dsdr) => {
      itemKeys.forEach((key) => {
        const schedule = dsdr.document_set_document_activities.find((dsdr) => dsdr.activity_id === key);
        const hasSchedule = !!schedule;

        let {
          show_after,
          due_at
        } = schedule || {};

        show_after = stringToDate(show_after);
        due_at = stringToDate(due_at);

        const switchMode = hasSchedule ? switchModes.ON : switchModes.OFF;

        if (acc.hasOwnProperty(key)) {
          if (
            acc[key].switchMode !== switchMode ||
            !areEqualEffectiveDates(acc[key].show_after, show_after) ||
            !areEqualEffectiveDates(acc[key].due_at, due_at)
          ) {
            acc[key] = {
              switchMode: switchModes.MIXED
            };
          }
        } else {
          acc[key] = {
            switchMode,
            show_after,
            due_at
          };
        }
      });

      return acc;
    }, {});

    return {
      itemSwitchModes
    };
  }

  scheduleCorrection(schedule) {
    const {
      show_after,
      due_at
    } = schedule;

    if (show_after && due_at && due_at < show_after) {
      if (due_at < yesterday()) {
        return R.omit(["due_at"], schedule);
      } else {
        return {...schedule, due_at: show_after};
      }
    }

    return schedule;
  }

  handleCustomSkillClick(e) {
    this.setState({
      customSkillModalVisible: ! this.state.customSkillModalVisible
    });
  }

  handleCustomSkillChange(e) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      customSkill: {
        ...prevState.customSkill,
        [name]: value
      }
    }));
  }

  handleCustomSkillSubmit(e) {
    const data = {
      customSkill: this.state.customSkill
    };

    const url = window.location.href.replace("/edit_documents_activities", "/activity");

    this.setState({ setVisibilityProcessing: true });

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

        return response.json();
      })
      .then((data) => {
        this.updateActivities.bind(this)(data)
        this.onSetVisibilitySubmitSuccess.bind(this)()
      })
      .catch(() => {
        alert("Error: Could not save custom skill. Please retry.");
        this.setState({ setVisibilityProcessing: false })
      });
  };

  updateActivities(data) {
    const i = this.props.activities.findIndex(activity => activity.document_set_id != null)
    if (i >= 0) {
      this.props.activities.splice(i, 1)
    }
    this.props.activities.push(data)
  }

  handleCustomSkillDelete(e) {
    const url = window.location.href.replace("/edit_documents_activities", "/activity");

    this.setState({ setDeleteProcessing: true });

    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        this.deleteActivity.bind(this)()
        this.onSetVisibilitySubmitSuccess.bind(this)()
      })
      .catch(() => {
        alert("Error: Could not delete custom skill. Please retry.");
        this.setState({ setDeleteProcessing: false })
      });
  };

  deleteActivity() {
    const i = this.props.activities.findIndex(activity => activity.document_set_id != null)
    if (i >= 0) {
      this.props.activities.splice(i, 1)
    }
    this.setState({customSkill: { title: '', prompt: '' }})
  }

  render() {
    const {
      dsdrItemSchedules
    } = this.state;

    const selectedCount = this.props.documentSetDocumentRelations.selected.length;

    const switchStateModes = this.defaultSwitchStates();

    const selectExtraButtons = [
      <div key="SetScheduleDiv" style={{display: "flex", "marginBottom": "10px"}}>
        <div style={{flex: 1}}>
          Select one or more cases from the list below and schedule student skills. Set start date and due date in the next step.
        </div>
        <GenButton
          name="Schedule Skills"
          highlighted
          small
          disabled={selectedCount === 0}
          onClick={this.handleToggleSetVisibilityModalOpen.bind(this)}
        />
      </div>,
      <div key="CustomSkillDiv" style={{display: "flex"}}>
        <div style={{flex: 1}}>
          Add a custom student skill if none of the available skills fulfill your specific requirements.
        </div>
        <GenButton
          name="Custom Skill"
          highlighted
          small
          onClick={this.handleCustomSkillClick.bind(this)}
        />
      </div>
    ];

    const scheduleItemInfos = R.sortBy((activity) => activity.name, this.props.activities).map((activity) => ({key: activity.id, title: activity.name}));

    const scheduleItems = scheduleItemInfos.map((itemInfo) => {
      const itemDefaults = switchStateModes.itemSwitchModes[itemInfo.key];

      const hasNewValues = !R.isNil(dsdrItemSchedules[itemInfo.key]);
      const newValues = dsdrItemSchedules[itemInfo.key];

      const defaultSwitchMode = itemDefaults?.switchMode || switchModes.OFF;
      const switchMode = hasNewValues ? (newValues ? switchModes.ON : switchModes.OFF) : defaultSwitchMode;

      const currentValues = hasNewValues ? newValues : itemDefaults;
      const showAfter = currentValues?.show_after;
      const dueAt = currentValues?.due_at;

      let stateText;
      switch (switchMode) {
        case switchModes.ON:
          stateText = "Activates";
          break;
        case switchModes.OFF:
          stateText = "Unassigned";
          break;
        case switchModes.MIXED:
          stateText = "Selected cases will retain current settings";
          break;
      }

      return <div key={itemInfo.key} className="drawer">
        <strong className="drawer-strong"> {itemInfo.title} </strong>
        <div>
          <div className="set-visibility-row">
            <GenSwitch
              value={switchMode}
              includeMixed={defaultSwitchMode === switchModes.MIXED}
              onClick={(newSwitchMode) => {
                if (newSwitchMode === switchModes.MIXED) {
                  this.setState({
                    dsdrItemSchedules: {...R.omit([itemInfo.key], dsdrItemSchedules)}
                  });
                } else {
                  this.setState({
                    dsdrItemSchedules: {...dsdrItemSchedules, [itemInfo.key]: (newSwitchMode === switchModes.ON) ? {} : false}
                  });
                }
              }}
            />
            <p className="visibility-control-detail-paragraph"> {stateText} </p>
            {switchMode === switchModes.ON && <>
              <DateTimePicker
                min={now()}
                placeholder={"Now"}
                value={showAfter instanceof Date ? showAfter : null}
                onChange={(date) => {
                  const dateToUse = date < now() ? now() : date;
                  this.setState({dsdrItemSchedules: {...dsdrItemSchedules, [itemInfo.key]: this.scheduleCorrection({...(currentValues || {}), show_after: dateToUse})}});
                }}
              />
              <GenButton
                name="X"
                highlighted
                disabled={!(showAfter instanceof Date)}
                small
                className="clear-button"
                onClick={() => this.setState({ dsdrItemSchedules: {...dsdrItemSchedules, [itemInfo.key]: R.omit(["show_after"], (currentValues || {}))}})}
              />
            </>}
          </div>
          {switchMode === switchModes.ON && <div className="set-visibility-row">
            <p className="visibility-control-detail-paragraph" style={{marginLeft: "68px"}}> {"Due date"} </p>
            {switchMode === switchModes.ON && <>
              <DateTimePicker
                min={showAfter || now()}
                placeholder={"Never"}
                value={dueAt instanceof Date ? dueAt : null}
                onChange={(date) => {
                  const dateToUse = date < now() ? now() : date;
                  this.setState({dsdrItemSchedules: {...dsdrItemSchedules, [itemInfo.key]: this.scheduleCorrection({...(currentValues || {}), due_at: dateToUse})}});
                }}
              />
              <GenButton
                name="X"
                highlighted
                disabled={!(dueAt instanceof Date)}
                small
                className="clear-button"
                onClick={() => this.setState({ dsdrItemSchedules: {...dsdrItemSchedules, [itemInfo.key]: R.omit(["due_at"], (currentValues || {}))}})}
              />
            </>}
          </div>}
        </div>
      </div>;
    });

    let caseIDs = this.props.documentSetDocumentRelations.selected.map((item) => item.document_id).sort((a, b) => a-b);
    let showMore;
    if (selectedCount > TRUNCATE_COUNT + 1) {
      if (!this.state.showAllCaseIDSummary) {
        caseIDs = caseIDs.slice(0, TRUNCATE_COUNT);
        showMore = <>and {selectedCount - TRUNCATE_COUNT} more. <a style={{"text-decoration": "underline"}}>Show All</a></>;
      } else {
        showMore = <> <a style={{"text-decoration": "underline"}}>Hide All</a></>;
      }
    }

    const modalCaseIDSummary = <p className='case-ids'>Changes made will affect case(s) with id(s)... {caseIDs.join(", ")} {showMore}</p>;
    return <div>
      <GenModal open={this.state.setVisibilityModalOpen} closeModelFunction={this.handleToggleSetVisibilityModalOpen.bind(this)}>
        <div className="gen-modal-panel">
          <div className="edit-documents-access-modal-header">
            <h1>Schedule Skill</h1>
            <div className="gen-button-right-group">
              <GenButton
                name="Cancel"
                highlighted
                small
                onClick={this.handleToggleSetVisibilityModalOpen.bind(this)}
              />
              <GenButton
                name="Save"
                highlighted
                small
                loading={this.state.setVisibilityProcessing}
                onClick={this.handleSetVisibilitySubmit.bind(this)}
              />
            </div>
          </div>

          <div className="case-control-header">
            <h3 className="visibility-h3"> Case{(selectedCount > 1) ? "s" : ""} {`(${selectedCount} Selected)`}</h3>
          </div>

          <div onClick={() => this.setState({showAllCaseIDSummary: !this.state.showAllCaseIDSummary})}>
            {modalCaseIDSummary}
          </div>

          <h5 className="drawer-control-header">Skills</h5>
          <div className="edit-documents-access-modal-bordered-section">
            {scheduleItems}
          </div>
        </div>
      </GenModal>
      <GenModal open={this.state.customSkillModalVisible} closeModelFunction={this.handleCustomSkillClick.bind(this)}>
        <div className="gen-modal-panel">
          <div className="edit-documents-access-modal-header">
            <h1>Custom Skill</h1>
            <div className="gen-button-right-group">
              <GenButton
                name="Cancel"
                highlighted
                small
                onClick={this.handleCustomSkillClick.bind(this)}
              />
              <GenButton
                name="Delete"
                highlighted
                small
                loading={this.state.setDeleteProcessing}
                onClick={this.handleCustomSkillDelete.bind(this)}
              />
              <GenButton
                name="Save"
                highlighted
                small
                loading={this.state.setVisibilityProcessing}
                onClick={this.handleCustomSkillSubmit.bind(this)}
              />
            </div>
          </div>
          <h5 className="drawer-control-header">Title</h5>
          <input type="text"
                 name="title"
                 value={this.state.customSkill.title}
                 onChange={this.handleCustomSkillChange.bind(this)}
          />
          <h5 className="drawer-control-header">Prompt</h5>
          <textarea name="prompt"
                    value={this.state.customSkill.prompt}
                    onChange={this.handleCustomSkillChange.bind(this)}
                    rows="3"
                    cols="40" />
        </div>
      </GenModal>
      <GenSelectBar
        {...this.props.documentSetDocumentRelations}
        itemType={"case(s)"}
        selectExtraButtons={selectExtraButtons}
        selectionDescription={selectionDescription}
      />
      <GenControlsBar>
        <GenSearchBox
          {...this.props.documentSetDocumentRelations}
          showSearch
        />
      </GenControlsBar>
      <GenTableDisplay
        {...this.props.documentSetDocumentRelations}
        columns= {[
          {
            "key": "Case Id",
            "title": <><span className="adaptive-table-header">Case </span>ID</>,
            "column_width": "small",
            "sort_column": "documents.id",
            "calculated_value": (item) => <p className="edit-documents-access-table-centered-paragraph">{item.document_id}</p>,
            "center_column_header": true
          },
          {
            "key": "Case Title",
            "title": <><span className="adaptive-table-header">Case </span>Title</>,
            "column_width": "large",
            "sort_column": "documents.title",
            "calculated_value": (item) => <a href={"/document_set_document_relations/" + item.id}>{item.document.title}</a>,
            "center_column_header": true
          },
          ...scheduleItemInfos.map((itemInfo) => {
            return {
              "title": itemInfo.shortTitle || itemInfo.title,
              "column_width": "medium",
              "calculated_value": (item) => {
                const itemSchedule = item.document_set_document_activities.find((activity) => activity.activity_id === itemInfo.key);
                return this.itemVisibilityFor(itemSchedule).status;
              },
              "calculated_class_name": (item) => {
                const itemSchedule = item.document_set_document_activities.find((activity) => activity.activity_id === itemInfo.key);
                return this.itemVisibilityFor(itemSchedule).className;
              },
              "center_column_header": true
            };
          })
        ]}
      />
      <GenMore
        {...this.props.documentSetDocumentRelations}
        shouldInfiniteScroll
      />
    </div>;
  }
}

export default connectToList(DocumentSetEditDocumentsActivitiesTable, "documentSetDocumentRelations");
