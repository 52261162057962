import React from "react";

import * as R from "ramda";

import GenBooleanCircle from "../Gen/BooleanCircle";
import GenButton from "../Gen/Button";
import GenGroupableTable from "../Gen/GroupableTable";

import ActivityReviewModal from "../Activity/ReviewModal";

import {
  integerDurrationToDisplayString,
  stringDateToDisplayDateString,
} from "../../src/time_display";
import { fullURL } from "../../src/url";

const documentSetReportSettings = [
  {
    title: "First Name",
    key: "first_name",
    grouping: "user",
    searchable: true,
    width: 100,
  },
  {
    title: "Last Name",
    key: "last_name",
    grouping: "user",
    searchable: true,
    width: 100,
  },
  {
    title: "Email",
    key: "email",
    grouping: "user",
    searchable: true,
    width: 250,
  },
  {
    title: "Case Id",
    key: "id",
    grouping: "document",
    searchable: true,
    width: 75,
    displayFunction: (value, rowData) => <a href={`/documents/${rowData.document.id}`} target="_blank" rel="noreferrer">{value}</a>,
  },
  {
    title: "Case Name",
    key: "title",
    grouping: "document",
    searchable: true,
    width: 250,
    displayFunction: (value, rowData) => <a href={`/documents/${rowData.document.id}`} target="_blank" rel="noreferrer">{value}</a>,
  },
  {
    title: "Viewed Case",
    key: "case_viewed",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Viewed Video",
    key: "video_viewed",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Viewed Count",
    key: "case_times_opened",
    width: 100,
    align: "center",
  },
  {
    title: "Viewed Video Time",
    key: "seconds_viewed",
    displayFunction: integerDurrationToDisplayString,
    width: 100,
    align: "center",
  },
  {
    title: "Viewed Vitals",
    key: "vitals_viewed",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Viewed DDx",
    key: "differential_viewed",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Viewed Dx",
    key: "diagnosis_viewed",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Viewed Outcomes",
    key: "outcomes_viewed",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Viewed Care",
    key: "care_viewed",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Viewed Keypoints",
    key: "keypoints_viewed",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
];

const documentSetActivityReportSettings = [
  {
    title: "First Name",
    key: "first_name",
    grouping: "user",
    searchable: true,
    width: 100,
  },
  {
    title: "Last Name",
    key: "last_name",
    grouping: "user",
    searchable: true,
    width: 100,
  },
  {
    title: "Email",
    key: "email",
    grouping: "user",
    searchable: true,
    width: 250,
  },
  {
    title: "Case Id",
    key: "id",
    grouping: "document",
    searchable: true,
    width: 75,
    displayFunction: (value, rowData) => <a href={`/documents/${rowData.document.id}`} target="_blank" rel="noreferrer">{value}</a>,
  },
  {
    title: "Case Name",
    key: "title",
    grouping: "document",
    searchable: true,
    width: 250,
    displayFunction: (value, rowData) => <a href={`/documents/${rowData.document.id}`} target="_blank" rel="noreferrer">{value}</a>,
  },
  {
    title: "Submitted",
    key: "submitted",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Time of Submission",
    key: "submitted_at",
    displayFunction: stringDateToDisplayDateString,
    width: 200,
    align: "center",
  },
  {
    title: "Review",
    key: "review_button",
    displayFunction: (value, rowData, setState) => (
      <GenButton
        name="Review"
        highlighted={rowData.submitted}
        disabled={!rowData.submitted}
        onClick={() => { setState({reviewRow: rowData}); }}
      />
    ),
    width: 100,
    align: "center",
  },
  {
    title: "Reviewed",
    key: "review_submitted",
    displayFunction: (value) => <GenBooleanCircle value={value} />,
    width: 100,
    align: "center",
  },
  {
    title: "Time of Review",
    key: "reviewed_at",
    displayFunction: stringDateToDisplayDateString,
    width: 200,
    align: "center",
  },
  {
    title: "Review Text",
    key: "review_comment",
    searchable: true,
    width: 200,
  },
];

function WrappedActivityReviewModal(props) {
  const {
    activity,
    reviewRow,
    closeReviewRow,
    reloadData,
  } = props;

  return (
    <ActivityReviewModal
      activity={activity}
      activityUserState={reviewRow}
      {...reviewRow}
      afterSave={() => {
        closeReviewRow();
        reloadData();
      }}
      afterCancel={() => {
        closeReviewRow();
      }}
    />
  );
}

export default function DocumentSetReport(props) {
  const {
    activities,
    documentSet,
    chooseCaseReport,
    collapsedDocumentIds,
  } = props;

  const reports = [
    {
      name: "Case Usage",
      key: "case_usage",
      dataUrl: fullURL(`/document_sets/${documentSet.id}/usage_report`),
      exportUrl: fullURL(`/document_sets/${documentSet.id}/usage_report.csv`),
      settings: documentSetReportSettings,
      groupings: [
        {
          name: "User",
          key: "user",
        },
        {
          name: "Case",
          key: "document",
        },
      ],
    },
    ...R.sortBy((activity) => activity.name, activities).map((activity) => (
      {
        name: activity.name,
        key: activity.id,
        dataUrl: fullURL(`/document_sets/${documentSet.id}/activities/${activity.id}/report`),
        settings: documentSetActivityReportSettings,
        groupings: [
          {
            name: "User",
            key: "user",
          },
          {
            name: "Case",
            key: "document",
          },
        ],
        reviewModal: WrappedActivityReviewModal,
        extraModalData: {activity},
      }
    )),
  ];

  return (
    <GenGroupableTable
      reports={reports}
      defaultReportKey={chooseCaseReport ? 'case_usage' : null}
      collapsedRowIds={collapsedDocumentIds}
    />
  );
}
