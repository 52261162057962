import {computePosition,
        shift,
        offset,
        autoUpdate
       } from '@floating-ui/dom'

document.addEventListener('turbolinks:load', () => {
  const account_button = document.querySelector('#account-button')
  // Note: if account_button or menu is null, the call to autoUpdate generates an error. So, any page where
  // current_user is not set.
  if (! account_button) { return }

  const menu = document.querySelector('#account-menu')
  const mobile_nav = document.querySelector('#js-navigation-menu')

  async function update() {
    const placement = window.getComputedStyle(account_button).display == 'block' ? 'top-end' : 'bottom-end'
    computePosition(account_button, menu, {
      placement: placement,
      middleware: [offset(6), shift({padding: 20})]
    }).then(({x, y}) => {
      Object.assign(menu.style, {
        left: `${x}px`,
        top: `${y}px`,
      })
    })
  }

  const cleanup = autoUpdate(account_button, menu, update, { elementResize: false })

  function toggleAccountMenu(event) {
    event.preventDefault()

    if (menu.style.display == 'none' || menu.style.display == '') {
      autoUpdate(account_button, menu, update)
      menu.style.display = 'block'
      document.addEventListener('mousedown', e => {
        if ( ! (e.target.closest('#account-menu') || e.target.closest('#account-button'))) {
          menu.style.display = 'none'
          cleanup()
        }
      }, { once: true } )
    } else {
      menu.style.display = 'none'
      cleanup()
    }
  }

  const accountButton = document.getElementById('account-button');
  if (accountButton) {
    accountButton.removeEventListener('click', toggleAccountMenu);
    accountButton.addEventListener('click', toggleAccountMenu);
  }
})
