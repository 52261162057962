import React from "react";

import connectToList from "../../store/list";

import GenControlsBar from "../Gen/ControlsBar";
import GenMore from "../Gen/More";
import GenSearchBox from "../Gen/SearchBox";
import VersionSummary from "./Summary";

function VersionList(
  {
    documentId,
    versions,
  },
) {
  React.useEffect(() => {
    versions.mountList(`/documents/${documentId}/versions`);
  }, []);

  return (
    <div>
      <GenControlsBar>
        <GenSearchBox
          {...versions}
        />
      </GenControlsBar>
      {versions.listData?.map((version) => (
        <VersionSummary
          key={version.id}
          version={version}
        />
      ))}
      <GenMore
        {...versions}
        shouldInfiniteScroll
      />
    </div>
  );
}

export default connectToList(VersionList, ["versions"]);
