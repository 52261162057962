import React from 'react';

import GenButton from 'Gen/Button'
import GenSidebarList from 'Gen/SidebarList'

import {captureListDefaults} from 'Document/CaptureListDefaults'

export default function CaptureList() {
  return(
    <GenSidebarList
      {...captureListDefaults}
      historyPersistance={true}
      beforeListContent={<GenButton key="Create" name="New Capture" highlighted={true} href="/captures/new" bottom_margin={true}/>}
    />
  );
}
