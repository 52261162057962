import React from "react";

import connectToList from "../../store/list";

import GenControlsBar from "../Gen/ControlsBar";
import GenMore from "../Gen/More";
import GenSearchBox from "../Gen/SearchBox";
import GenSortButtons from "../Gen/SortButtons";
import UserSummary from "./Summary";

function UserList(
  {
    users,
  },
) {
  React.useEffect(() => {
    users.mountList("/users");
  }, []);

  return (
    <div>
      <GenControlsBar>
        <GenSearchBox
          {...users}
        />
        <GenSortButtons
          {...users}
          sortOptions={[
            {
              title: "First Name",
              value: "users.first_name",
            },
            {
              title: "Last Name",
              value: "users.last_name",
            },
            {
              title: "Email",
              value: "users.email",
            },
          ]}
        />
      </GenControlsBar>
      {users.listData?.map((user) => (
        <UserSummary
          key={user.id}
          user={user}
        />
      ))}
      <GenMore
        {...users}
        shouldInfiniteScroll
      />
    </div>
  );
}

export default connectToList(UserList, ["users"]);
