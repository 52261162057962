import React from 'react';

export default function GenBreadcrumbs(props) {
  const breadcrumbs = props.breadcrumbs.map((breadcrumb) => {
    return <a key={breadcrumb[1]} onClick={props.callbacks.openSection.bind(null, breadcrumb[1])} dangerouslySetInnerHTML={{__html: breadcrumb[0]}}/>;
  });

  return(
    <div className="gen-breadcrumbs">
      {breadcrumbs}
    </div>
  );
}
