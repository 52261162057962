import React from 'react';

import classNames from 'classnames'

export default function DocBookmark(
  {
    currentUser,
    uid,
    bookmarked,
  },
) {
  // DISABLED BOOKMARKS FOR REELDX
  return(null)

  const onClick = (e) => {
    currentUser.addState({[uid]: {"bookmarked": !bookmarked}});

    e.stopPropagation();
  };

  return (
    <div
      className={classNames("doc-bookmark-button", {"bookmarked": bookmarked})}
      onClick={onClick}
    />
  );
}
