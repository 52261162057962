import React from 'react';

export default function DocumentSetDocumentRelationScheduleStatus(props) {
  const {
    showAfter,
    showUntil,
    mixedOverride,
    hideStatusText,
    hideOverride
  } = props

  let showAfterTime = showAfter && new Date(showAfter)
  let showUntilTime = showUntil && new Date(showUntil)

  let status = ""
  let className = ""

  if (mixedOverride) {
    status = hideStatusText ? "" : "Mixed"
    className = "mixed-label"
  } else if (showAfterTime && showAfterTime > new Date()) {
    status = `${(showAfterTime.getMonth() + 1)}/${showAfterTime.getDate()}/${showAfterTime.getFullYear()}`
    className = "visible-after-label"
  } else if (showUntilTime || hideOverride) {
    if (showUntilTime > new Date()) {
      status = `${(showUntilTime.getMonth() + 1)}/${showUntilTime.getDate()}/${showUntilTime.getFullYear()}`
      className = "visible-until-label"
    } else {
      status = hideStatusText ? "" : "Hidden"
      className = "hidden-label"
    }
  } else {
    status = hideStatusText ? "" : "Visible"
    className = "visible-label"
  }

  return <p className={className}>{status}</p>
}
