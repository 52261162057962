import {
  isCollapsedRange
} from 'src/range'

import {
  addStyle,
  removeStyle
} from 'src/inline_json'

import {
  modifyNodeValueByUIDAndKey
} from 'src/doc_helpers'

import {
  inlineHTMLToJSON,
  inlineJSONToHTML
} from 'src/convert'

export function generateStyleFunction(tagName, editState) {
  let activeStyle = editState.currentlyActiveStyles?.find(style => style.type === tagName)
  let newlyActive = editState.currentlyEditing?.newlyActiveStyles?.find(style => style.type === tagName)
  let newlyInactive = editState.currentlyEditing?.newlyInactiveStyles?.find(style => style.type === tagName)

  return () => {
    let currentlyEditing = editState.currentlyEditing

    if (activeStyle) {
      if (isCollapsedRange(currentlyEditing.range)) {
        if (newlyInactive) {
          editState.setCurrentlyEditing({...currentlyEditing,
            newlyInactiveStyles: (currentlyEditing.newlyInactiveStyles || []).filter(style => style.type !== tagName)
          })
        } else {
          editState.setCurrentlyEditing({...currentlyEditing,
            newlyInactiveStyles: (currentlyEditing.newlyInactiveStyles || []).concat([{type: tagName}])
          })
        }
      } else {
        editState.modifyEditJSON(json => {
          return modifyNodeValueByUIDAndKey(json, currentlyEditing.uid, currentlyEditing.key, inlineHTML => {
            return inlineJSONToHTML(removeStyle(inlineHTMLToJSON(inlineHTML), tagName, currentlyEditing.range))
          })
        })
      }
    } else {
      if (isCollapsedRange(currentlyEditing.range)) {
        if (newlyActive) {
          editState.setCurrentlyEditing({...currentlyEditing,
            newlyActiveStyles: (currentlyEditing.newlyActiveStyles || []).filter(style => style.type !== tagName)
          })
        } else {
          editState.setCurrentlyEditing({...currentlyEditing,
            newlyActiveStyles: (currentlyEditing.newlyActiveStyles || []).concat([{type: tagName}])
          })
        }
      } else {
        editState.modifyEditJSON(json => {
          return modifyNodeValueByUIDAndKey(json, currentlyEditing.uid, currentlyEditing.key, inlineHTML => {
            return inlineJSONToHTML(addStyle(inlineHTMLToJSON(inlineHTML), tagName, currentlyEditing.range))
          })
        })
      }
    }
  }
}
