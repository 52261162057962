import React from 'react';

import classNames from 'classnames'

import DocBookmark from 'Doc/Bookmark'
import DocInlineText from 'Doc/InlineText'

export default class DocMatcher extends React.Component {
  state = {
    activeMatchItemUID: null,
    activeMatchItemLetter: null
  };

  onMatchItemClick = (futureUID, futureLetter, e) => {
    e.stopPropagation();

    if (this.props.inactiveBoolean) {return null;}

    let pastUID = this.state.activeMatchItemUID;
    let pastLetter = this.state.activeMatchItemLetter;

    if (pastUID) {
      if (pastUID === futureUID) {
        this.setState({activeMatchItemUID: null, activeMatchItemLetter: null});
      } else if (pastLetter === futureLetter) {
        this.setState({activeMatchItemUID: futureUID, activeMatchItemLetter: futureLetter});
      } else {
        this.setState({activeMatchItemUID: null, activeMatchItemLetter: null});

        let aUID, bUID;
        if (futureLetter === "a") {
          aUID = futureUID;
          bUID = pastUID;
        } else {
          aUID = pastUID;
          bUID = futureUID;
        }

        let filteredValues = this.props.value.filter(value => value[0] !== aUID && value[1] !== bUID);

        let futureValues = filteredValues.concat([[aUID, bUID]]);

        this.props.currentUser.addState({[this.props.uid]: {"value": futureValues}});
      }
    } else {
      this.setState({activeMatchItemUID: futureUID, activeMatchItemLetter: futureLetter});
    }
  };

  renderMatcherRow(pair, isMatched) {
    let [a, b] = pair;

    let aClasses = classNames("doc-matcher-item doc-matcher-item-a", {'selected': a.uid === this.state.activeMatchItemUID});
    let bClasses = classNames("doc-matcher-item doc-matcher-item-b", {'selected': b.uid === this.state.activeMatchItemUID});

    return (
      <div className={classNames('doc-matcher-row', {'doc-matcher-matched-row': isMatched})} key={a.uid + b.uid}>
        <div className={aClasses} onClick={this.onMatchItemClick.bind(this, a.uid, "a")}>
          <p>
            <DocInlineText content={a.content} uid={a.uid} contentKey="content" editState={this.props.editState}/>
          </p>
        </div>
        <div className={bClasses} onClick={this.onMatchItemClick.bind(this, b.uid, "b")}>
          <p>
            <DocInlineText content={b.content} uid={b.uid} contentKey="content" editState={this.props.editState}/>
          </p>
        </div>
      </div>
    )
  }

  render() {
    let classes = classNames("doc-matcher", {"inactive": this.props.inactiveBoolean})

    let restAs = this.props.contentA;
    let restBs = this.props.contentB;
    let valueIdPairs = this.props.value;

    let matchedPairs = valueIdPairs.map(valueIdPair => {
      let valueA = restAs.find(a => a.uid === valueIdPair[0]);
      let valueB = restBs.find(b => b.uid === valueIdPair[1]);

      if ((valueA !== undefined) && (valueB !== undefined)) {
        restAs = restAs.filter(a => a.uid !== valueIdPair[0]);
        restBs = restBs.filter(b => b.uid !== valueIdPair[1]);
        return([valueA, valueB]);
      } else {
        return(null);
      }
    }).filter(matchPair => matchPair !== null);

    let unmatchedPairs = restAs.map((restA, i) => [restAs[i], restBs[i]]);

    let matchedRows = matchedPairs.map(matchedPair => this.renderMatcherRow(matchedPair, true))
    let unmatchedRows = unmatchedPairs.map(unmatchedPair => this.renderMatcherRow(unmatchedPair, false))

    return (
      <div className={classes}>
        {matchedRows}
        {unmatchedRows}
        <DocBookmark {...this.props} />
      </div>
    )
  }
}
