import React from 'react'

import GenButton from 'Gen/Button'

import classNames from 'classnames'

import axios from 'axios'

const INTIALIZING = 'INTIALIZING'
const NO_CAMERA = 'NO_CAMERA'
const LIVE_CAMERA = 'LIVE_CAMERA'
const RECORDING = 'RECORDING'
const PLAYING = 'PLAYING'

function randomString() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export default class MediaAssetNewMediaRecorder extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      videoRecorderStatus: INTIALIZING
    }
  }

  componentDidMount() {
    this.startStreamByDeviceIds()
  }

  async getDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()

      this.setState({
        audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
        videoInputDevices: devices.filter(device => device.kind === 'videoinput')
      })
    } catch (e) {
      console.error('navigator.getUserMedia error:', e)
      this.setState({videoRecorderStatus: NO_CAMERA})
    }
  }

  async startStreamByDeviceIds(audioDeviceId, videoDeviceId) {
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop()
      })
    }

    const constraints = {
      audio: {deviceId: audioDeviceId ? {exact: audioDeviceId} : undefined},
      video: {deviceId: videoDeviceId ? {exact: videoDeviceId} : undefined},
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints)

      this.getDevices()

      this.handleStream(stream)
    } catch (e) {
      console.error('navigator.getUserMedia error:', e)
      this.setState({videoRecorderStatus: NO_CAMERA})
    }
  }

  handleStream(stream) {
    let allTracks = stream.getTracks()

    let audioTrack = allTracks.find(track => track.kind === "audio")
    let audioDeviceId = audioTrack.getSettings().deviceId

    let videoTrack = allTracks.find(track => track.kind === "video")
    let videoDeviceId = videoTrack.getSettings().deviceId
    let facingMode = videoTrack.getSettings().facingMode

    this.setState({
      selectedAudioInputDeviceId: audioDeviceId,
      selectedVideoInputDeviceId: videoDeviceId,
      videoRecorderStatus: LIVE_CAMERA,
      facingMode: facingMode
    })

    this.stream = stream
    this.video.srcObject = stream
    this.video.autoplay = true
    this.video.muted = true
  }


  ///////////////////////
  // CAMERA SELECTION //
  /////////////////////

  // handleAudioInputDeviceChange(e) {
  //   let selectedAudioInputDeviceId = e.target.value
  //   this.setState({selectedAudioInputDeviceId: selectedAudioInputDeviceId}, () => {
  //     this.startStreamByDeviceIds(this.state.selectedAudioInputDeviceId, this.state.selectedVideoInputDeviceId)
  //   })
  // }
  //
  // handleVideoInputDeviceChange(e) {
  //   let selectedVideoInputDeviceId = e.target.value
  //   this.setState({selectedVideoInputDeviceId: selectedVideoInputDeviceId}, () => {
  //     this.startStreamByDeviceIds(this.state.selectedAudioInputDeviceId, this.state.selectedVideoInputDeviceId)
  //   })
  // }

  toggleCamera() {
    const currentDeviceIndex = this.state.videoInputDevices.findIndex(device => device.deviceId === this.state.selectedVideoInputDeviceId)
    const nextDeviceIndex = (currentDeviceIndex + 1) % this.state.videoInputDevices.length
    const nextDevice = this.state.videoInputDevices[nextDeviceIndex]

    this.setState({selectedVideoInputDeviceId: nextDevice.deviceId}, () => {
      this.startStreamByDeviceIds(this.state.selectedAudioInputDeviceId, this.state.selectedVideoInputDeviceId)
    })
  }

  ////////////////
  // RECORDING //
  //////////////

  startRecording() {
    this.setState({recordedFile: undefined})
    this.recordedBlobs = []

    // let options = {mimeType: 'video/webm;codecs=vp9'}
    let options = {mimeType: 'video/webm'}

    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      console.error(`${options.mimeType} is not Supported`)
      options = {mimeType: 'video/webm;codecs=vp8'}
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.error(`${options.mimeType} is not Supported`)
        options = {mimeType: 'video/webm'}
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.error(`${options.mimeType} is not Supported`)
          options = {mimeType: ''}
        }
      }
    }

    try {
      this.mediaRecorder = new MediaRecorder(this.stream, options)
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e)
      return
    }


    this.mediaRecorder.onstop = this.onRecordingStop.bind(this)
    this.mediaRecorder.ondataavailable = this.handleMediaRecorderData.bind(this)
    this.mediaRecorder.start(1000)

    this.setState({videoRecorderStatus: RECORDING})
  }

  onRecordingStop() {
    const blob = new Blob(this.recordedBlobs, {type: 'video/webm'})
    this.setState({recordedFile: blob})

    this.video.src = null
    this.video.srcObject = null
    this.video.src = window.URL.createObjectURL(blob)
    this.video.autoplay = false
    this.video.muted = false
    this.video.onended = this.stopPlayRecording.bind(this)
  }

  handleMediaRecorderData(event) {
    if (event.data && event.data.size > 0) {
      this.recordedBlobs.push(event.data)
    }
  }

  stopRecording() {
    console.log("Stop Recording")
    this.mediaRecorder.stop()
    this.setState({videoRecorderStatus: LIVE_CAMERA})
  }

  discardRecording() {
    this.setState({recordedFile: undefined})

    this.video.src = null
    this.video.srcObject = null
    this.video.srcObject = this.stream
    this.video.autoplay = true
    this.video.muted = true
    this.video.onended = undefined
  }

  ///////////////
  // PLAYBACK //
  /////////////

  playRecording() {
    this.video.play()
    this.setState({videoRecorderStatus: PLAYING})
  }

  stopPlayRecording() {
    this.video.pause()
    this.video.currentTime = 0
    this.setState({videoRecorderStatus: LIVE_CAMERA})
  }


  ////////////////
  // UPLOADING //
  //////////////
  async upload() {
    console.log(this.state.recordedFile)

    let randomUploadFileName = `${randomString()}.webm`

    let signedUrl
    try {
      signedUrl = await axios({
        method: 'get',
        url: '/media_assets/signed_url',
        params: {
          objectName: randomUploadFileName,
          path: '/uploads/'
        },
        headers: {
          Accept: 'application/json'
        },
        responseType: 'json'
      })
      signedUrl = signedUrl.data.signedUrl
    } catch (e) {
      console.error('ERROR GET SIGNED URL:', e)
    }

    console.log("SIGNED URL:", signedUrl)

    let file = new File([this.state.recordedFile], randomUploadFileName)

    let uploadResponse
    try {
      uploadResponse = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: this.onUploadProgress.bind(this)
      })
    } catch (e) {
      console.error('ERROR UPLOAD:', e)
    }

    console.log("UPLOAD RESPONSE:", uploadResponse)

    this.onUploadFinish(signedUrl)
  }

  onUploadProgress(progressEvent) {
    this.setState({progressPercent: progressEvent.loaded/progressEvent.total * 100})
  }

  onUploadError(error) {
    console.log("ERROR:", error)
    alert("Error Uploading:")
    // DISPLAY THERE WAS AN ERROR UPLOADING
  }

  onUploadFinish(fileURL) {
    console.log("FINISH:", fileURL)

    let fileUrl = new URL(fileURL)
    let originalPath = fileUrl.pathname

    console.log("Response:", fileURL)
    console.log("File Path:", originalPath)

    const csrfToken = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

    axios.post(`/documents/${this.props.document_id}/media_assets`, {
      media_asset: {
        original_upload_path: originalPath,
        media_type: 'video'
      }
    })
    .then((response) => {
      Turbolinks.visit(`/documents/${this.props.document_id}/hipaa_releases/new`)
    })
    .catch((error) => {
      console.log(error)
      alert("Error Uploading:")
    })
  }

  render() {
    let hasProgress = typeof(this.state.progressPercent) === 'number'

    let controls = null
    switch (this.state.videoRecorderStatus) {
      case INTIALIZING:
        controls = <>
          <h5>Connecting to camera...</h5>
        </>

        break
      case LIVE_CAMERA:
      case RECORDING:
      case PLAYING:
        if (hasProgress) {
          controls = <div className="progress progress-striped active">
            <div className="bar" style={{width: `${this.state.progressPercent * 0.95}%`}}></div>
          </div>
        } else if (this.state.recordedFile) {
          const isPlaying = this.state.videoRecorderStatus === PLAYING

          controls = <>
            <GenButton
              name={<span><i className="fa fa-trash fa-2x"></i><br/>Discard</span>}
              highlighted
              disabled={isPlaying}
              onClick={this.discardRecording.bind(this)}
            />
            <GenButton
              name={<span><i className={`fa ${isPlaying ? 'fa-stop-circle' : 'fa-play-circle'} fa-4x`}></i><br/>{isPlaying ? 'Stop' : 'Play'}</span>}
              highlighted
              onClick={isPlaying ? this.stopPlayRecording.bind(this) : this.playRecording.bind(this)}
            />
            <GenButton
              name={<span><i className="fa fa-upload fa-2x"></i><br/>Upload</span>}
              highlighted
              disabled={isPlaying}
              onClick={this.upload.bind(this)}
            />
          </>
        } else {
          // let audioInputSelectList
          // if (this.state.audioInputDevices) {
          //   audioInputSelectList = <div>
          //     <select value={this.state.selectedAudioInputDeviceId} onChange={this.handleAudioInputDeviceChange.bind(this)}>
          //       {this.state.audioInputDevices.map(audioInputDevice => <option key={audioInputDevice.deviceId} value={audioInputDevice.deviceId}>
          //         {audioInputDevice.label}
          //       </option>)}
          //     </select>
          //   </div>
          // }
          //
          // let videoInputSelectList
          // if (this.state.videoInputDevices) {
          //   videoInputSelectList = <div>
          //     <select value={this.state.selectedVideoInputDeviceId} onChange={this.handleVideoInputDeviceChange.bind(this)} disabled={this.state.videoRecorderStatus === RECORDING}>
          //       {this.state.videoInputDevices.map(videoInputDevice => <option key={videoInputDevice.deviceId} value={videoInputDevice.deviceId}>
          //         {videoInputDevice.label}
          //       </option>)}
          //     </select>
          //   </div>
          // }

          const isRecording = this.state.videoRecorderStatus === RECORDING
          const shouldShowCameraToggle = this.state.videoInputDevices // && this.state.videoInputDevices.length > 1

          controls = <>
            <div className='gen-empty-flex-space'></div>
            <GenButton
              name={<span><i className={`fa ${isRecording ? 'fa-stop-circle' : 'fa-circle'} fa-4x`}></i><br/>{isRecording ? 'Stop' : 'Record'}</span>}
              highlighted
              onClick={isRecording ? this.stopRecording.bind(this) : this.startRecording.bind(this)}
            />
            {shouldShowCameraToggle ? <GenButton
              name={<span><i className="fa fa-camera fa-2x"></i><br/>Switch</span>}
              highlighted
              disabled={isRecording}
              onClick={this.toggleCamera.bind(this)}
            /> : <div className='gen-empty-flex-space'></div>}
          </>
        }

        break
      case NO_CAMERA:
        controls = <>
          <div className="error">
            <p>Error Accessing Camera</p>
          </div>
        </>

        break
      default:
        controls = <>
          <div className="error">
            <p>Error</p>
          </div>
        </>
    }

    return <div className='media-asset-new-media-recorder'>
      <video
        className={classNames({mirror: !this.state.facingMode || this.state.facingMode === "user"})}
        ref={video => {this.video = video}}
      />
      <div className='controls-box'>
        <div className='controls-inner-box'>
          <div className='controls-title'>
            <h6>Patient Capture -- {this.props.document_id} | {this.props.document_title}</h6>
          </div>
          <div className='controls'>
            {controls}
          </div>
        </div>
      </div>
    </div>
  }
}
