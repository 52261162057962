import React from "react";

import connectToList from "../../store/list";

import GenControlsBar from "../Gen/ControlsBar";
import GenMore from "../Gen/More";
import GenSearchBox from "../Gen/SearchBox";
import GenSortButtons from "../Gen/SortButtons";
import GroupSummary from "./Summary";

function GroupList(
  {
    isAdmin,
    groupsPath,
    groups,
  },
) {
  React.useEffect(() => {
    groups.mountList(groupsPath || "/groups");
  }, []);

  return (
    <div>
      <GenControlsBar>
        <GenSearchBox
          {...groups}
        />
        <GenSortButtons
          {...groups}
          sortOptions={[
            {
              title: "Name",
              value: "groups.name",
            },
          ]}
        />
      </GenControlsBar>
      {groups.listData?.map((group) => (
        <GroupSummary
          key={group.id}
          group={group}
          shouldShowExpiration={isAdmin}
        />
      ))}
      <GenMore
        {...groups}
        shouldInfiniteScroll
      />
    </div>
  );
}

export default connectToList(GroupList, ["groups"]);
