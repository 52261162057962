import React from 'react';

import GenButton from 'Gen/Button'
import GenSidebarList from 'Gen/SidebarList'

import GenPanel from 'Gen/Panel'
import GenPanelHeader from 'Gen/PanelHeader'
import GenPanelSlice from 'Gen/PanelSlice'

import DocumentSummary from 'Document/Summary'

import {documentListDefaults} from 'Document/ListDefaults'

import {canPossiblyAccess} from 'src/role_definition_helpers'

export default function DocumentList(
  {
    isWithGroupHeader,
    current_user,
    group,
    current_role_definition,
    document_mdata_definition,
    hideSidebar,
    canCreateCategory,
  },
) {
  let groupHeader = null
  if (isWithGroupHeader) {
    let rightContent = null
    if (current_user?.is_admin) {
      rightContent = [
          <GenButton key="Edit" name="Edit Group" highlighted={true} href="/edit"/>,
          <GenButton key="Duplicate" name="Duplicate" highlighted={true} href="/duplicate"/>,
          <GenButton key="Members" name="Members" highlighted={true} href="/group_role_definition_users"/>
      ]
    }

    groupHeader = <GenPanel>
      <GenPanelHeader rightContent={rightContent}>
        {group.name}
      </GenPanelHeader>
      <GenPanelSlice>
        {group.description}
      </GenPanelSlice>
    </GenPanel>
  }

  let actions = []
  if (canPossiblyAccess(current_role_definition, 'document', 'create')) {
    actions.push(<GenButton key="Create" name="Create Case" highlighted href="/documents/new"/>)
  }

  if (current_user?.is_admin) {
    actions.push(<GenButton key="Lend" name="Lend Cases" highlighted href="/group_documents/new_batch"/>)
    actions.push(<GenButton key="Revoke" name="Revoke Cases" highlighted href="/group_documents/delete_batch"/>)
    actions.push(<GenButton key="Report" name="Cases Report" highlighted href="/documents.csv"/>)
  }

  let filters = [
    {
      key: "patient_interaction_physical_exam",
      title: "Only cases with a physical exam",
      type: "checkbox",
      dataType: "MDATA",
      mdataKey: "patient_interaction",
      mdataValue: ["Physical Exam"],
      mdataTable: "documents",
    },
    {
      key: "patient_interaction_procedure",
      title: "Only cases with a procedure",
      type: "checkbox",
      dataType: "MDATA",
      mdataKey: "patient_interaction",
      mdataValue: ["Procedure"],
      mdataTable: "documents",
    },
    {
      title: "Demographics",
      type: "filter_group",
      filters: [
        {
          key: "gender",
          title: "Gender",
          type: "checkboxes",
          dataType: "MDATA",
          options: document_mdata_definition.gender.values,
          mdataTable: "documents",
        },
        {
          key: "race",
          title: "Race",
          type: "checkboxes",
          dataType: "MDATA",
          dataCount: "MDATA_MANY",
          options: document_mdata_definition.race.values,
          mdataTable: "documents",
        },
        {
          key: "age_group",
          title: "Age Category",
          type: "checkboxes",
          dataType: "MDATA",
          dataCount: "MDATA_MANY",
          options: document_mdata_definition.age_group.values,
          mdataTable: "documents",
        },
        {
          key: "age",
          title: "Chronological Age",
          type: "ymwd",
          mdataTable: "documents",
        }
      ]
    }, {
      key: "organ_system",
      title: "Organ System",
      displayLikeFilterGroup: true,
      type: "checkboxes",
      dataType: "MDATA",
      dataCount: "MDATA_MANY",
      options: document_mdata_definition.organ_system.values,
      mdataTable: "documents",
    }, {
      title: "Location",
      type: "filter_group",
      filters: [
        {
          key: "clinical_location",
          title: "Clinical Location",
          type: "checkboxes",
          dataType: "MDATA",
          dataCount: "MDATA_MANY",
          options: document_mdata_definition.clinical_location.values,
          mdataTable: "documents",
        },
        {
          key: "secondary_location",
          title: "Secondary Location",
          type: "checkboxes",
          dataType: "MDATA",
          dataCount: "MDATA_MANY",
          options: document_mdata_definition.secondary_location.values,
          mdataTable: "documents",
        },
      ]
    }, {
      title: "APN Curriculum",
      type: "filter_group",
      filters: [
        {
          key: "apn_area_of_study",
          title: "Area of Study",
          type: "checkboxes",
          dataType: "MDATA",
          dataCount: "MDATA_MANY",
          options: document_mdata_definition.apn_area_of_study.values,
          mdataTable: "documents",
        }
      ]
    }, {
      title: "CoAEMSP Curriculum",
      type: "filter_group",
      filters: [
        {
          key: "coaemsp_pathology",
          title: "Pathology or Patient Complaint",
          type: "checkboxes",
          dataType: "MDATA",
          dataCount: "MDATA_MANY",
          options: document_mdata_definition.coaemsp_pathology.values,
          mdataTable: "documents",
        }
      ]
    }, {
      title: "PANCE",
      type: "filter_group",
      filters: [
        {
          key: "pance_medical_content_categories",
          title: "Medical Content Categories",
          type: "checkboxes",
          dataType: "MDATA",
          dataCount: "MDATA_MANY",
          options: document_mdata_definition.pance_medical_content_categories.values,
          mdataTable: "documents",
        }
      ]
    }, {
      key: "specialty",
      title: "Medical Specialty",
      displayLikeFilterGroup: true,
      type: "checkboxes",
      dataType: "MDATA",
      dataCount: "MDATA_MANY",
      options: document_mdata_definition.specialty.values,
      mdataTable: "documents",
    }, {
      key: "reeldx_libraries",
      title: "ReelDx Libraries",
      displayLikeFilterGroup: true,
      type: "checkboxes",
      dataType: "MDATA",
      dataCount: "MDATA_MANY",
      options: document_mdata_definition.reeldx_libraries.values,
      mdataTable: "documents",
    }
  ];

  if (current_user?.is_admin) {
    filters = [...filters,
      {
        key: "publish_version_id",
        title: "Publishing Status",
        displayLikeFilterGroup: true,
        type: "checkboxes",
        dataType: "BOOLEAN",
        options: [
          {
            displayName: "Published",
            value: true
          },
          {
            displayName: "Not Published",
            value: false
          },
        ]
      },
      {
        key: "circulation",
        title: "Circulation",
        displayLikeFilterGroup: true,
        type: "checkboxes",
        dataType: "REGULAR",
        options: [
          {
            displayName: "Public",
            value: "public_circulation"
          },
          {
            displayName: "Group",
            value: "group_circulation"
          },
          {
            displayName: "Invite",
            value: "invite_circulation"
          },
          {
            displayName: "None",
            value: "no_circulation"
          },
        ]
      },
    ];
  }

  return (
    <GenSidebarList
      {...documentListDefaults}
      itemView={(group.root_document_set_id) && ((document) => {
        return (
          <DocumentSummary
            document={document}
            key={document.id}
            rootDocumentSetId={group.root_document_set_id}
            currentUser={current_user}
            currentRoleDefinition={current_role_definition}
          />
        );
      })}
      historyPersistance={true}
      hideSidebar={hideSidebar}
      gridView
      actions={actions}
      beforeListContent={groupHeader}
      filters={filters}
      canCreateCategory={canCreateCategory}
    />
  );
}
