// Selection
// {
//   range: Range
//   uid: String
//   key: String
// }


export function getDOMSelection() {
  let domSelection = window.getSelection()
  return domSelection.rangeCount > 0 ? domSelection : null
}

export function getDOMRange() {
  let domSelection = getDOMSelection()
  return domSelection ? domSelection.getRangeAt(0) : null
}

export function getDocRangeSelection() {
  return domRangeToDocRange(getDOMRange())
}

export function clearAllDocRangeSelection() {
  if (getDocRangeSelection()) { window.getSelection().removeAllRanges() }
}

export function domRangeToDocRange(domRange) {
  if (!domRange) { return null }

  let ancestorInlineTextNode = getAncestorInlineTextNodeFromChildNode(domRange.startContainer)
  if (!ancestorInlineTextNode) { return null }

  let start = 0
  let end = 0
  if (!(ancestorInlineTextNode.dataset.isempty === 'true')) {
    let startContainerOffset = findTextOffsetNodeInNode(ancestorInlineTextNode, domRange.startContainer)
    let endContainerOffset = findTextOffsetNodeInNode(ancestorInlineTextNode, domRange.endContainer)

    if (startContainerOffset === null || endContainerOffset === null) { return null }

    start = startContainerOffset + domRange.startOffset
    end = endContainerOffset + domRange.endOffset
  }

  return {
    range: {
      start: start,
      end: end
    },
    uid: ancestorInlineTextNode.dataset.uid,
    key: ancestorInlineTextNode.dataset.contentkey
  }
}


export function areEqualRangeSelections(selection1, selection2) {
  return selection1 && selection2 &&
    selection1.uid === selection2.uid &&
    selection1.key === selection2.key &&
    selection1.range && selection2.range &&
    selection1.range.start === selection2.range.start &&
    selection1.range.end === selection2.range.end
}

function nodeTextLength(node) {
  return (node.nodeType === Node.TEXT_NODE) ? node.textContent.length : 0
}

function findTextOffsetNodeInNode(parentNode, childNode) {
  if (parentNode === childNode) {return 0}

  let newWalker = document.createTreeWalker(parentNode, NodeFilter.SHOW_ALL)

  let offset = 0
  while(newWalker.nextNode()) {
    let currentNode = newWalker.currentNode

    if (currentNode === childNode) {
      return offset
    } else {
      offset = offset + nodeTextLength(currentNode)
    }
  }

  return null
}

export function isNodeInlineText(node) {
  return !!node.classList && node.classList.contains("doc-inline-text")
}

export function getAncestorInlineTextNodeFromChildNode(node) {
  if (!node) { return null }

  return isNodeInlineText(node) ? node : getAncestorInlineTextNodeFromChildNode(node.parentNode)
}

export function getAncestorInlineTextNodeFromUIDAndContentKey(uid, key) {
  return document.querySelector('[data-uid="' + uid + '"][data-contentkey="' + key + '"].doc-inline-text')
}

export function setDocSelection(docSelection) {
  let domRange = docSelectionToDOMRange(docSelection)
  if (domRange) { setDOMRange(domRange) }
}

export function setDOMRange(domRange) {
  let domSelection = window.getSelection()
  domSelection.removeAllRanges()
  domSelection.addRange(domRange)
}

export function docSelectionToDOMRange(docSelection) {
  if (!docSelection) { return null }

  let parentNode = getAncestorInlineTextNodeFromUIDAndContentKey(docSelection.uid, docSelection.key)

  if (parentNode) {
    let rangeStart = findNodeAndOffsetFromTextOffset(parentNode, docSelection.range.start)
    let rangeEnd = findNodeAndOffsetFromTextOffset(parentNode, docSelection.range.end)

    let domRange = document.createRange()
    domRange.setStart(...rangeStart)
    domRange.setEnd(...rangeEnd)
    return domRange
  }

  return null
}

function findNodeAndOffsetFromTextOffset(parentNode, textOffset) {
  if (textOffset === 0) { return [parentNode, 0] }

  let newWalker = document.createTreeWalker(parentNode, NodeFilter.SHOW_ALL)

  let currentOffset = 0
  let currentNode
  while(newWalker.nextNode()) {
    currentNode = newWalker.currentNode

    let newOffset = currentOffset + nodeTextLength(currentNode)
    if (newOffset >= textOffset) {
      return [currentNode, textOffset - currentOffset]
    } else {
      currentOffset = newOffset
    }
  }

  if (currentNode) {
    // If range exceeds end of parentNode
    // place at end of last child
    return [currentNode, nodeTextLength(currentNode)]
  } else {
    // If parent had no nodes
    // Place at start of parent
    return [parentNode, 0]
  }
}
