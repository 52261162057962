export function isAdmin(currentUser) {
  return currentUser && currentUser.type === "Admin"
}

export function canPossiblyAccess(currentRoleDefinition, ...args) {
  return !!lookupInDefinition(currentRoleDefinition, ...args)
}

function lookupInDefinition(currentRoleDefinition, ...args) {
  let result = args.reduce((definition, arg) => definition === true || (typeof(definition) === "object" && definition[arg]), currentRoleDefinition.temp_definition)
  return result || false
}
