import React from 'react';

export default function DocExtra(
  {
    editState,
    subtype,
    content,
  },
) {
  if (!editState.isEditor) { return(null); }

  switch(subtype){
    case "error":
      return <p className="doc-extra doc-extra-error" dangerouslySetInnerHTML={{__html: content}}/>;
    case "warning":
      return <p className="doc-extra doc-extra-warning" dangerouslySetInnerHTML={{__html: content}}/>;
    default:
      return <p className="doc-extra doc-extra-unknown" dangerouslySetInnerHTML={{__html: content}}/>;
  }
}
