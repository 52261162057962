import React from 'react';

export default function GenPanelSlice(
  {
    children,
  },
) {
  return (
    <div className="gen-panel-slice">
      {children}
    </div>
  );
}
