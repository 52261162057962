import React from 'react';

import GenDirectionalButtons from 'Gen/DirectionalButtons'

export default function GenSortButtons(
  {
    setListSort,
    resetListPages,
    readListPage,
    sortOptions,
    sort_by,
    sort_order,
  },
) {
  const updateSort = (newSortValue, newSortDirection) => {
    (newSortDirection === "descending") ? "DESC" : "ASC"

    setListSort(newSortValue, newSortDirection === "descending" ? "DESC" : "ASC")
    resetListPages()
    readListPage()
  }

  return (
    <GenDirectionalButtons
      buttons={sortOptions}
      selectedValue={sort_by}
      selectedDirection={sort_order === "DESC" ? "descending" : "ascending"}
      onUpdate={updateSort}
    />
  );
}
