import React from 'react';

import GenButton from 'Gen/Button'

export default function GenCycleButton(props) {
  let currentValue = props.currentValue;
  let maybeCurrentStateObject = props.states.filter(function(state){
    return(state.value === currentValue);
  })[0];

  const currentStateObject = maybeCurrentStateObject ? maybeCurrentStateObject : props.states[0];

  const internalOnClick = (value) => {
    props.onClick(currentStateObject.nextValue);
  };

  return (
    <GenButton
      name={currentStateObject.button.name}
      highlighted={currentStateObject.button.highlighted}
      disabled={currentStateObject.button.disabled || props.disabled}
      onClick={internalOnClick} />
  );
}
