import React from 'react';

import GenButton from 'Gen/Button'
import GenList from 'Gen/List'

export default class GenSelectList extends React.Component {
  onItemClick = (item) => {
    let newSelected
    if (this.props.selected.indexOf(item.id) >= 0) {
      newSelected = this.props.selected.filter(function(id){ return(id !== item.id); });
    } else {
      newSelected = this.props.selected.concat([item.id]);
    }

    this.props.onSelectionChange(newSelected);
  };

  generateView = (that) => {
    return(function(item) {
      let classes = "gen-select-item";
      if (that.props.selected.indexOf(item.id) >= 0) {
        classes += " bg-sky-100";
      }

      return(
        <div className={classes} onClick={() => {that.onItemClick(item);}} key={item.id}>
          {that.props.itemView(item, that.props.selected.indexOf(item.id) >= 0)}
        </div>
      );
    });
  };

  selectAll = (successCallback) => {
    if (this.childGenList && this.childGenList.state.currentItems) {
      this.props.onSelectionChange(this.childGenList.state.currentItems.map(function(item) { return(item.id); }));
    } else {
      this.props.onSelectionChange([]);
    }
  };

  deselectAll = () => {
    this.props.onSelectionChange([]);
  };

  render() {
    return(<div className="gen-select-list">
      <div className="gen-select-list-buttons">
        <div className="gen-select-list-buttons-left flex gap-2 items-baseline">
          <GenButton name="Select All Shown" onClick={this.selectAll} highlighted small/>
          <GenButton name="Deselect All" onClick={this.deselectAll} highlighted small/>
          <span>{this.props.selected.length} selected</span>
        </div>
        <div className="gen-select-list-buttons-right">
          {this.props.selectExtraButtons}
        </div>
      </div>
      <GenList
        ref={(input) => { this.childGenList = input; }}
        {...this.props}
        itemView={this.generateView(this)}
      />
    </div>);
  }
}
