import React from 'react';

import GenList from 'Gen/List'

import DocumentSetSummary from 'DocumentSet/Summary'

export default function DocumentSetList(
  {
    document_set_id,
    current_role_definition,
    shows_ownership_checkbox,
    user_id,
  },
) {
  return (
    <GenList
      itemsURL={"/document_sets/" + document_set_id + "/document_sets"}
      itemView={documentSet => <DocumentSetSummary documentSet={documentSet} key={documentSet.id}/>}
      noItemsMessage={current_role_definition?.name === "Learner" && "You have not been added to any courses yet. If you believe this is a mistake, please contact your instructor."}
      search
      sortOptions={[
                    {
                      "title": "Name",
                      "value": "document_sets.name"
                    },
                    {
                      "title": "Owner",
                      "value": "users.last_name"
                    }
                  ]}
      checkboxFilterOptions={shows_ownership_checkbox && [
                              {
                                "key": "owner",
                                "whereFilter": ["=", "document_sets.owner_id", user_id],
                                "label": "Only courses that I own"
                              }
                            ]}
    />
  );
}
