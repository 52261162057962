import React from "react";

import connectToList from "../../store/list";

import GenControlsBar from "../Gen/ControlsBar";
import GenMore from "../Gen/More";
import GenSearchBox from "../Gen/SearchBox";
import DocumentUserSummary from "./Summary";

function DocumentUserList(
  {
    documentId,
    documentUsers,
  },
) {
  React.useEffect(() => {
    documentUsers.mountList(`/documents/${documentId}/document_users`);
  }, []);

  return (
    <div>
      <GenControlsBar>
        <GenSearchBox
          {...documentUsers}
        />
      </GenControlsBar>
      {documentUsers.listData?.map((documentUser) => (
        <DocumentUserSummary
          key={documentUser.id}
          documentUser={documentUser}
        />
      ))}
      <GenMore
        {...documentUsers}
        shouldInfiniteScroll
      />
    </div>
  );
}

export default connectToList(DocumentUserList, ["documentUsers"]);
