import axios from 'axios'
import pluralize from 'pluralize'
import * as R from 'ramda'
import {snakeCase} from 'lodash'

import { fullURL } from 'src/url'

export const SEED = 'item/SEED'

export const CREATE = 'item/CREATE'
export const READ = 'item/READ'
export const UPDATE = 'item/UPDATE'
export const DESTROY = 'item/DESTROY'

const PENDING = '_PENDING'
const FULFILLED = '_FULFILLED'
const REJECTED = '_REJECTED'

// SHOULD INCLUDE DELETE_EDIT_DATA_KEYS???
// SHOULD INCLUDE DELETE_LOCAL_DATA_KEYS???

export const SET_EDIT_DATA_FIELDS = 'item/SET_EDIT_DATA_FIELDS'
export const RESET_EDIT_DATA = 'item/RESET_EDIT_DATA'
export const SET_LOCAL_DATA_FIELDS = 'item/SET_LOCAL_DATA_FIELDS'
export const RESET_LOCAL_DATA = 'item/RESET_LOCAL_DATA'

function itemsReducer(itemType, state={}, action={}, options={}) {
  if (!action.meta || action.meta.itemType != itemType) {
    return state
  }

  switch (action.type) {
    case READ + PENDING:
      return {...state,
        [action.meta.id]: itemReducer(state[action.meta.id], action)
      }
    case CREATE + FULFILLED:
      return {...state,
        [action.meta.id]: undefined,
        [action.payload.id]: itemReducer(state[action.meta.id], action)
      }
    case DESTROY + FULFILLED:
      return R.omit([action.meta.id], state)
    case SEED:
    case CREATE + PENDING:
    case CREATE + REJECTED:
    case READ + FULFILLED:
    case READ + REJECTED:
    case UPDATE + PENDING:
    case UPDATE + FULFILLED:
    case UPDATE + REJECTED:
    case DESTROY + PENDING:
    case DESTROY + REJECTED:
    case SET_EDIT_DATA_FIELDS:
    case RESET_EDIT_DATA:
    case SET_LOCAL_DATA_FIELDS:
    case RESET_LOCAL_DATA:
      if (state[action.meta.id]) {
        return {...state,
          [action.meta.id]: itemReducer(state[action.meta.id], action)
        }
      } else {
        return state
      }
    default:
      return state
  }
}

const DEFAULT_EDIT_DATA = {}
const DEFAULT_LOCAL_DATA = {}


// {
//   savedData: {},
//   editData: {},
//   localData: {},
//   isSyncing: false,
//   lastSyncedAt: Date.now(),
//   errors: null,
// }

function itemReducer(state={editData: DEFAULT_EDIT_DATA, localData: DEFAULT_LOCAL_DATA}, action={}, options={}) {
  switch (action.type) {
    case SEED:
      return {...state,
        savedData: action.payload.data
      }
    case CREATE + PENDING:
    case READ + PENDING:
    case UPDATE + PENDING:
    case DESTROY + PENDING:
      return {...state,
        isSyncing: true
      }
    case CREATE + REJECTED:
    case READ + REJECTED:
    case UPDATE + REJECTED:
    case DESTROY + REJECTED:
      return {...state,
        isSyncing: false,
        syncError: action.payload.message
      }
    case CREATE + FULFILLED:
    case UPDATE + FULFILLED:
      return {...state,
        isSyncing: false,
        lastSyncedAt: Date.now(),
        errors: null,
        editData: DEFAULT_EDIT_DATA,
        savedData: action.payload.data
      }
    case READ + FULFILLED:
      return {...state,
        isSyncing: false,
        lastSyncedAt: Date.now(),
        errors: null,
        savedData: action.payload.data
      }
    case SET_EDIT_DATA_FIELDS:
      return {...state,
        editData: {...state.editData,
          ...action.payload.fields
        }
      }
    case RESET_EDIT_DATA:
      return {...state,
        editData: DEFAULT_EDIT_DATA
      }
    case SET_LOCAL_DATA_FIELDS:
      return {...state,
        localData: {...state.localData,
          ...action.payload.fields
        }
      }
    case RESET_LOCAL_DATA:
      return {...state,
        localData: DEFAULT_LOCAL_DATA
      }
    default:
      return state
  }
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function generateItemsReducer(itemType, options={}) {
  return (state, action) => itemsReducer(itemType, state, action, options)
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function rawItemFromStore(store, itemType, id) {
  let storeReducerName = pluralize(itemType)
  return store[storeReducerName] && store[storeReducerName][id]
}

function defaultDataMergeFunction(rawItem) {
  return R.mergeRight(rawItem.savedData || {}, rawItem.editData || {})
}

export function itemFromStore(store, itemType, id, mergeFunction=defaultDataMergeFunction) {
  let rawItem = rawItemFromStore(store, itemType, id)
  return rawItem && mergeFunction(rawItem)
}

export function itemSavedDataFromStore(store, itemType, id) {
  let rawItem = rawItemFromStore(store, itemType, id)
  return rawItem?.savedData
}

export function itemEditDataFromStore(store, itemType, id) {
  let rawItem = rawItemFromStore(store, itemType, id)
  return rawItem?.editData
}

export function itemLocalDataFromStore(store, itemType, id) {
  let rawItem = rawItemFromStore(store, itemType, id)
  return rawItem?.localData
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

function defaultOutTransformer(data) { return data }

function createItemWithData(itemType, endpoint, tempId, data, outTransformer=defaultOutTransformer) {
  return {
    type: CREATE,
    meta: {
      itemType,
      tempId,
    },
    payload: axios({
      url: fullURL('/' + endpoint),
      data: outTransformer(data),
      method: 'post',
      withCredentials: true,
      headers: {'Accept': 'application/json'}
    })
  }
}

export function createItem(itemType, endpoint, tempId, outTransformer=defaultOutTransformer) {
  return (dispatch, getState) => {
    let state = getState()
    let item = state[pluralize(itemType)][tempId]
    let editData = item.editData

    dispatch(createItemWithData(itemType, endpoint, tempId, editData, outTransformer=defaultOutTransformer))
  }
}

export function readItem(itemType, endpoint, id) {
  return {
    type: READ,
    meta: {
      itemType,
      id,
    },
    payload: axios({
      url: fullURL('/' + endpoint + (id || '')),
      method: 'get',
      withCredentials: true,
      headers: {'Accept': 'application/json'}
    }),
  }
}

export function updateItemWithData(itemType, endpoint, id, data, outTransformer=defaultOutTransformer) {
  return {
    type: UPDATE,
    meta: {
      itemType,
      id,
    },
    payload: axios({
      url: fullURL('/' + endpoint + (id || '')),
      data: {[snakeCase(itemType)]: outTransformer(data)},
      method: 'put',
      withCredentials: true,
      headers: {'Accept': 'application/json'}
    })
  }
}

export function updateItem(itemType, endpoint, id, outTransformer=defaultOutTransformer) {
  return (dispatch, getState) => {
    let state = getState()
    let item = state[pluralize(itemType)][id]
    let editData = item.editData

    dispatch(updateItemWithData(itemType, endpoint, id, editData, outTransformer=defaultOutTransformer))
  }
}

export function destroyItem(itemType, endpoint, id) {
  return {
    type: DESTROY,
    meta: {
      itemType: itemType,
      id: id
    },
    payload: axios({
      url: fullURL('/' + endpoint + (id || '')),
      method: 'delete',
      withCredentials: true,
      headers: {'Accept': 'application/json'}
    })
  }
}

export function generateActions(itemType, endpoint) {
  let capitalizedItemType = itemType.charAt(0).toUpperCase() + itemType.slice(1)

  return {
    [`seed${capitalizedItemType}`]: (id, data) => { return {
      type: SET_LOCAL_DATA_FIELDS,
      meta: {itemType, id},
      payload: {data}
    }},
    [`create${capitalizedItemType}`]: (tempId) => createItem(itemType, endpoint, tempId),
    [`read${capitalizedItemType}`]: (id) => readItem(itemType, endpoint, id),
    [`update${capitalizedItemType}`]: (id) => updateItem(itemType, endpoint, id),
    [`destroy${capitalizedItemType}`]: (id) => destroyItem(itemType, endpoint, id),
    [`setEditFields${capitalizedItemType}`]: (id, fields) => { return {
      type: SET_EDIT_DATA_FIELDS,
      meta: {itemType, id},
      payload: {fields}
    }},
    [`resetEditFields${capitalizedItemType}`]: (id) => { return {
      type: RESET_LOCAL_DATA,
      meta: {itemType, id}
    }},
    [`setLocalFields${capitalizedItemType}`]: (id, fields) => { return {
      type: SET_LOCAL_DATA_FIELDS,
      meta: {itemType, id},
      payload: {fields}
    }},
    [`resetLocalFields${capitalizedItemType}`]: (id) => { return {
      type: RESET_LOCAL_DATA,
      meta: {itemType, id}
    }}
  }
}
