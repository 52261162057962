import React from 'react';

import DocBookmark from 'Doc/Bookmark'
import DocInlineText from 'Doc/InlineText'

export default function DocParagraph(props) {
  return (
    <div className="doc-paragraph">
      <p>
        <DocInlineText content={props.content} uid={props.uid} contentKey="content" editState={props.editState}/>
      </p>
      <DocBookmark {...props} />
    </div>
  );
}
