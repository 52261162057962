import React from 'react'

export default function VersionSummary(
  {
    version,
  },
) {
  return (
    <div className="panel">
      <h1 className="name">
        <a href={"/versions/" + version.id}>
          {version.updated_at}
        </a>
      </h1>
      <p>ID: {version.id}</p>
    </div>
  );
}
