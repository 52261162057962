import React from "react";

import connectToList from "../../store/list";

import GenMore from "../Gen/More";
import GenControlsBar from "../Gen/ControlsBar";
import GenSearchBox from "../Gen/SearchBox";
import GenSortButtons from "../Gen/SortButtons";

const ENDPOINT = "institutions";

function InstitutionList(
  {
    institutions,
  },
) {
  React.useEffect(() => {
    institutions.mountList(ENDPOINT);
  }, []);

  const items = (institutions.listData || []).map((item) => (
    <div className="panel" key={item.id}>
      <h1>
        <a href={`/institutions/${item.id}`}>{item.name}</a>
      </h1>
    </div>
  ));

  return (
    <div>
      <GenControlsBar>
        <GenSearchBox
          {...institutions}
        />
        <GenSortButtons
          {...institutions}
          sortOptions={[
            {
              title: "Name",
              value: "institutions.name",
            },
          ]}
        />
      </GenControlsBar>
      {items}
      <GenMore
        {...institutions}
        shouldInfiniteScroll
      />
    </div>
  );
}

export default connectToList(InstitutionList, "institutions");
