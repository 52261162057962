import React from 'react';

import classNames from 'classnames'

export default function GenListDisplay(
  {
    selected,
    setListSelected,
    listData,
    renderItem,
  },
) {
  const onItemClick = (item) => {
    const isSelected = selected.some(sItem => sItem.id === item.id);
    let newSelectedItems = isSelected ? selected.filter(sItem => sItem.id !== item.id) : selected.concat([item])
    setListSelected(newSelectedItems)
  };

  const renderedItems = (listData || []).map(item => {
    const isSelected = selected.some(sItem => sItem.id === item.id);

    return (
      <div
        key={item.id}
        className={classNames("panel", "gen-list-display-panel", isSelected ? "selected" : "")}
        onClick={() => {onItemClick(item)}}
      >
        {renderItem(item)}
      </div>
    );
  })

  return (
    <div className="gen-list-display">
      {renderedItems}
    </div>
  )
}
