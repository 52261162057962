import React from 'react';

export default function GenFilterGroup(
  {
    title,
    children,
  },
) {
  return (
    <div className="gen-list-filter-group">
      <h4>{title}</h4>
      <div className="gen-list-filter-group-filters">
        {children}
      </div>
    </div>
  );
}
