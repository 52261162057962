import React from 'react';

import DocInlineText from 'Doc/InlineText'

export default function DocListItem(
  {
    content,
    uid,
    editState,
  },
) {
  return (
    <li className="doc-list-item">
      <DocInlineText content={content} uid={uid} contentKey="content" editState={editState}/>
    </li>
  );
}
