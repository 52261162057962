export function sortBasedOnType(a, b) {
  if ((typeof a) === "string") {
    return a.localeCompare(b);
  } else if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
}
