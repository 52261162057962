import React from 'react';

export default function UserSummary(
  {
    user,
  },
) {
  return (
    <div className="panel">
      <h1 className="full_name">
        <a href={"/users/" + user.id}>
          <span className="first_name">{user.first_name}</span> <span className="last_name">{user.last_name}</span>
        </a>
      </h1>
      <p className="email">{user.email}</p>
    </div>
  );
}
