import React from 'react';

export default function GenControlsBar(
  {
    children,
  },
) {
  return (
    <div className="gen-controls-bar">
      {children}
    </div>
  );
}
