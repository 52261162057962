import * as R from 'ramda'

// Range :: {
//   start: INTEGER,
//   end: INTEGER
// }

export function isCollapsedRange(range) {
  return range.start >= range.end
}

export function adjustRangeValues(range, offset, cutoff=0) {
  return adjustRangeValuesWithFunction(range, val => val >= cutoff ? val + offset : val)
}

export function adjustRangeValuesAtMostToCuttoff(range, offset, cutoff=0) {
  return adjustRangeValuesWithFunction(range, val => val >= cutoff ? R.max(val + offset, cutoff) : val)
}

function adjustRangeValuesWithFunction(range, func) {
  return R.compose(R.over(R.lensProp('start'), func), R.over(R.lensProp('end'), func))(range)
}

function combineRanges(ranges) {
  return R.sortBy(range => range.start, ranges).reduce((acc, range) => {
    if (acc.length === 0) {
      return [range]
    } else {
      let lastRange = R.last(acc)
      if (lastRange.end < range.start) {
        return acc.concat([range])
      } else {
        return R.init(acc).concat([{
          start: lastRange.start,
          end: R.max(lastRange.end, range.end)
        }])
      }
    }
  }, [])
}
