import React from "react";

export default function GenBooleanCircle(props) {
  const {
    value,
  } = props;

  return (
    value
      ? <i className="fa fa-check-circle boolean-check" />
      : <i className="fa fa-times-circle boolean-times" />
  );
}
