import React from 'react'
import { Provider, connect } from 'react-redux'

import Mousetrap from 'mousetrap'
import * as R from 'ramda'
import classNames from 'classnames'

import store from 'store/store'

import DocMediaModal from 'MediaModal'
import DocEditMetadataModal from 'EditMetadataModal'
import DocSection from 'Doc/Section'
import DocTopBar from 'TopBar'
import GenLoadingSpinner from 'Gen/LoadingSpinner'

import {
  addActivitiesToDocument
} from 'src/activities'

import {
  inlineHTMLToJSON,
  inlineJSONToHTML
} from 'src/convert'

import {
  DISPLAY_MODES,
  displayTraitsBasedOnMode,
} from 'src/display_mode'

import {
  deepFindByUID,
  deepFindLineageByUID,
  deepFindOldestNotSectionAncestorByUID,
  deepFindSiblingBeforeByUID,
  deleteNodeByUID,
  generateNode,
  replaceNodeByUID,
  insertAfterNodeByUID,
  insertBeforeNodeByUID,
  modifyNodeValueByUIDAndKey,
  setNodeValueByUIDAndKey,
  siblingCountByUID,
  siblingIndexByUID,
  newSetNodeKVPairAction,
  newOpenLinkAction,
} from 'src/doc_helpers'

import {
  replaceText,
  inlineJSONBeforeIndex,
  inlineJSONAfterIndex
} from 'src/inline_json'

import {
  getDocRangeSelection
} from 'src/selection'

import {
  generateStyleFunction
} from 'src/style'

import {
  fullURL
} from 'src/url'

import {
  nowInSeconds
} from 'src/now_in_seconds'

import {
  readDocumentSetDocumentState,
  updateDocumentSetDocumentState,
  destroyDocumentSetDocumentState,
  setEditFieldsDocumentSetDocumentState,
  resetEditFieldsDocumentSetDocumentState,
  setLocalFieldsDocumentSetDocumentState,
  resetLocalFieldsDocumentSetDocumentState,
  documentSetDocumentStateFromStore,
  addDocumentSetDocumentState,
  resetDocumentSetDocumentState,
} from 'reducers/document_set_document_state'

import {
  readDocument,
  documentFromStore,
} from 'reducers/document'

import {
  readVersion,
  updateVersion,
  setEditFieldsVersion,
  setLocalFieldsVersion,
  versionFromStore,
  versionEditDataFromStore,
  versionLocalDataFromStore,
} from 'reducers/version'

import {
  readActivity,
  updateActivity,
  setEditFieldsActivity,
  setLocalFieldsActivity,
  activityFromStore,
  activityEditDataFromStore,
  activityLocalDataFromStore,
} from 'reducers/activity'

import {
  readActivityUserState,
  activityUserStateFromStore,
  addActivityUserState,
} from 'reducers/activity_user_state'

function document_usc_url(document_id){ return fullURL(`/documents/${document_id}/usc`) }
function document_user_log_url(document_id){ return fullURL(`/documents/${document_id}/document_user_logs/create_batch`) }

const mapStateToProps = (state, oProps) => {
  const {
    type,
    activityID,
    documentID,
    versionID,
    currentDocumentSetDocumentRelation,
    currentDocumentActivities=[],
    currentDocumentSetDocumentActivities=[],
  } = oProps

  if (type === "Activity") {
    const activityDocument = activityFromStore(state, activityID)
    return {
      currentDocument: activityDocument && {...activityDocument, type: "Activity"},
      currentVersion: activityFromStore(state, activityID),
      versionEditData: activityEditDataFromStore(state, activityID),
      versionLocalData: activityLocalDataFromStore(state, activityID),
      activityUserStatesByActivityId: [],
    }
  } else {
    const combinedDocumentActivities = currentDocumentActivities.concat(currentDocumentSetDocumentActivities)

    let documentSetDependentProps = {};
    documentSetDependentProps = {
      documentSetDocumentState: currentDocumentSetDocumentRelation && documentSetDocumentStateFromStore(state, `${currentDocumentSetDocumentRelation.document_set_id}/${documentID}`),
      activityUserStatesByActivityId: R.fromPairs(combinedDocumentActivities.map(documentSetDocumentActivities => {
        const activityId = documentSetDocumentActivities.activity.id
        const longActivityId = currentDocumentSetDocumentRelation ? `${activityId}/${currentDocumentSetDocumentRelation.document_set_id}/${documentID}` : `${activityId}/${documentID}`
        return [activityId, activityUserStateFromStore(state, longActivityId)]
      })),
    }

    return {
      currentDocument: documentFromStore(state, documentID),
      currentVersion: versionFromStore(state, versionID),
      versionEditData: versionEditDataFromStore(state, versionID),
      versionLocalData: versionLocalDataFromStore(state, versionID),
      ...documentSetDependentProps,
    }
  }
}

const mapDispatchToProps = (dispatch, oProps) => {
  const {
    type,
    activityID,
    documentID,
    versionID,
    currentDocumentSetDocumentRelation,
  } = oProps;

  let dispatchFunctions = {};

  if (type === "Activity") {
    dispatchFunctions = {
      // DOCUMENT
      readDocument: () => dispatch(readActivity(activityID)),
      // VERSION
      readVersion: () => dispatch(readActivity(activityID)),
      updateVersion: () => dispatch(updateActivity(activityID)),
      setEditFieldsVersion: (fields) => dispatch(setEditFieldsActivity(activityID, fields)),
      setLocalFieldsVersion: (fields) => dispatch(setLocalFieldsActivity(activityID, fields)),
      // ACTIVITY
      readActivityUserState: (activityId) => {},
      addActivityUserState: (activityId, state) => {},
    }

    dispatchFunctions = {
      ...dispatchFunctions,
      setEditJSON: content => dispatchFunctions.setEditFieldsVersion({content}),
    }
  } else {
    const activityIdSuffix = currentDocumentSetDocumentRelation ? `${currentDocumentSetDocumentRelation.document_set_id}/${documentID}` : `${documentID}`

    dispatchFunctions = {
      // DOCUMENT
      readDocument: () => dispatch(readDocument(documentID)),
      // VERSION
      readVersion: () => dispatch(readVersion(versionID)),
      updateVersion: () => dispatch(updateVersion(versionID)),
      setEditFieldsVersion: (fields) => dispatch(setEditFieldsVersion(versionID, fields)),
      setLocalFieldsVersion: (fields) => dispatch(setLocalFieldsVersion(versionID, fields)),
      // DOCUMENT SET DOCUMENT STATE
      readDocumentSetDocumentState: () => dispatch(readDocumentSetDocumentState(`${currentDocumentSetDocumentRelation.document_set_id}/${documentID}`)),
      addDocumentSetDocumentState: (state) => dispatch(addDocumentSetDocumentState(`${currentDocumentSetDocumentRelation.document_set_id}/${documentID}`, state)),
      resetDocumentSetDocumentState: () => dispatch(resetDocumentSetDocumentState(`${currentDocumentSetDocumentRelation.document_set_id}/${documentID}`)),
      // ACTIVITY
      readActivityUserState: (activityId) => dispatch(readActivityUserState(`${activityId}/${activityIdSuffix}`)),
      addActivityUserState: (activityId, state) => dispatch(addActivityUserState(`${activityId}/${activityIdSuffix}`, state))
    }

    dispatchFunctions = {
      ...dispatchFunctions,
      setEditJSON: json => dispatchFunctions.setEditFieldsVersion({json}),
    }
  }

  return {
    ...dispatchFunctions,
    setCurrentlyEditing: currentlyEditing => dispatchFunctions.setLocalFieldsVersion({currentlyEditing}),
    setDisplayMode: displayMode => dispatchFunctions.setLocalFieldsVersion({displayMode}),
    setUndoRedoQueues: (undoQueue, redoQueue) => dispatchFunctions.setLocalFieldsVersion({undoQueue, redoQueue}),
    setEditMetadata: (editMetadataUid) => dispatchFunctions.setLocalFieldsVersion({editMetadataUid}),
  }
}

class DocContainer extends React.Component {
  state = {
    syncCounter: 0,
    uscData: [],
  }

  componentDidMount() {
    const {
      currentDocumentActivities=[],
      currentDocumentSetDocumentActivities=[],
      currentDocumentSetDocumentRelation,
      readActivityUserState,
      readDocumentSetDocumentState,
      readDocument,
      readVersion,
    } = this.props;

    this.resetUndoRedo()
    readVersion()
    readDocument()

    const combinedDocumentActivities = currentDocumentActivities.concat(currentDocumentSetDocumentActivities)

    combinedDocumentActivities.forEach(documentSetDocumentActivity => readActivityUserState(documentSetDocumentActivity.activity.id))

    document.addEventListener("selectionchange", this.onSelectionChange.bind(this), false)

    // let stateObj = { section_uid: topSectionUID };
    // for (let attrname in history.state) { stateObj[attrname] = history.state[attrname]; }
    // history.replaceState(stateObj, "", "?section_uid=" + topSectionUID);
    //
    // window.onpopstate = (event) => {
    //   if (event.state) {
    //     if (event.state.section_uid) {
    //       this.setState({displayMode: "normal"});
    //     }
    //   }
    // };

    if (currentDocumentSetDocumentRelation?.is_gated) {
      readDocumentSetDocumentState()
    } else {
      this.getUSCS()
      this.getUSCSTimer = setInterval(this.getUSCS.bind(this), 60000)
    }

    this.logEvent("root", "section_opened");

    Mousetrap.bind(["del", "backspace"], this.handleDelete.bind(this))
    Mousetrap.bind('command+backspace', this.handleDelete.bind(this))
    Mousetrap.bind('command+del', this.handleDelete.bind(this))
    Mousetrap.bind('option+backspace', this.handleDelete.bind(this))
    Mousetrap.bind('option+del', this.handleDelete.bind(this))
    Mousetrap.bind('enter', this.handleEnter.bind(this))
    Mousetrap.bind('command+b', this.handleStyleButton.bind(this, 'b'))
    Mousetrap.bind('command+i', this.handleStyleButton.bind(this, 'i'))
    Mousetrap.bind('command+u', this.handleStyleButton.bind(this, 'u'))
    Mousetrap.bind('command+shift+=', this.handleStyleButton.bind(this, 'sub'))
    Mousetrap.bind('command+=', this.handleStyleButton.bind(this, 'sup'))

    Mousetrap.bind('command+s', (e) => {e.preventDefault(); this.save()})
    Mousetrap.bind('command+z', (e) => {e.preventDefault(); this.undo()})
    Mousetrap.bind('command+y', (e) => {e.preventDefault(); this.redo()})
  }

  componentWillUnmount() {
    document.removeEventListener("selectionchange", this.onSelectionChange.bind(this), false)

    clearInterval(this.getUSCSTimer)

    Mousetrap.unbind(["del", "backspace"])
    Mousetrap.unbind('enter')
    Mousetrap.unbind('command+b')
    Mousetrap.unbind('command+i')
    Mousetrap.unbind('command+u')
    Mousetrap.unbind('command+shift+=')
    Mousetrap.unbind('command+=')

    Mousetrap.unbind('command+s')
    Mousetrap.unbind('command+z')
    Mousetrap.unbind('command+y')
  }

  onSelectionChange() {
    const {
      versionLocalData,
      setCurrentlyEditing,
    } = this.props;

    let newDocSelection = getDocRangeSelection()

    if (newDocSelection && newDocSelection.uid) {
      if (!versionLocalData.currentlyEditing || !R.equals(R.pick(['uid', 'key', 'range'], versionLocalData.currentlyEditing), newDocSelection)) {
        setCurrentlyEditing(newDocSelection)
      }
    } else if (versionLocalData.currentlyEditing) {
      // setCurrentlyEditing(R.omit(['contentKey', 'range'], versionLocalData.currentlyEditing))
    }
  }

  save() {
    const {
      type,
      updateVersion,
    } = this.props;

    if (type === "Activity" && !confirm("Are you sure you want to save? Changes to the activity will push out to all users using this activity immediately.")) {
      return;
    }

    updateVersion();
  }

  addToUndo(newJSON, newDocSelection) {
    const {
      setUndoRedoQueues,
      versionLocalData,
    } = this.props;

    let newUndo = [[newJSON, newDocSelection]].concat(versionLocalData.undoQueue || []);

    setUndoRedoQueues(newUndo, []);
  }

  redo() {
    const {
      versionLocalData,
      setCurrentlyEditing,
      setEditJSON,
      setUndoRedoQueues,
      currentVersion,
    } = this.props;

    const {
      currentlyEditing,
      undoQueue = [],
      redoQueue = [],
    } = versionLocalData

    if (redoQueue.length > 0) {
      let [newCurrentVersion, newDocSelection] = redoQueue[0]

      let newUndo = [[currentVersion.json, currentlyEditing]].concat(undoQueue)
      let newRedo = redoQueue.slice(1)

      setEditJSON(newCurrentVersion)
      setCurrentlyEditing(newDocSelection)
      setUndoRedoQueues(newUndo, newRedo)
    }
  }

  undo() {
    const {
      versionLocalData,
      currentVersion,
      setEditJSON,
      setCurrentlyEditing,
      setUndoRedoQueues,
    } = this.props;

    const {
      currentlyEditing,
      undoQueue = [],
      redoQueue = [],
    } = versionLocalData

    if (undoQueue.length > 0) {
      let [newCurrentVersion, newDocSelection] = undoQueue[0]

      let newUndo = undoQueue.slice(1)
      let newRedo = [[currentVersion.json, currentlyEditing]].concat(redoQueue)

      setEditJSON(newCurrentVersion)
      setCurrentlyEditing(newDocSelection)
      setUndoRedoQueues(newUndo, newRedo)
    }
  }

  resetUndoRedo() {
    const {
      setUndoRedoQueues,
    } = this.props;

    setUndoRedoQueues([], [])
  }

  openSection(uid) {
    // TODO: REVISE OPEN SECTION
    // this.setState({displayMode: "normal", displayRootSectionUID: uid});
    // let stateObj = { section_uid: uid };
    // history.pushState(stateObj, "", "?section_uid=" + uid);
    this.logEvent(uid, "section_opened");
  };

  logEvent(node_uid, event, data) {
    let logEvent = {"node_uid": node_uid, "event": event, "created_at": nowInSeconds()};

    if (data !== 'undefined') {
      logEvent.data = data;
    }

    this.postDocumentUserLogEvents([logEvent]);
  };

  postDocumentUserLogEvents(events) {
    const {
      type,
      currentUser,
      documentID,
    } = this.props;

    if (type === "Activity") { return; }

    if (currentUser) {
      fetch(document_user_log_url(documentID), {
        method: "POST",
        body: JSON.stringify({"events": events}),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

          return response.json();
        })
        .then(() => {})
        .catch(() => {});
    }
  };

  getUSCS() {
    const {
      currentUser,
      documentID,
    } = this.props;

    const {
      syncCounter,
      uscData,
    } = this.state

    if (currentUser && documentID) {
      fetch(document_usc_url(documentID) + "?lastSyncCounter=" + syncCounter, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

          return response.json();
        })
        .then((data) => this.setState({uscData: uscData.concat(data.usc), syncCounter: data.sync_counter}))
        .catch((err) => {});
    }
  };

  syncUSCS(uscs) {
    const {
      currentUser,
      documentID,
    } = this.props;

    const {
      syncCounter,
      uscData,
    } = this.state

    if (currentUser) {
      fetch(document_usc_url(documentID), {
        method: "POST",
        body: JSON.stringify({"usc": uscs, "lastSyncCounter": syncCounter}),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) { throw new Error(`${response.status} response`, {cause: response}) }

          return response.json();
        })
        .then((data) => {
          this.setState({uscData: uscData.concat(data.usc), syncCounter: data.sync_counter})
        })
        .catch(() => {});
    } else {
      this.setState({uscData: uscData.concat(uscs), syncCounter: 0})
    }
  };

  addDocumentUserState(state) {
    const uscs = R.flatten(
      R.map(([uid, kvObject]) => {
        return R.map(([key, value]) => {
          return {
            uid: uid,
            [key]: value,
            timeStamp: Date.now(),
          }
        }, R.toPairs(kvObject))
      }, R.toPairs(state))
    )

    this.syncUSCS(uscs)
  }

  mergeInUSCS(json, uscs) {
    let clonedVersion = R.clone(json);

    for (let usc of uscs) {
      let node = deepFindByUID(clonedVersion, usc.uid);
      if (node) {
        for (let prop in usc) {
          if (prop !== "uid" && prop !== "timeStamp") {
            node[prop] = usc[prop];
          }
        }
      }
    }

    return clonedVersion
  };

  handleDelete(event) {
    const {
      currentVersion
    } = this.props;

    const {
      currentlyEditing,
      currentlyEditingNode,
      setCurrentlyEditing,
      modifyEditJSON
    } = this.generateEditState()

    if (currentlyEditing?.range) {
      event.preventDefault()

      let isFNBackspace = event.keyCode === 46
      let isDelete = event.key === "Delete" || isFNBackspace

      let isAlt = event.altKey
      let isMeta = event.metaKey

      if (isAlt) {
        function countIndiciesUntilNextSpaceAndThenAChar(string) {
          let array
          if (isDelete) {
            array = string.split('')
          } else {
            array = string.split('').reverse()
          }

          let index = 0
          let isPastACharacter = false

          array.every ( e => {
            if (e !== ' ') {
              isPastACharacter = true
            }

            if (e === ' ' && isPastACharacter) {
              return false
            }

            index += 1

            return true
          })

          return index
        }

        if (isDelete) {
          let substring = currentlyEditingNode.content.substring(currentlyEditing.range.start, currentlyEditingNode.content.length)
          let numCharsToDelete = countIndiciesUntilNextSpaceAndThenAChar(substring)

          currentlyEditing.range.end = currentlyEditing.range.start + numCharsToDelete
        } else {
          let substring = currentlyEditingNode.content.substring(0, currentlyEditing.range.start)
          let numCharsToBackspace = countIndiciesUntilNextSpaceAndThenAChar(substring)

          currentlyEditing.range.start = currentlyEditing.range.start - numCharsToBackspace
        }
      } else if (isMeta) {
          if (isDelete) {
            currentlyEditing.range.end = currentlyEditingNode.content.length
          } else {
            currentlyEditing.range.start = 0
          }
      } else if (isFNBackspace) {
        currentlyEditing.range.end = currentlyEditing.range.start + 1
      }

      if (currentlyEditing.range.start != currentlyEditing.range.end) {
        setCurrentlyEditing({...currentlyEditing, range: {start: currentlyEditing.range.start, end: currentlyEditing.range.start}})

        modifyEditJSON(json => {
          return modifyNodeValueByUIDAndKey(json, currentlyEditing.uid, currentlyEditing.key, inlineHTML => {
            return inlineJSONToHTML(replaceText(inlineHTMLToJSON(inlineHTML), currentlyEditing.range, ""))
          })
        })
      } else if (currentlyEditing.range.start > 0) {
        setCurrentlyEditing({...currentlyEditing, range: {start: currentlyEditing.range.start-1, end: currentlyEditing.range.start-1}})

        modifyEditJSON(json => {
          return modifyNodeValueByUIDAndKey(json, currentlyEditing.uid, currentlyEditing.key, inlineHTML => {
            return inlineJSONToHTML(replaceText(inlineHTMLToJSON(inlineHTML), {start: currentlyEditing.range.start-1, end: currentlyEditing.range.start}, ""))
          })
        })
      } else {
        switch (currentlyEditingNode.type) {
          case "listItem":
            if (siblingIndexByUID(currentVersion.json, currentlyEditingNode.uid) === 0) {
              const newParagraph = generateNode('paragraph', {content: currentlyEditingNode.content})

              modifyEditJSON(json => {
                let returnJSON = json

                const parentNode = deepFindOldestNotSectionAncestorByUID(json, currentlyEditingNode.uid)

                if (siblingCountByUID(json, currentlyEditingNode.uid) === 1) {
                  returnJSON = replaceNodeByUID(returnJSON, parentNode.uid, newParagraph)
                } else {
                  returnJSON = insertBeforeNodeByUID(returnJSON, parentNode.uid, newParagraph)
                  returnJSON = deleteNodeByUID(returnJSON, currentlyEditingNode.uid)
                }

                return returnJSON
              })

              setCurrentlyEditing({uid: newParagraph.uid, range: {start: 0, end: 0}, key: 'content'})
            } else {
              let siblingBefore

              modifyEditJSON(json => {
                let returnJSON = json

                siblingBefore = deepFindSiblingBeforeByUID(json, currentlyEditingNode.uid)

                returnJSON = setNodeValueByUIDAndKey(returnJSON, siblingBefore.uid, 'content', siblingBefore.content + currentlyEditingNode.content)
                returnJSON = deleteNodeByUID(returnJSON, currentlyEditingNode.uid)

                return returnJSON
              })

              const newSelectionIndex = inlineHTMLToJSON(siblingBefore.content).text.length
              setCurrentlyEditing({uid: siblingBefore.uid, range: {start: newSelectionIndex, end: newSelectionIndex}, key: 'content'})
            }
            break;
          case "paragraph":
            const siblingBefore = deepFindSiblingBeforeByUID(currentVersion.json, currentlyEditingNode.uid)
            let newSelectionIndex

            if (siblingBefore) {
              switch (siblingBefore.type) {
                case 'paragraph':
                  modifyEditJSON(json => {
                    let returnJSON = json

                    returnJSON = setNodeValueByUIDAndKey(returnJSON, siblingBefore.uid, 'content', siblingBefore.content + currentlyEditingNode.content)
                    returnJSON = deleteNodeByUID(returnJSON, currentlyEditingNode.uid)

                    return returnJSON
                  })

                  newSelectionIndex = inlineHTMLToJSON(siblingBefore.content).text.length
                  setCurrentlyEditing({uid: siblingBefore.uid, range: {start: newSelectionIndex, end: newSelectionIndex}, key: 'content'})
                  break;
                case 'regularList':
                case 'numberedList':
                  let listItemBefore

                  modifyEditJSON(json => {
                    let returnJSON = json

                    listItemBefore = R.last(siblingBefore.content)

                    returnJSON = setNodeValueByUIDAndKey(returnJSON, listItemBefore.uid, 'content', listItemBefore.content + currentlyEditingNode.content)
                    returnJSON = deleteNodeByUID(returnJSON, currentlyEditingNode.uid)

                    return returnJSON
                  })

                  newSelectionIndex = inlineHTMLToJSON(listItemBefore.content).text.length
                  setCurrentlyEditing({uid: listItemBefore.uid, range: {start: newSelectionIndex, end: newSelectionIndex}, key: 'content'})
                  break;
                default:
                  if (currentlyEditingNode.content.length === 0) {
                    modifyEditJSON(json => {
                      return deleteNodeByUID(json, currentlyEditingNode.uid)
                    })

                    setCurrentlyEditing(null)
                  }
              }
            } else {
              if (currentlyEditingNode.content.length === 0) {
                modifyEditJSON(json => {
                  return deleteNodeByUID(json, currentlyEditingNode.uid)
                })

                setCurrentlyEditing(null)
              }
            }
            break;
          default:
        }
      }
    }
  }

  handleEnter(event) {
    let {
      currentlyEditing,
      currentlyEditingNode,
      modifyEditJSON,
      setCurrentlyEditing
    } = this.generateEditState()

    if (currentlyEditing?.selectionMenu) {
      setCurrentlyEditing({...currentlyEditing,
        selectionMenu: undefined
      })
    } else if (currentlyEditing?.range) {
      event.preventDefault()

      let newNode = generateNode(currentlyEditingNode.type)

      if (currentlyEditingNode.type === "section" && currentlyEditing.key === "title") {
        return
      }

      modifyEditJSON(json => {
        let inlineText = inlineHTMLToJSON(deepFindByUID(json, currentlyEditing.uid)[currentlyEditing.key])

        let textBefore = inlineJSONBeforeIndex(inlineText, currentlyEditing.range.start)
        let textAfter = inlineJSONAfterIndex(inlineText, currentlyEditing.range.end)

        let newJSON = json

        newJSON = modifyNodeValueByUIDAndKey(json, currentlyEditing.uid, currentlyEditing.key, inlineHTML => {
          return inlineJSONToHTML(textBefore)
        })

        newNode.content = inlineJSONToHTML(textAfter)
        newJSON = insertAfterNodeByUID(newJSON, currentlyEditing.uid, newNode)

        return newJSON
      })

      setCurrentlyEditing({uid: newNode.uid, range: {start: 0, end: 0}, key: 'content'})
    }
  }

  handleStyleButton(tagName, event) {
    let editState = this.generateEditState()

    let {
      currentlyEditing,
    } = editState

    if (currentlyEditing?.range) {
      event.preventDefault()

      return generateStyleFunction(tagName, editState)()
    }
  }

  handleKeyValueChange(uid, key, value) {
    let editState = this.generateEditState()

    editState.modifyEditJSON(json => modifyNodeValueByUIDAndKey(json, uid, key, () => value))
  }

  generateEditState(isEditDisplayMode=true) {
    let {
      type,
      currentVersion,
      isEditor,
      setCurrentlyEditing,
      setEditFieldsVersion,
      setEditJSON,
      versionEditData,
      versionLocalData,
      setEditMetadata,
    } = this.props;

    let editState = {
      isEditor: isEditor
    }

    if (editState.isEditor) {
      editState = {
        ...editState,
        isEditing: isEditDisplayMode,
        hasUnsavedChanges: !R.isEmpty(versionEditData),
        setEditFieldsVersion: setEditFieldsVersion,
        setEditJSON: setEditJSON.bind(this),
        addToUndo: this.addToUndo.bind(this),
        undoQueue: versionLocalData.undoQueue || [],
        redoQueue: versionLocalData.redoQueue || [],
        save: this.save.bind(this),
        undo: this.undo.bind(this),
        redo: this.redo.bind(this),
        currentlyEditing: versionLocalData.currentlyEditing,
        setCurrentlyEditing: setCurrentlyEditing.bind(this),
        handleKeyValueChange: this.handleKeyValueChange.bind(this),
        setEditMetadata: setEditMetadata,
        editMetadataUid: versionLocalData.editMetadataUid,
      }

      if (editState.currentlyEditing && editState.currentlyEditing.uid) {
        let lineage = deepFindLineageByUID(type === "Activity" ? currentVersion.content : currentVersion.json, editState.currentlyEditing.uid)

        if (lineage) {
          editState.currentlyEditingAncestors = R.init(lineage)
          editState.currentlyEditingNode = R.last(lineage)

          let editingRange = editState.currentlyEditing.range
          if (editState.currentlyEditing.key && editingRange) {
            let currentlyEditingInlineJSON = inlineHTMLToJSON(editState.currentlyEditingNode[editState.currentlyEditing.key])

            editState.currentlyActiveStyles = currentlyEditingInlineJSON.styles.filter(style => style.range.start <= editingRange.start && style.range.end >= editingRange.end)
          }
        }
      }

      editState.modifyEditJSON = (modifyFunction) => {
        editState.addToUndo(type === "Activity" ? currentVersion.content : currentVersion.json, editState.currentlyEditing)
        editState.setEditJSON(modifyFunction(type === "Activity" ? currentVersion.content : currentVersion.json))
      }
    }

    return editState
  }

  render() {
    let {
      type,
      activityUserStatesByActivityId,
      addActivityUserState,
      addDocumentSetDocumentState,
      resetDocumentSetDocumentState,
      currentDocument,
      currentDocumentActivities=[],
      currentDocumentSetDocumentActivities=[],
      currentDocumentSetDocumentRelation={},
      currentGroup,
      currentRoleDefinition={},
      currentVersion,
      isEditor,
      readDocument,
      scheduableSectionUIDs,
      imagingTypes,
      setDisplayMode,
      versionLocalData,
      documentSetDocumentState,
    } = this.props;

    let combinedDocumentActivities = currentDocumentActivities.concat(currentDocumentSetDocumentActivities)

    if (!(type === "Activity" ? currentVersion?.content : currentVersion?.json) || !currentDocument?.id) { return <GenLoadingSpinner/> }

    const addDocumentUserState = currentDocumentSetDocumentRelation?.is_gated ? addDocumentSetDocumentState : this.addDocumentUserState.bind(this),

    // CURRENT USER
    currentUser = {
      ...this.props.currentUser,
      addDocumentUserState: addDocumentUserState,
      addActivityUserState: addActivityUserState,
      addState: addDocumentUserState,
      addStateTargetType: "document",
      resetDocumentSetDocumentState: resetDocumentSetDocumentState,
      logEvent: this.logEvent.bind(this),
    }

    currentRoleDefinition = {
      ...currentRoleDefinition,
      isLearner: currentRoleDefinition.name === "Learner",
    }

    // DISPLAY STATE
    let displayMode = versionLocalData.displayMode || (isEditor ? DISPLAY_MODES.EDIT : DISPLAY_MODES.STUDENT)
    const {
      isUnlocked,
      isForcedUncollapsed,
      isEditDisplayMode
    } = displayTraitsBasedOnMode(displayMode)

    let displayState = {
      displayMode,
      isUnlocked,
      isForcedUncollapsed,
      setDisplayMode: setDisplayMode,
      openSection: this.openSection.bind(this),
      documentType: type,
    }

    // EDIT STATE
    let editState = this.generateEditState(isEditDisplayMode)

    let mergedJSON = type === "Activity" ? currentVersion.content : currentVersion.json;

    if (type === "Activity") {
      mergedJSON = {
        type: "section",
        uid: "root",
        title: currentVersion.name,
        content: mergedJSON.map((node) => ({...node, collapsible: true, nested: true, collapsed: false})),
      }
    }

    mergedJSON = R.clone(mergedJSON);
    if (currentDocumentSetDocumentRelation?.is_gated) {
      const state = documentSetDocumentState?.state || {};
      const strippedState = R.map(R.map((valueData) => valueData.value), state);

      R.toPairs(strippedState).forEach(([uid, kvs]) => {
        const node = deepFindByUID(mergedJSON, uid);
        if (node) {
          R.toPairs(kvs).forEach(([key, value]) => {
            node[key] = value;
          });
        }
      });

      combinedDocumentActivities = combinedDocumentActivities.filter((dsda) => dsda.attempt_index === documentSetDocumentState.attempt_index)
    } else {
      mergedJSON = this.mergeInUSCS(mergedJSON, this.state.uscData)
    }

    if (combinedDocumentActivities) {
      mergedJSON = addActivitiesToDocument(mergedJSON, combinedDocumentActivities, activityUserStatesByActivityId);
    }

    if (currentRoleDefinition.isLearner) {
      let firstVideo;
      if (currentDocumentSetDocumentRelation?.is_gated) {
        const firstLevelContent = mergedJSON.content;
        firstVideo = firstLevelContent.find((node) => "video" === node.type);
        let firstNode = firstLevelContent.find((node) => ["section", "activity"].includes(node.type));

        if (firstVideo && !firstVideo.video_played) {
          const firstNodeAction = firstNode.type === "activity" ?
            newSetNodeKVPairAction("root", "collapsed", false, "activity", firstNode.documentSetDocumentActivity.activity_id) :
            newSetNodeKVPairAction(firstNode.uid, "collapsed", false, "document")

          firstVideo.onVideoComplete = [
            newSetNodeKVPairAction(firstVideo.uid, "video_played", true),
            firstNodeAction,
          ];
          firstVideo.extraTitle = "Watch the video to continue."
        }
      }

      const reduceResult = mergedJSON.content.reduce((accum, node, currentIndex, array) => {
        let {
          shouldHide,
          newJsonContent,
        } = accum;

        if (shouldHide || (firstVideo && !firstVideo.video_played)) {
          if (["section", "activity"].includes(node.type)) {
            if (currentDocumentSetDocumentRelation?.is_gated) {
              return {
                shouldHide: shouldHide,
                newJsonContent: [
                  ...newJsonContent,
                  {
                    ...node,
                    inactive: 2,
                  }
                ],
              };
            } else {
              return {
                shouldHide: shouldHide,
                newJsonContent: [
                  ...newJsonContent,
                  {
                    ...node,
                    invisible: true,
                  }
                ],
              };
            }
          } else {
            return {
              shouldHide,
              newJsonContent: [...newJsonContent, node],
            }
          }
        } else {
          let shouldHideNext = false;
          if (node.type === "activity") {
            const documentSetDocumentActivity = node.documentSetDocumentActivity;
            const activity = documentSetDocumentActivity.activity;
            const activityUserState = node.activityUserState;
            const hasBeenSubmitted = activityUserState.state?.submit_button?.inactive?.value === 2

            shouldHideNext = !hasBeenSubmitted && (activity.placement_uid || currentDocumentSetDocumentRelation?.is_gated);
          } else if (node.type === "section" && currentDocumentSetDocumentRelation?.is_gated) { // SHOULD ADD LEVEL TEST????
            shouldHideNext = !node.completed_section;
          }

          if (currentDocumentSetDocumentRelation?.is_gated && shouldHideNext) {
            const onClickActions = [];
            let remainingNodes = array.slice(currentIndex+1);
            let nextNode = remainingNodes.find((n) => ["section", "activity"].includes(n.type));
            if (nextNode) {
              const nextNodeAction = nextNode.type === "activity" ?
                newSetNodeKVPairAction("root", "collapsed", false, "activity", nextNode.documentSetDocumentActivity.activity_id) :
                newSetNodeKVPairAction(nextNode.uid, "collapsed", false, "document")

                onClickActions.push(nextNodeAction);
            } else {
              const activityScores = combinedDocumentActivities.map((documentSetDocumentActivity) => {
                const activity = documentSetDocumentActivity.activity;
                const activityUserState = activityUserStatesByActivityId[activity.id];
                return activityUserState.grade_percentage
              }).filter((gradePercentage) => typeof gradePercentage === "number")

              if (activityScores.length > 0) {
                const grade_percentage = R.sum(activityScores) / activityScores.length
                onClickActions.push(
                  newSetNodeKVPairAction(mergedJSON.uid, "grade_percentage", grade_percentage, "document"),
                );
              }

              onClickActions.push(
                newSetNodeKVPairAction(mergedJSON.uid, "completed_document", true, "document"),
              );
            }

            if (node.type === "activity") {
              if (!nextNode) {
                node.onCompleteTitle = "Submit and Complete Case";
              }

              node.onComplete = onClickActions;
            } else if (node.type === "section") { // SHOULD ADD LEVEL TEST????
              onClickActions.push(
                newSetNodeKVPairAction(node.uid, "completed_section", true),
                newSetNodeKVPairAction(node.uid, "collapsed", true),
              )

              node.content.push(
                generateNode("button", {
                  uid: `${node.uid}_next_button`,
                  title: nextNode ? "Continue" : "Complete Case",
                  onClick: onClickActions,
                }),
              );
            }
          }

          return {
            shouldHide: shouldHideNext,
            newJsonContent: [...newJsonContent, node],
          }
        }
      }, {shouldHide: false, newJsonContent: []})
      mergedJSON.content = reduceResult.newJsonContent;

      if (currentDocumentSetDocumentRelation?.is_gated && mergedJSON.completed_document) {
        const newContent = [
          generateNode("completionNotice", {
            uid: `${mergedJSON.uid}_complete_paragraph`,
            grade_percentage: documentSetDocumentState?.grade_percentage,
            attempt_index: documentSetDocumentState?.attempt_index,
            nextDocumentSetDocumentRelationId: this.props.nextDocumentSetDocumentRelationId,
          }),
        ]

        const lastSectionIndex = R.findLastIndex(n => n.type === "section" || n.type === "activity", mergedJSON.content);
        mergedJSON.content = R.insertAll(lastSectionIndex + 1, newContent, mergedJSON.content);
      }
    }

    let progressBar
    if (currentDocumentSetDocumentRelation?.is_gated) {
      let progress = 0;
      if (mergedJSON.completed_document) {
        progress = 100.0;
      } else {
        const progressSections = mergedJSON.content.filter((child) => "section" === child.type)
        const completedSections = progressSections.filter((section) => {
          const hasNextClicked = section.completed_section;

          return hasNextClicked;
        });

        const completedActivities = combinedDocumentActivities.filter((documentSetDocumentActivity) => {
          const activityUserState = activityUserStatesByActivityId[documentSetDocumentActivity.activity.id];
          const hasBeenSubmitted = activityUserState.state?.submit_button?.inactive?.value === 2

          return hasBeenSubmitted;
        })

        const allPossibleCount = progressSections.length + combinedDocumentActivities.length;
        const allCompletedCount = completedSections.length + completedActivities.length;
        progress = (allCompletedCount * 100.0) / allPossibleCount;
      }

      progressBar = (
        <div className="fixed bottom-0 right-20 z-40 p-3 bg-white bg-opacity-75 backdrop-blur-3xl border border-green-cyan shadow-lg">
          <h3>Progress: {Math.floor(progress)}%</h3>
        </div>
      )
    }

    let caseReview;
    if (documentSetDocumentState?.reviewed_at) {
      caseReview = (
        <div className="activity-review">
          <h4>Review:</h4>
          <div className="activity-review-inside">
            <p>{documentSetDocumentState.review_comment}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="doc-container">
        <DocTopBar
          currentDocument={currentDocument}
          currentVersion={currentVersion}
          currentUser={currentUser}
          currentRoleDefinition={currentRoleDefinition}
          currentDocumentSetDocumentRelation={currentDocumentSetDocumentRelation}
          displayState={displayState}
          editState={editState}
        />
        <div className={classNames('doc-body', `normal-display-mode`, {'edit-bar-bump': editState.isEditor})}>
          <DocSection
            isRootSection

            {...mergedJSON}
            currentDocument={currentDocument}
            currentVersion={currentVersion}
            currentUser={currentUser}
            currentRoleDefinition={currentRoleDefinition}
            currentGroup={currentGroup}
            currentDocumentSetDocumentRelation={currentDocumentSetDocumentRelation}
            scheduableSectionUIDs={scheduableSectionUIDs}
            imagingTypes={imagingTypes}
            displayState={displayState}
            editState={editState}
            aboveContent={caseReview}
          />
        </div>
        {editState.isEditor && <DocMediaModal
          currentDocument={currentDocument}
          currentVersion={currentVersion}
          currentUser={currentUser}
          currentRoleDefinition={currentRoleDefinition}
          editState={editState}
          readDocument={readDocument}
        />}
        {editState.isEditor && <DocEditMetadataModal
          currentDocument={currentDocument}
          currentVersion={currentVersion}
          currentUser={currentUser}
          currentRoleDefinition={currentRoleDefinition}
          editState={editState}
          mergedJSON={mergedJSON}
        />}
        {progressBar}
      </div>
    )
  }
}

export default (props) => {
  const ConnectedDocContainer = connect(mapStateToProps, mapDispatchToProps)(DocContainer)

  return <Provider store={store()}>
    <ConnectedDocContainer {...props} />
  </Provider>
}
