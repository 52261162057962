import React from 'react';
import ReactDOM from "react-dom";

import * as R from 'ramda'

import classNames from 'classnames'

import DocActivity from 'Doc/Activity'
import DocAudio from 'Doc/Audio'
import DocBookmark from 'Doc/Bookmark'
import DocButton from 'Doc/Button'
import DocCheckbox from 'Doc/Checkbox'
import DocCompletionNotice from 'Doc/CompletionNotice'
import DocExtra from 'Doc/Extra'
import DocImage from 'Doc/Image'
import DocImageGallery from 'Doc/ImageGallery'
import DocMatcher from 'Doc/Matcher'
import DocMultipleChoice from 'Doc/MultipleChoice'
import DocNumberedList from 'Doc/NumberedList'
import DocOpenAiChat from 'Doc/OpenAiChat'
import DocParagraph from 'Doc/Paragraph'
import DocRatingBar from 'Doc/RatingBar'
import DocRegularList from 'Doc/RegularList'
import DocSliderBar from 'Doc/SliderBar'
import DocTextInput from 'Doc/TextInput'
import DocVideo from 'Doc/Video'

import DocInlineText from 'Doc/InlineText'

import DocSectionScheduleStatus from 'Doc/SectionScheduleStatus'

import AddToButton from 'DocumentSetDocumentRelation/AddTo/Button'

import { documentDisplayNumber } from 'src/document_display_title'

export default class DocSection extends React.Component {
  onHeaderClick() {
    const {
      collapsed,
      collapsible,
      inactiveBoolean,
      nested,
      uid,

      currentUser,
      displayState,
    } = this.props;

    if (inactiveBoolean) { return }

    if (!nested) {
      displayState.openSection(uid);
    } else if(collapsible) {
      if (collapsed) {
        currentUser.logEvent(uid, "section_uncollapsed");
      }

      currentUser.addState({[uid]: {"collapsed": !collapsed}});
    }
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.collapsed !== this.props.collapsed) {
      return ReactDOM.findDOMNode(this)?.getBoundingClientRect();
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot && prevProps.collapsed !== this.props.collapsed) {
      const prevBoundingClientRect = snapshot;
      const currBoundingClientRect = ReactDOM.findDOMNode(this)?.getBoundingClientRect();
      if (this.props.collapsed && currBoundingClientRect) {
        if (prevBoundingClientRect.bottom < 0) {
          window.scrollBy(0, currBoundingClientRect.height - prevBoundingClientRect.height);
        } else if (prevBoundingClientRect.top < 0) {
          window.scrollBy(0, currBoundingClientRect.top);
        }
      }
    }
  }

  render() {
    const {
      aboveContent,
      classes,
      collapsed,
      collapsible,
      content,
      headerColor,
      inactive,
      inactiveBoolean,
      isRootSection,
      nested,
      rightContent,
      scheduableSectionUIDs,
      title,
      titleBadge,
      uid,
      reportButton,

      currentDocument,
      currentDocumentSetDocumentRelation,
      currentGroup,
      currentRoleDefinition,
      currentUser,
      currentVersion,
      displayState,
      editState,

      currentActivityUserState,
    } = this.props;


    let htmlClass = classNames(
      "doc-section",
      {
        "nested": nested,
        "collapsible": collapsible,
        "collapsed": collapsed,
        "inactive": inactiveBoolean
      }
    )

    let hideBadge
    if (currentDocumentSetDocumentRelation && scheduableSectionUIDs.includes(uid)) {
      let shouldHide = false
      let parseHideUntil = false
      if (currentDocumentSetDocumentRelation.drawer_schedules[uid]) {
        let hideUntil = currentDocumentSetDocumentRelation.drawer_schedules[uid].hide_until
        parseHideUntil = (typeof hideUntil === 'string') ? new Date(hideUntil) : hideUntil
        shouldHide = (parseHideUntil instanceof Date) ? new Date() < new Date(hideUntil) : hideUntil
      }

      if (currentRoleDefinition.isLearner) {
        if (shouldHide) { return null }
      } else {
        hideBadge = <DocSectionScheduleStatus hideUntil={parseHideUntil}/>
      }
    }

    let headerClasses = classNames(
      "doc-section-header",
      {"display-root": isRootSection}
    )

    let mediaIcons = []
    if (classes) {
      classes.forEach((childClass) => {
        switch (childClass) {
          case "extra_video":
            mediaIcons.push(<i key="extra_video" className="fa fa-video-camera" style={{'paddingLeft': '5px'}} />);
            break;
          case "extra_image":
            mediaIcons.push(<i key="extra_image" className="fa fa-image" style={{'paddingLeft': '5px'}} />);
            break;
          case "extra_audio":
            mediaIcons.push(<i key="extra_audio" className="fa fa-volume-up" style={{'paddingLeft': '5px'}} />);
            break;
          default:
        }
      })
    }

    let coverImage;
    if (isRootSection && editState.isEditing && displayState.documentType !== "Activity") {
      coverImage = (
        <img
          className="doc-section-header-cover-image"
          src={`${currentVersion.aws_folder_url}/compressed/images/${currentVersion.cover_image}`}
          onClick={a => editState.setCurrentlyEditing({menu: 'mediaMenu', mediaType: 'image', isCoverImage: true})}
        />
      )
    }

    let collapsedIndicator;
    let readerTags = {}
    if (collapsible) {
      readerTags = {
        role: 'button',
        'aria-label': `Toggle ${title}`,
        'aria-expanded': !collapsed,
        'aria-disabled': inactive,
      }

      collapsedIndicator = (
        <span className="doc-section-collapsed-indicator">
          <i className={`fa fa-caret-${collapsed ? "right" : "down"}`} />
        </span>
      );
    }

    const sectionHeader = (
      <div className={headerClasses} onClick={this.onHeaderClick.bind(this)} {...readerTags} style={headerColor && {'backgroundColor': headerColor}}>
        <h1 className="doc-section-header-title">
          {collapsedIndicator}
          {coverImage}
          {isRootSection && currentDocument.type !== "Activity" && `${documentDisplayNumber(currentDocument)} | `}
          <DocInlineText content={title} uid={uid} contentKey="title" editState={editState}/>
          {mediaIcons}
        </h1>
        {inactiveBoolean &&
          <div className="skill-badge bg-sky-600 mr-2">
            <i className="fa fa-lock"></i>
          </div>
        }
        {titleBadge &&
          <div className="doc-section-header-title-badge">
            {titleBadge}
          </div>
        }
        {isRootSection && !editState.isEditor &&
          <AddToButton
            document={currentDocument}
            rootDocumentSetId={currentGroup.root_document_set_id}
            currentUser={currentUser}
            currentRoleDefinition={currentRoleDefinition}
          />
        }
        {(rightContent || hideBadge) &&
          <div className="doc-section-right-content">
            {rightContent}
            {hideBadge}
          </div>
        }
        <DocBookmark {...this.props} />
      </div>
    )

    let visibleContent, renderedAboveContent, renderedReportButton
    if (!displayState.isForcedUncollapsed && ((!isRootSection && !nested) || collapsed || inactiveBoolean)) {
      visibleContent = [];
    } else {
      if (reportButton) {
        renderedReportButton = reportButton;
      }

      visibleContent = content.filter(node => node.invisible !== true);

      if (aboveContent) {
        renderedAboveContent = (
          <div className="doc-section-body-above-content">
            {aboveContent}
          </div>
        )
      }
    }

    const passToEveryNode = {
      currentDocument: currentDocument,
      currentVersion: currentVersion,
      currentUser: currentUser,
      displayState: displayState,
      editState: editState,

      currentDocumentSetDocumentRelation: currentDocumentSetDocumentRelation,
      currentRoleDefinition: currentRoleDefinition,
      scheduableSectionUIDs: scheduableSectionUIDs,

      currentActivityUserState: currentActivityUserState,
    }

      let contentNodes = visibleContent.map((node, index) => {
      if (node.hasOwnProperty("inactive") && node.inactive !== 0) {
        node.inactiveBoolean = node.inactive === 2;
      } else {
        node.inactiveBoolean = inactiveBoolean;
      }

      if (R.includes(node.type, ['section', 'image', 'video']) && index > 0) {
        node.imagingTypes = this.props.imagingTypes
      }

      let nodeContent = {
        key: node.uid,
        ...node,
        ...passToEveryNode,
      }

      switch(node.type){
        case "section":
          return <DocSection {...nodeContent} />
        case "paragraph":
          return <DocParagraph {...nodeContent} />
        case "regularList":
          return <DocRegularList {...nodeContent} />
        case "numberedList":
          return <DocNumberedList {...nodeContent} />
        case "extra":
          return <DocExtra {...nodeContent} />
        case "image":
          return <DocImage {...nodeContent} />
        case "imageGallery":
          return <DocImageGallery {...nodeContent} />
        case "video":
          return <DocVideo {...nodeContent} />
        case "audio":
          return <DocAudio {...nodeContent} />
        case "checkbox":
          return <DocCheckbox {...nodeContent} />
        case "textInput":
          return <DocTextInput {...nodeContent} />
        case "sliderBar":
          return <DocSliderBar {...nodeContent} />
        case "ratingBar":
          return <DocRatingBar {...nodeContent} />
        case "multipleChoice":
          return <DocMultipleChoice {...nodeContent} />
        case "matcher":
          return <DocMatcher {...nodeContent} />
        case "button":
          return <DocButton {...nodeContent} />
        case "activity":
          return <DocActivity {...nodeContent} />
        case "openAiChat":
          return <DocOpenAiChat {...nodeContent} />
        case "completionNotice":
          return <DocCompletionNotice {...nodeContent} />
        default:
          // TODO: ADD WARNING IF IN DISPLAY MORE
          return null
      }
    });

    const sectionBody = (
      <div className="doc-section-body relative">
        {renderedAboveContent}
        {contentNodes}
        {renderedReportButton}
      </div>
    )

    return (
      <div className={htmlClass}>
        {sectionHeader}
        {/* {isRootSection && <DocCompletionNotice {...passToEveryNode} />} */}
        {sectionBody}
      </div>
    )
  }
}
