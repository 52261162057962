import React from "react";

export default function GenSearchBox(
  {
    resetListPages,
    readListPage,
    setListSearch,
    search,
  },
) {
  const searchTimerRef = React.useRef(null);

  React.useEffect(
    () => () => {
      const searchTimer = searchTimerRef.current;
      if (searchTimer) { clearTimeout(searchTimer); }
    },
    [],
  );

  const updateSearchWithTimer = React.useCallback(() => {
    resetListPages();
    readListPage();
    searchTimerRef.current = null;
  }, [resetListPages, readListPage]);

  const handleSearchChange = React.useCallback((e) => {
    setListSearch(e.target.value);

    const searchTimer = searchTimerRef.current;
    if (searchTimer) { clearTimeout(searchTimer); }
    searchTimerRef.current = setTimeout(updateSearchWithTimer, 750);
  }, [setListSearch, updateSearchWithTimer]);

  const handleSearchKeyDown = React.useCallback((e) => {
    if (e.key === "Enter") {
      const searchTimer = searchTimerRef.current;
      if (searchTimer) { clearTimeout(searchTimer); }
      searchTimerRef.current = null;

      updateSearchWithTimer();
      e.preventDefault();
    }
  }, [updateSearchWithTimer]);

  return (
    <form className="gen-search-box" role="search">
      <input
        type="search"
        placeholder="Search..."
        value={search || ""}
        onChange={handleSearchChange}
        onKeyDown={handleSearchKeyDown}
      />
    </form>
  );
}
