import {
  generateItemsReducer,
  generateActions,
  itemFromStore,
  itemEditDataFromStore,
  itemLocalDataFromStore
} from 'reducers/item'

const itemType = "version"
const endpoint = "versions/"

export const versionsReducer = generateItemsReducer(itemType)

export const {
  seedVersion,
  createVersion,
  readVersion,
  updateVersion,
  destroyVersion,
  setEditFieldsVersion,
  resetEditFieldsVersion,
  setLocalFieldsVersion,
  resetLocalFieldsVersion,
} = generateActions(itemType, endpoint)

export const versionFromStore = (store, id) => itemFromStore(store, itemType, id)
export const versionEditDataFromStore = (store, id) => itemEditDataFromStore(store, itemType, id)
export const versionLocalDataFromStore = (store, id) => itemLocalDataFromStore(store, itemType, id)
