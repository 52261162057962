import React from 'react';

export default function GenPanel(
  {
    className,
    children,
  },
) {
  return (
    <div className={`gen-panel ${className}`}>
      {children}
    </div>
  );
}
