import React from 'react'
import { Provider, connect, batch } from 'react-redux'

import store from 'store/store'

import {
  getListFromStore,
  setEndpoint,
  readListPage,
  resetListPages,
  addListFilter,
  removeListFilter,
  setListSearch,
  resetListSearch,
  setListSort,
  resetListSort,
  setListSelected,
  resetListSelected,
  addListSelected,
  removeListSelected,
  setListItemsPerPage
} from 'reducers/list'

const mapStateToProps = listID => (state, ownProps) => (
  {
    [listID]: getListFromStore(state, listID)
  }
)

const mapDispatchToProps = listID => (dispatch, ownProps) => {
  return {
    [listID]: {
      mountList: (endpoint) => batch(() => {
        dispatch(setEndpoint(listID, endpoint))
        dispatch(readListPage(listID))
      }),
      setEndpoint: (endpoint) => dispatch(setEndpoint(listID, endpoint)),
      readListPage: () => dispatch(readListPage(listID)),
      resetListPages: () => dispatch(resetListPages(listID)),
      addListFilter: (filterID, filter) => dispatch(addListFilter(listID, filterID, filter)),
      removeListFilter: (filterID) => dispatch(removeListFilter(listID, filterID)),
      setListSearch: (search) => dispatch(setListSearch(listID, search)),
      resetListSearch: () => dispatch(resetListSearch(listID)),
      setListSort: (sort_by, sort_order) => dispatch(setListSort(listID, sort_by, sort_order)),
      resetListSort: () => dispatch(resetListSort(listID)),
      setListSelected: (selected) => dispatch(setListSelected(listID, selected)),
      resetListSelected: () => dispatch(resetListSelected(listID)),
      addListSelected: (addSelectedIDs) => dispatch(addListSelected(listID, addSelectedIDs)),
      removeListSelected: (removeSelectedIDs) => dispatch(removeListSelected(listID, removeSelectedIDs)),
      setListItemsPerPage: (itemsPerPage) => dispatch(setListItemsPerPage(listID, itemsPerPage))
    }
  }
}

const mergeProps = listID => (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    [listID]: {
      ...stateProps[listID],
      ...dispatchProps[listID]
    }
  }
}

export default function connectToList(component, possibleListIDs) {
  const listIDs = Array.isArray(possibleListIDs) ? possibleListIDs : [possibleListIDs];

  let ConnectedToListComponent = component;
  listIDs.forEach((listID) => {
    ConnectedToListComponent = connect(mapStateToProps(listID), mapDispatchToProps(listID), mergeProps(listID))(ConnectedToListComponent)
  })

  return props => {
    return <Provider store={store()}>
      <ConnectedToListComponent {...props}/>
    </Provider>
  }
}
