import React from "react";

import classNames from "classnames";

export default function ActivityReviewSummary(props) {
  const {
    activity,
    activityUserState,
  } = props;

  return (
    activity.content.flat().map((node) => {
      let suggestedResponse;
      if (node.feedback) {
        suggestedResponse = (
          <div>
            <b>Correct Answer:</b>
            <div className="suggested-response">
              {node.feedback}
            </div>
          </div>
        );
      }

      switch (node.type) {
        case "paragraph": {
          return (
            <p
              key={node.uid}
              dangerouslySetInnerHTML={{__html: node.content}}
              style={{marginBottom: "20px"}}
            />
          );
        }
        case "textInput": {
          return (
            <div key={node.uid} style={{marginBottom: "20px"}}>
              <b>Student Response:</b>
              <div className="student-response">
                <p>{activityUserState && activityUserState.state[node.uid]?.value?.value}</p>
              </div>
              {suggestedResponse}
            </div>
          );
        }
        case "multipleChoice": {
          const answeredValues = node.content.map((multipleChoiceAnswer) => {
            const answered = activityUserState && activityUserState.state[multipleChoiceAnswer.uid]?.value?.value;

            return (
              <li
                key={multipleChoiceAnswer.uid}
                className={
                  classNames(
                    "doc-multiple-choice-answer",
                    multipleChoiceAnswer.answer ? "correct" : "incorrect",
                    {answered},
                  )
                }
              >
                <span className="doc-multiple-choice-answer-outer"><span className="doc-multiple-choice-answer-inner" /></span>
                <span className="doc-multiple-choice-answer-title">{multipleChoiceAnswer.content}</span>
                {(multipleChoiceAnswer.value || multipleChoiceAnswer.answer) && (
                  <span style={{marginLeft: "10px"}}>
                    {multipleChoiceAnswer.answer ? <i className="fa fa-check text-green-600" /> : <i className="fa fa-times text-red-700" /> }
                  </span>
                )}
              </li>
            );
          });

          return (
            <div>
              <b>Student Response:</b>
              <div className="student-response">
                <ul>
                  {answeredValues}
                </ul>
              </div>
              {suggestedResponse}
            </div>
          );
        }
        default:
          return null;
          // TODO ADD ERROR
      }
    })
  );
}
