import React from 'react';

import DocBookmark from 'Doc/Bookmark'
import DocListItem from 'Doc/ListItem'

export default function DocRegularList(props) {
  let listItems = props.content.map(listItem => {
    return (
      <DocListItem
        key={listItem.uid}
        {...listItem}
        currentDocument={props.currentDocument}
        currentVersion={props.currentVersion}
        currentUser={props.currentUser}
        displayState={props.displayState}
        editState={props.editState}
      />
    );
  });

  return (
    <ul className="doc-regular-list">
      {listItems}
      <DocBookmark {...props} />
    </ul>
  );
}
