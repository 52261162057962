export function integerDurrationToDisplayString(value) {
  const seconds = value % 60;
  const minutes = Math.floor(value / 60) % 60;
  const displayEnd = `${String(minutes).padStart(2, 0)}:${String(seconds).padStart(2, 0)}`;

  const hours = Math.floor(value / 3600);
  return hours > 0 ? `${String(hours).padStart(2, 0)}:${displayEnd}` : displayEnd;
}

export function stringDateToDisplayDateString(value) {
  return value && new Date(Date.parse(value)).toLocaleString("en-US", {timeZoneName: "short"});
}
