import React from 'react';

export default function CaptureSummary(
  {
    document,
  },
) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  let created_at_date = new Date(document.created_at);
  let formatted_date = created_at_date.toLocaleDateString("en-US", options);

  return (
    <a href={"/documents/" + document.id} style={{width: "100%"}}>
      <div className="capture_summary_row">
        <div className="capture_summary_header">
          <h1> {document.title} </h1>
        </div>
        <div className="capture_summary_details">
          <p> <strong> Created: </strong> {formatted_date} <br/> </p>
          <p> <strong> Status: </strong> {document.pretty_status} </p>
          <p> <strong> Patient Age: </strong> {document.pretty_age} </p>
        </div>
      </div>
    </a>
  );
}
