import React from 'react';

import GenButton from 'Gen/Button'

export default function DocCompletionNotice(props) {
  const {
    grade_percentage,
    attempt_index,
    nextDocumentSetDocumentRelationId,
    currentDocumentSetDocumentRelation,
    currentUser,
  } = props;

  let continueButton;
  if (nextDocumentSetDocumentRelationId) {
    continueButton = (
      <GenButton
        name="Continue to Next Case"
        highlighted
        href={`/document_set_document_relations/${nextDocumentSetDocumentRelationId}`}
      />
    );
  }

  if (typeof grade_percentage !== "number") {
    return (
      <div className="doc-completion-notice pt-5">
        <div className="doc-notice-green">
          <h3>Congratulations!</h3>

          <p>You completed this case.</p>

          <div className="flex gap-3 mt-2">
            {continueButton}
          </div>
        </div>
      </div>
    )
  }


  const renderedGrade = `${(grade_percentage * 100).toFixed(2)}%`
  const renderedPassingGrade = `${(currentDocumentSetDocumentRelation.document_set.passing_percentage * 100).toFixed()}%`
  if (grade_percentage > currentDocumentSetDocumentRelation.document_set.passing_percentage) {
    return (
      <div className="doc-completion-notice pt-5">
        <div className="doc-notice-green">
          <h3>Score: {renderedGrade}</h3>

          <p>Congratulations! You passed this case.</p>

          <div className="flex gap-3 mt-2">
            {continueButton}
          </div>
        </div>
      </div>
    )
  } else if(attempt_index + 1 < currentDocumentSetDocumentRelation.document_set.attempt_limit) {
    return (
      <div className="doc-completion-notice pt-5">
        <div className="doc-notice-red">
          <h3>Score: {renderedGrade}</h3>

          <p>You did not complete a high enough percentage of the questions to receive a passing grade. A passing grade is {renderedPassingGrade}. Would you like to re-take? You may only do so once.</p>

          <div className="flex gap-3 mt-2">
            <GenButton
              name="Retake"
              highlighted
              onClick={() => {
                currentUser.resetDocumentSetDocumentState();
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}
            />

            {continueButton}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="doc-completion-notice pt-5">
        <div className="doc-notice-red">
          <h3>Score: {renderedGrade}</h3>

          <p>You did not complete a high enough percentage of the questions to receive a passing grade. A passing grade is {renderedPassingGrade}. Unfortunately you can no longer retake this case.</p>

          <div className="flex gap-3 mt-2">
            {continueButton}
          </div>
        </div>
      </div>
    )
  }
}
